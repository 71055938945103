import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import WebinarForm from "../../../components/forms/WebinarForm"
import { FORM_TYPE_EDIT } from "../../../enums/FormTypes"
import QueryString from "query-string"
import { gql, useMutation, useQuery } from "@apollo/client"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import { Button, Result, Spin } from "antd"
import { Link, navigate } from "gatsby"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"
import moment from "moment"

const GET_WEBINAR_QUERY = gql`
  query getWebinar($id: uuid!) {
    webinars_by_pk(id: $id) {
      id
      title
      subtitle
      webinar_id
      short_description
      url
      video_id
      author_id
      available_date
      state
      landscape_id
      premium_only
      author_rels {
        author_id
      }
    }
  }
`

const UPDATE_WEBINAR_MUTATION = gql`
  mutation updateWebinar(
    $id: uuid!
    $data: webinars_set_input!
    $webinarAuthorRels: [webinar_author_rels_insert_input!]!
  ) {
    delete_webinar_author_rels(where: { webinar_id: { _eq: $id } }) {
      affected_rows
    }
    update_webinars_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
    insert_webinar_author_rels(objects: $webinarAuthorRels) {
      affected_rows
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const EditWebinarPage = ({ location }) => {
  const [updating, setUpdating] = useState(false)

  const params = QueryString.parse(location.search)

  const { data, error, loading } = useQuery(GET_WEBINAR_QUERY, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  })

  const [updateWebinar] = useMutation(UPDATE_WEBINAR_MUTATION)

  const handleFormSubmit = async values => {
    setUpdating(true)

    let newValues = { ...values }
    delete newValues.author_ids

    const webinarAuthorRels = values.author_ids.map(authorId => ({
      webinar_id: params.id,
      author_id: authorId,
    }))

    try {
      await updateWebinar({
        variables: { data: newValues, id: params.id, webinarAuthorRels },
      })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/contents/webinars")
        },
      })
    } catch (error) {
      uiHandleError({ error })
    }
    setUpdating(false)
  }

  if (loading) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <LoadingScreen />
      </PageRoot>
    )
  }

  if (error) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  const webinar = data.webinars_by_pk

  if (!webinar) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <Result
          status="404"
          title="404"
          subTitle="Elemento non trovato!"
          extra={
            <Button type="primary">
              <Link to={"/contents/webinars"}>Torna indietro</Link>
            </Button>
          }
        />
      </PageRoot>
    )
  }

  const initialValues = {
    ...webinar,
    available_date: moment(webinar.available_date),
    author_ids: webinar.author_rels.map(item => item.author_id),
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={updating}>
        <WebinarForm
          initialValues={initialValues}
          onFinish={handleFormSubmit}
          formType={FORM_TYPE_EDIT}
        />
      </Spin>
    </PageRoot>
  )
}

export default EditWebinarPage
