import { gql, useApolloClient } from "@apollo/client"
import { useGlobalValue } from "./useGlobalValue"
import { useAuth0 } from "@auth0/auth0-react"
import fetch from "cross-fetch"

const GET_CURRENT_USER = gql`
  query currentUser($user_id: String!) {
    auth0_users_by_pk(user_id: $user_id) {
      role
      user_id
      last_login
    }
  }
`

const GET_FILE_DATA = gql`
  query getFileData($id: uuid!) {
    files_by_pk(id: $id) {
      filename
      duration
      width
      height
      is_public
      mimetype
      size
      s3_key
      id
      extension
    }
  }
`

const GET_SEO_WITH_SLUG_QUERY = gql`
  query getSlug(
    $slug: String!
    $itemId: uuid
    $itemType: enum_item_types_enum
  ) {
    seo(
      where: {
        slug: { _eq: $slug }
        item_id: { _neq: $itemId }
        item_type: { _eq: $itemType }
      }
    ) {
      item_id
    }
  }
`

const useCommonQueries = () => {
  const client = useApolloClient()
  const { user, getAccessTokenSilently } = useAuth0()
  const [{}, dispatch] = useGlobalValue()

  const getCurrentUser = async () => {
    const { data } = await client.query({
      query: GET_CURRENT_USER,
      variables: { user_id: user.sub },
    })
    return data.auth0_users_by_pk
  }

  const getFileData = async id => {
    const { data } = await client.query({
      query: GET_FILE_DATA,
      variables: { id },
    })
    return data.files_by_pk
  }

  const getSignedVideoSourceUrl = async ({ videoId }) => {
    const url = `/.netlify/functions/getVideoSource`
    const { email } = user

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ videoId, email }),
      headers: {
        "Content-Type": "application/json",
      },
    })

    if (response.status !== 200) {
      throw new Error(response.message)
    }

    const { sourceUrl } = await response.json()

    return sourceUrl
  }

  const getBraintreeToken = async () => {
    const response = await fetch("/.netlify/functions/braintree-get-token")

    if (response.status !== 200) {
      throw new Error(response.message)
    }

    const { token } = await response.json()

    return token
  }

  const braintreeCheckout = async payload => {
    const response = await fetch("/.netlify/functions/braintree-checkout", {
      method: "POST",
      body: JSON.stringify({ paymentMethodNonce: payload.nonce }),
      headers: { "Content-Type": "application/json" },
    })

    if (response.status !== 200) {
      throw new Error(response.message)
    }

    const { transaction } = await response.json()

    return transaction
  }

  const logUserSearch = async ({ search }) => {
    throw new Error("not implemented")
  }

  const logUserVideoAction = async ({ videoId, action, videoTime }) => {
    throw new Error("not implemented")
  }

  const slugExists = async (slug, itemId, itemType) => {
    const { data } = await client.query({
      query: GET_SEO_WITH_SLUG_QUERY,
      variables: { slug, itemId, itemType },
    })

    return data.seo.length > 0
  }

  const toggleUserFavoriteVideo = async ({ video_id, is_favorite }) => {
    const mutation = gql`
      mutation updateUserFavoriteVideo(
        $data: user_favorite_videos_insert_input!
      ) {
        insert_user_favorite_videos_one(
          object: $data
          on_conflict: {
            constraint: user_favorite_videos_pkey
            update_columns: is_favorite
          }
        ) {
          is_favorite
        }
      }
    `

    const { data, errors } = await client.mutate({
      mutation,
      variables: {
        data: { video_id, is_favorite },
      },
    })
    if (errors) {
      throw errors[0]
    }

    return data
  }

  const generateCoupon = async ({ couponId, count, userIds }) => {
    const url = `/.netlify/functions/generate-coupons`
    const token = await getAccessTokenSilently()

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ couponId, count, userIds }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })

    const result = await response.json()

    if (response.status !== 200) {
      throw result.error
    }

    return result
  }

  return {
    getCurrentUser,
    getSignedVideoSourceUrl,
    logUserSearch,
    logUserVideoAction,
    toggleUserFavoriteVideo,
    getBraintreeToken,
    braintreeCheckout,
    getFileData,
    slugExists,
    generateCoupon,
  }
}

export default useCommonQueries
