import React from "react"
import { Button, Form, Input } from "antd"
import { FORM_TYPE_ADD } from "../../enums/FormTypes"

const UserForm = ({ onFinish, initialValues, formType }) => {
  return (
    <Form initialValues={initialValues} onFinish={onFinish} layout={"vertical"}>
      <Form.Item name="user_id" hidden>
        <Input />
      </Form.Item>

      <Form.Item
        label="Nome"
        name="firstname"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Cognome"
        name="lastname"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            type: "email",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Numero di telefono" name="phone_number">
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {formType === FORM_TYPE_ADD ? "inserisci" : "aggiorna"}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default UserForm
