import React from "react"
import { Button, Form } from "antd"
import FeatureItemSelector from "../customFormItems/ContentItemSelector"

const HomepageLayoutForm = ({ onFinish, initialValues, formType }) => {
  return (
    <Form initialValues={initialValues} onFinish={onFinish} layout={"vertical"}>
      <Form.Item label="Elementi in evidenza" name="featured_item_list">
        <FeatureItemSelector multiple={true} />
      </Form.Item>

      <Form.Item label="Elementi suggeriti" name="suggested_item_list">
        <FeatureItemSelector multiple={true} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          aggiorna
        </Button>
      </Form.Item>
    </Form>
  )
}

export default HomepageLayoutForm
