import React, { useState } from "react"
import { gql } from "@apollo/client"
import DataTable from "../../../components/DataTable"
import { Button, Modal, Space } from "antd"
import PageRoot from "../../../components/PageRoot"
import { EyeFilled } from "@ant-design/icons"
import { formatDate } from "../../../utils"
import { ROLE_ADMIN, ROLE_PRODUCT_MANAGER } from "../../../enums/UserRoles"

const ALL_TOPIC_SUGGESTIONS_QUERY = gql`
  {
    topic_suggestions(order_by: { created_at: desc }) {
      id
      created_at
      content
      subject
      user {
        email
        author_rels {
          author {
            firstname
            lastname
          }
        }
      }
    }
  }
`

const TopicSuggestionListPage = () => {
  const [suggestionToView, setSuggestionToView] = useState(null)

  const columns = [
    {
      title: "Oggetto",
      dataIndex: "subject",
    },
    {
      title: "Data creazione",
      render: record => formatDate(record.created_at),
    },
    {
      title: "Azioni",
      render: record => {
        return (
          <Space>
            {/*<Button><Link to={`/contents/categories/view?id=${record.id}`}>mostra</Link></Button>*/}
            <Button
              type="primary"
              onClick={() => {
                setSuggestionToView(record)
              }}
            >
              <EyeFilled />
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <PageRoot authorizedRoles={[ROLE_ADMIN, ROLE_PRODUCT_MANAGER]}>
      <DataTable
        dataKey={"topic_suggestions"}
        query={ALL_TOPIC_SUGGESTIONS_QUERY}
        columns={columns}
      />
      <Modal
        destroyOnClose
        width={"80%"}
        visible={suggestionToView}
        onOk={() => setSuggestionToView(null)}
        onCancel={() => setSuggestionToView(null)}
      >
        {suggestionToView && (
          <div>
            <h1>{suggestionToView.subject}</h1>
            <h3>{suggestionToView.user.email}</h3>
            <h4>{formatDate(suggestionToView.created_at)}</h4>
            <textarea readOnly style={{ width: "100%" }} rows={20}>
              {suggestionToView.content}
            </textarea>
          </div>
        )}
      </Modal>
    </PageRoot>
  )
}

export default TopicSuggestionListPage
