import React, { useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import {
  Button,
  Col,
  DatePicker,
  List,
  Modal,
  Popconfirm,
  Row,
  Table,
  Tag,
} from "antd"
import PageRoot from "../../../components/PageRoot"
import { ROLE_ACCOUNTING, ROLE_ADMIN } from "../../../enums/UserRoles"
import { Link } from "gatsby"
import moment from "moment"
import ErrorScreen from "../../../components/ErrorScreen"
import { formatDate } from "../../../utils"
import { InfoOutlined } from "@ant-design/icons"

const ALL_AUTHOR_COMMISSIONS_QUERY = gql`
  query getAuthorCorseCommissions($startAt: timestamptz, $endAt: timestamptz) {
    author_course_commissions(
      where: {
        _and: [{ end_at: { _gte: $startAt } }, { end_at: { _lte: $endAt } }]
      }
    ) {
      id
      start_at
      end_at
      amount
      author_id
      author {
        id
        firstname
        lastname
      }
      settled
      summary
    }
  }
`

const SIGN_AUTHOR_COMMISSION_PAID_MUTAITON = gql`
  mutation signAuthorCommissionPaid($id: uuid!) {
    update_author_course_commissions_by_pk(
      _set: { settled: true }
      pk_columns: { id: $id }
    ) {
      settled
    }
  }
`

const AuthorCommissionListPage = () => {
  const now = moment()
  const [startAt, setStartAt] = useState(moment().startOf("month").toDate())
  const [endAt, setEndAt] = useState(moment().endOf("month").toDate())

  const [commissionDetailToShow, setCommissionDetailToShow] = useState(null)

  const { data, loading, error, refetch } = useQuery(
    ALL_AUTHOR_COMMISSIONS_QUERY,
    {
      variables: { startAt, endAt },
      fetchPolicy: "network-only",
    }
  )

  const [signAuthorCommissionPaid] = useMutation(
    SIGN_AUTHOR_COMMISSION_PAID_MUTAITON
  )

  if (error) {
    return (
      <PageRoot>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  const columns = [
    {
      title: "Data inizio",
      render: ({ start_at }) => start_at && formatDate(start_at),
    },
    { title: "Data fine", render: ({ end_at }) => formatDate(end_at) },
    {
      title: "Autore",
      render: ({ author }) => {
        return `${author.firstname} ${author.lastname}`
      },
    },
    {
      title: "Commissione",
      dataIndex: "amount",
    },
    {
      title: "Dettaglio",
      render: item => (
        <Button
          type="primary"
          shape="circle"
          icon={<InfoOutlined />}
          onClick={() => {
            setCommissionDetailToShow(item.summary)
          }}
        />
      ),
    },
    {
      title: "Pagato",
      render: ({ id, settled }) => {
        return settled ? (
          <Tag color="blue">Pagato</Tag>
        ) : (
          <Popconfirm
            title="Segna questo come pagato?"
            onConfirm={async () => {
              await signAuthorCommissionPaid({ variables: { id } })
              await refetch()
            }}
            okText="Si"
            cancelText="No"
          >
            <Button>Segna come pagato</Button>
          </Popconfirm>
        )
      },
    },
  ]

  return (
    <PageRoot authorizedRoles={[ROLE_ADMIN]}>
      <Row gutter={[16, 16]} justify="space-between">
        <Col>
          <DatePicker.RangePicker
            size="large"
            disabledDate={current =>
              current &&
              current > now.clone() &&
              current < now.clone().add(-1, "year")
            }
            ranges={{
              "Ultimi 7 giorni": [moment().add(-7, "day"), moment()],
              "Ultimi 30 giorni": [moment().add(-30, "day"), moment()],
              "Questa settimana": [
                moment().startOf("week"),
                moment().endOf("week"),
              ],
              "Questo mese": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
              "Ultimo trimestre": [moment().add(-3, "month"), moment()],
              "Questo anno": [moment().startOf("year"), moment()],
            }}
            allowClear={false}
            value={[moment(startAt), moment(endAt)]}
            onChange={value => {
              setStartAt(value[0].toDate())
              setEndAt(value[1].toDate())
            }}
          />
        </Col>
        <Col>
          <Button type="primary">
            <Link to="/monetization/author-commissions/settings">
              Impostazione provvigione
            </Link>
          </Button>
        </Col>
        <Col span={24}></Col>
      </Row>
      <Table
        rowKey="id"
        loading={loading}
        columns={columns}
        dataSource={data?.author_course_commissions}
      />
      <Modal
        title="Dettaglio sulla provvigione"
        visible={commissionDetailToShow}
        destroyOnClose={true}
        onOk={() => {
          setCommissionDetailToShow(null)
        }}
        onCancel={() => {
          setCommissionDetailToShow(null)
        }}
      >
        {commissionDetailToShow && (
          <List
            itemLayout="hotizontal"
            dataSource={commissionDetailToShow}
            renderItem={(item, index) => (
              <List.Item
              // actions={[
              //   <Link
              //     to={`/monetization/transactions/view?id=${item.purchaseId}`}
              //   >
              //     Dettaglio
              //   </Link>,
              // ]}
              >
                <List.Item.Meta
                  title={item.course}
                  description={`venduto a ${item.soldAtPrice}`}
                />
                <div>Provvigione: {item.commission}</div>
              </List.Item>
            )}
          />
        )}
      </Modal>
    </PageRoot>
  )
}

export default AuthorCommissionListPage
