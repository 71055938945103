import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import CategoryForm from "../../../components/forms/CategoryForm"
import { gql, useMutation } from "@apollo/client"
import { FORM_TYPE_ADD } from "../../../enums/FormTypes"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import { navigate } from "gatsby"
import { Spin } from "antd"
import { CATEGORY } from "../../../enums/ItemTypes"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"

const INSERT_CATEGORY_MUTATION = gql`
  mutation insertSeo($data: seo_insert_input!) {
    insert_seo(objects: [$data]) {
      affected_rows
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const AddCategoryPage = () => {
  const [insertCategory] = useMutation(INSERT_CATEGORY_MUTATION)
  const [loading, setLoading] = useState(false)

  const onFinish = async values => {
    setLoading(true)
    try {
      const clone = { ...values }
      delete clone.seo

      const data = {
        ...values.seo,
        category: {
          data: {
            ...clone,
          },
        },
      }
      await insertCategory({ variables: { data } })
      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/contents/categories")
        },
      })
    } catch (error) {
      uiHandleError({
        error,
        action: () => {},
      })
    }
    setLoading(false)
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={loading}>
        <CategoryForm
          onFinish={onFinish}
          formType={FORM_TYPE_ADD}
          initialValues={{ seo: { item_type: CATEGORY } }}
        />
      </Spin>
    </PageRoot>
  )
}

export default AddCategoryPage
