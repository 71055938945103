import React, { useEffect } from "react"
import { gql, useQuery } from "@apollo/client"
import ErrorScreen from "../ErrorScreen"
import { Select, Spin, Tag } from "antd"
import TypeTag from "../TypeTag"

const ALL_ITEM_LIST_QUERY = gql`
  {
    videos(
      where: {
        _or: [{ state: { _eq: PUBLISHED } }, { state: { _eq: COMING_SOON } }]
        price_policy: { _eq: ON_DEMAND }
        _not: { course_rel: {} }
      }
    ) {
      __typename
      id
      title
      subtitle
      price
    }
    courses(
      where: {
        _or: [{ state: { _eq: PUBLISHED } }, { state: { _eq: COMING_SOON } }]
        price_policy: { _eq: ON_DEMAND }
      }
    ) {
      __typename
      id
      title
      subtitle
      price
    }
    certifications(
      where: {
        _or: [{ state: { _eq: PUBLISHED } }, { state: { _eq: COMING_SOON } }]
      }
    ) {
      __typename
      id
      title
      subtitle
      price
    }
    gift_cards(
      where: {
        _or: [{ state: { _eq: PUBLISHED } }, { state: { _eq: COMING_SOON } }]
      }
    ) {
      __typename
      id
      title
      subtitle
      price
    }
  }
`

const PurchasableItemSelector = ({
  value,
  onChange,
  multiple = false,
  onTotalPriceUpdate,
}) => {
  const { loading, error, data } = useQuery(ALL_ITEM_LIST_QUERY)

  useEffect(() => {
    if (!loading && !error) {
      let totalPrice = getTotalPrice(value || [])
      onTotalPriceUpdate && onTotalPriceUpdate(totalPrice)
    }
  }, [loading, error, data])

  if (loading) {
    return <Spin spinning />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const { videos, courses, certifications, gift_cards } = data

  const items = [...videos, ...courses, ...certifications, ...gift_cards]

  const getTotalPrice = selectedIds => {
    const selectedItem = items.filter(item =>
      (selectedIds || []).includes(item.id)
    )
    return selectedItem.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.price
    }, 0)
  }

  const onItemChange = newValue => {
    onChange && onChange(newValue)
    const totalPrice = getTotalPrice(newValue || [])

    onTotalPriceUpdate && onTotalPriceUpdate(totalPrice)
  }

  const defaultValue = multiple ? [] : null

  return (
    <>
      <Select
        defaultValue={value || defaultValue}
        style={{
          width: "100%",
        }}
        mode={multiple ? "multiple" : null}
        showSearch
        optionFilterProp="children"
        onChange={onItemChange}
      >
        {!multiple && (
          <Select.Option value={null} key={"null"}>
            nessuna
          </Select.Option>
        )}
        {items.map(({ id, title, __typename, price }) => (
          <Select.Option value={id} key={id}>
            <TypeTag type={__typename} />
            <Tag color="magenta">{price}</Tag>
            {title}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

export default PurchasableItemSelector
