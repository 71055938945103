import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import CourseForm from "../../../components/forms/CourseForm"
import { FORM_TYPE_EDIT } from "../../../enums/FormTypes"
import QueryString from "query-string"
import { gql, useMutation, useQuery } from "@apollo/client"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import { Button, Result, Spin } from "antd"
import { Link, navigate } from "gatsby"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import { COURSE } from "../../../enums/ItemTypes"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"

const GET_COURSE_QUERY = gql`
  query getCourse($id: uuid!) {
    courses_by_pk(id: $id) {
      description
      landscape_id
      id
      portrait_id
      short_description
      state
      subtitle
      title
      price_policy
      wallpaper_id
      currency
      header_image_id
      price
      level
      show_faq
      faq
      faq_json
      content_index
      show_learning_path
      learning_path
      is_external
      external_url
      videos(order_by: { index: asc }) {
        index
        video_id
      }
      category_rels {
        category_id
      }
      channel_rels {
        channel_id
      }
      seo {
        title
        description
        slug
        item_type
      }
    }
  }
`

const UPDATE_COURSE_MUTATION = gql`
  mutation updateCourse(
    $id: uuid!
    $data: courses_set_input!
    $seo: seo_insert_input!
  ) {
    update_courses_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
    insert_seo_one(
      object: $seo
      on_conflict: {
        constraint: seo_item_id_key
        update_columns: [title, slug, description]
      }
    ) {
      item_id
    }
  }
`

const DELETE_ALL_COURSE_RELS_MUTATIONS = gql`
  mutation deleteCourseRels($id: uuid!) {
    delete_course_videos(where: { course_id: { _eq: $id } }) {
      affected_rows
    }
    delete_item_category_rels(where: { item_id: { _eq: $id } }) {
      affected_rows
    }
    delete_item_channel_rels(where: { item_id: { _eq: $id } }) {
      affected_rows
    }
  }
`

const INSERT_ALL_COURSE_RELS_MUTATIONS = gql`
  mutation insertCourseRels(
    $videos: [course_videos_insert_input!]!
    $categories: [item_category_rels_insert_input!]!
    $channels: [item_channel_rels_insert_input!]!
  ) {
    insert_course_videos(objects: $videos) {
      affected_rows
    }
    insert_item_category_rels(objects: $categories) {
      affected_rows
    }
    insert_item_channel_rels(objects: $channels) {
      affected_rows
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const EditCoursePage = ({ location }) => {
  const [updating, setUpdating] = useState(false)

  const params = QueryString.parse(location.search)

  const { data, error, loading } = useQuery(GET_COURSE_QUERY, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  })

  const [updateCourse] = useMutation(UPDATE_COURSE_MUTATION)
  const [deleteCourseRels] = useMutation(DELETE_ALL_COURSE_RELS_MUTATIONS)
  const [insertCourseRels] = useMutation(INSERT_ALL_COURSE_RELS_MUTATIONS)

  const handleFormSubmit = async values => {
    setUpdating(true)
    let newValues = { ...values }
    delete newValues.videos
    delete newValues.category_ids
    delete newValues.channel_ids
    delete newValues.seo
    const videoData = values.videos
      ? values.videos.map((video, index) => ({
          course_id: params.id,
          video_id: video.id,
          index,
        }))
      : []

    const courseCategoryRels = values.category_ids
      ? values.category_ids.map(category_id => ({
          item_id: params.id,
          item_type: COURSE,
          category_id,
        }))
      : []

    const courseChannelRels = values.channel_ids
      ? values.channel_ids.map(channel_id => ({
          item_id: params.id,
          item_type: COURSE,
          channel_id,
        }))
      : []

    try {
      const seo = values.seo
      seo.item_id = params.id

      await updateCourse({ variables: { data: newValues, id: params.id, seo } })
      await deleteCourseRels({ variables: { id: params.id } })
      await insertCourseRels({
        variables: {
          videos: videoData,
          categories: courseCategoryRels,
          channels: courseChannelRels,
        },
      })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/contents/courses")
        },
      })
    } catch (error) {
      uiHandleError({ error })
    }
    setUpdating(false)
  }

  if (loading) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <LoadingScreen />
      </PageRoot>
    )
  }

  if (error) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  const course = data.courses_by_pk

  if (!course) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <Result
          status="404"
          title="404"
          subTitle="Elemento non trovato!"
          extra={
            <Button type="primary">
              <Link to={"/contents/courses"}>Torna indietro</Link>
            </Button>
          }
        />
      </PageRoot>
    )
  }

  const initialValues = {
    ...course,
    category_ids: course.category_rels.map(({ category_id }) => category_id),
    channel_ids: course.channel_rels.map(({ channel_id }) => channel_id),
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={updating}>
        <CourseForm
          initialValues={initialValues}
          onFinish={handleFormSubmit}
          formType={FORM_TYPE_EDIT}
        />
      </Spin>
    </PageRoot>
  )
}

export default EditCoursePage
