import PageRoot from "../../../../components/PageRoot"
import DataTable from "../../../../components/DataTable"
import React, { useState } from "react"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../../enums/UserRoles"
import { gql } from "@apollo/client"
import useColumnSearch from "../../../../hooks/useColumnSearch"
import { Button, Modal } from "antd"
import ItemCodeRelForm from "../../../../components/forms/ItemCodeRelForm"
import useProductCode from "../../../../hooks/useProductCode"
import { uiHandleError } from "../../../../utils"
import { PublishStatus } from "../../../../components/Labels"

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const ALL_COURSE_CODE_LIST_QUERY = gql`
  {
    courses(order_by: { title: asc }) {
      id
      title
      price_policy
      code_rel {
        default_code
      }
    }
  }
`

const CourseCodeList = () => {
  const { upsertProductCodeRel } = useProductCode()
  const nameSearchColumnOptions = useColumnSearch({ dataIndex: "title" })
  const codeSearchColumnOptions = useColumnSearch({
    dataIndex: "code_rel.default_code",
  })

  const [itemToEdit, setItemToEdit] = useState(null)
  const [updating, setUpdating] = useState(false)

  const columns = [
    {
      title: "Nome",
      dataIndex: "title",
      ...nameSearchColumnOptions,
    },
    {
      title: "Codice",
      ...codeSearchColumnOptions,
    },
    {
      title: "Tipologia",
      filters: [
        {
          text: "Corsi abbonamento",
          value: "SUBSCRIPTION",
        },
        {
          text: "Corsi on-demand",
          value: "ON_DEMAND",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.price_policy === value,
      render: record => {
        return record.price_policy
      },
    },
    {
      title: "Azioni",
      render: record => {
        return (
          <Button
            type="primary"
            onClick={() => {
              setItemToEdit(record)
            }}
          >
            Aggiorna
          </Button>
        )
      },
    },
  ]

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <DataTable
        dataKey={"courses"}
        query={ALL_COURSE_CODE_LIST_QUERY}
        columns={columns}
        updating={updating}
      />
      {itemToEdit && (
        <Modal
          visible={!!itemToEdit}
          title={itemToEdit.title}
          onCancel={() => {
            setItemToEdit(null)
          }}
          footer={null}
        >
          <ItemCodeRelForm
            initialValues={{
              item_id: itemToEdit.id,
              default_code: itemToEdit.code_rel?.default_code,
            }}
            onFinish={async values => {
              setUpdating(true)
              try {
                await upsertProductCodeRel({
                  variables: {
                    data: {
                      ...values,
                    },
                  },
                })

                setItemToEdit(null)
              } catch (e) {
                uiHandleError(e)
              }
              setUpdating(false)
            }}
          />
        </Modal>
      )}
    </PageRoot>
  )
}

export default CourseCodeList
