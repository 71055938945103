import { defaultTheme } from "./theme"
import { SET_USER } from "./enums/ReducerTypes"

const initialState = {
  theme: defaultTheme,
  user: null,
  isLoadingUser: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.value,
      }
    default:
      return state
  }
}

export { reducer, initialState }
