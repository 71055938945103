import React from "react"
import { Button, Result } from "antd"
import { Link } from "gatsby"

const PermissionDeniedScreen = () => {
  return (
    <Result
      status="403"
      title="403"
      subTitle={`Spiacente, non hai i permessi per visualizzare questa pagina!`}
    >
      <Button
        type="primary"
        onClick={() => {
          window.history.back()
        }}
      >
        Torna indietro
      </Button>
      <br />
      <br />
      Non sei tu?
      <br />
      <Button type="primary" key="console" danger>
        <Link to={"/auth/sign-out"}>Cambia utente</Link>
      </Button>
    </Result>
  )
}

export default PermissionDeniedScreen
