export const VIDEO = "VIDEO"
export const COURSE = "COURSE"
export const AUTHOR = "AUTHOR"
export const CATEGORY = "CATEGORY"
export const CHANNEL = "CHANNEL"
export const CERTIFICATION = "CERTIFICATION"
export const SUBSCRIPTION = "SUBSCRIPTION"
export const GIFT_CARD = "GIFT_CARD"
export const BUNDLE = "BUNDLE"
export const SPECIAL_PRICE = "SPECIAL_PRICE"
