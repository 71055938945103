import React, { useEffect, useState } from "react"
import { gql } from "@apollo/client"
import DataTable from "../../../components/DataTable"
import { Link } from "gatsby"
import { Button, Space } from "antd"
import PageRoot from "../../../components/PageRoot"
import useColumnSearch from "../../../hooks/useColumnSearch"
import LoadingScreen from "../../../components/LoadingScreen"
import { formatDate } from "../../../utils"
import moment from "moment"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_WAREHOUSE,
} from "../../../enums/UserRoles"

const ALL_SUBSCRIPTIONS_LIST_QUERY = gql`
  {
    user_subscriptions(
      order_by: { created_at: desc }
      where: { state: {}, braintree_subscription_id: { _neq: "" } }
      distinct_on: created_at
    ) {
      braintree_subscription_id
      end_at
      start_at
      state
      user_id
      user {
        basic_profile {
          email
          firstname
          lastname
          phone_number
        }
      }
    }
  }
`

const SubscriptionsListPage = () => {
  const emailSearchColumnOptions = useColumnSearch({
    dataIndex: "user.basic_profile.email",
  })
  const firstnameSearchColumnOptions = useColumnSearch(
    "user.basic_profile.firstname"
  )
  const lastnameSearchColumnOptions = useColumnSearch(
    "user.basic_profile.lastname"
  )
  const phoneNumberSearchColumnOptions = useColumnSearch(
    "user.basic_profile.phone_number"
  )

  const [refreshing, setRefreshing] = useState(false)

  useEffect(() => {
    if (refreshing) {
      setRefreshing(false)
    }
  }, [refreshing])

  if (refreshing) {
    return (
      <PageRoot>
        {" "}
        <LoadingScreen />{" "}
      </PageRoot>
    )
  }

  const columns = [
    {
      title: "Id",
      dataIndex: "user_id",
    },
    {
      title: "Nome",
      ...firstnameSearchColumnOptions,
      render: record => {
        return record.user?.basic_profile?.firstname
      },
    },
    {
      title: "Cognome",
      ...lastnameSearchColumnOptions,
      render: record => {
        return record.user?.basic_profile?.lastname
      },
    },
    {
      title: "Email",
      ...emailSearchColumnOptions,
      render: record => {
        return record.user?.basic_profile?.email
      },
    },
    {
      title: "Numero di telefono",
      ...phoneNumberSearchColumnOptions,
      render: record => {
        return record.user?.basic_profile?.phone_number
      },
    },
    {
      title: "Stato",
      /* TODO verificare questa parte dei filtri */
      render: record => {
        return record.state === "Active" ? "Attivo" : "Disattivato"
      },
      filter: [
        {
          text: "Attivo",
          value: "Active",
        },
        {
          text: "Disattivato",
          value: "Active",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return (
          (value === "active" && record.state === "Active") ||
          (value === "active" && record.state === "Active")
        )
      },
    },
    {
      title: "Data Inizio",
      render: record => {
        return record ? formatDate(record.start_at, { format: "L" }) : null
      },
      sorter: (a, b) => {
        return moment(a?.start_at) - moment(b?.start_at)
      },
    },
    {
      title: "Data Fine",
      render: record => {
        return record ? formatDate(record.end_at, { format: "L" }) : null
      },
      sorter: (a, b) => {
        return moment(a?.end_at) - moment(b?.end_at)
      },
    },
    {
      title: "Id Abbonamento",
      dataIndex: "braintree_subscription_id",
    },
    {
      title: "Azioni",
      render: record => {
        return (
          <Space>
            <Button type="primary">
              <Link to={`/users/all/view?id=${window.btoa(record.user_id)}`}>
                Dettaglio
              </Link>
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <PageRoot
      authorizedRoles={[
        ROLE_ADMIN,
        ROLE_ACCOUNTING,
        ROLE_SALE,
        ROLE_WAREHOUSE,
        ROLE_PRODUCT_MANAGER,
      ]}
    >
      <DataTable
        rowKey="user_id"
        dataKey={"user_subscriptions"}
        query={ALL_SUBSCRIPTIONS_LIST_QUERY}
        columns={columns}
      />
    </PageRoot>
  )
}

export default SubscriptionsListPage
