import React, { useEffect, useState } from "react"
import "./PushNotificationPreview.less"
import { Col, Row, Select, Space } from "antd"
import { BellFilled } from "@ant-design/icons"

const PushNotificationPreview = ({ notification }) => {
  const backgroundImages = {
    android: "/assets/images/android.svg",
    ios: "/assets/images/ios.svg",
  }

  const { title, subtitle, message } = notification

  const [osToShow, setOsToShow] = useState("android")
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("")
  useEffect(() => {
    setBackgroundImageUrl(`url(${backgroundImages[osToShow]})`)
  }, [osToShow])

  return (
    <div className="push-notification-preview">
      <div
        className="device-mockup"
        style={{ backgroundImage: backgroundImageUrl }}
      >
        <div className={`notification ${osToShow}`}>
          <div className="app-name">
            <Space>
              <BellFilled />
              <span>SportScience</span>
            </Space>
          </div>
          <h2 className="title">{title}</h2>
          <h4 className="subtitle">{subtitle}</h4>
          <p className="message">{message}</p>
        </div>
      </div>
      <Row justify="space-around">
        <Col>
          <Select
            defaultValue={osToShow}
            style={{ width: 120 }}
            onChange={value => {
              setOsToShow(value)
            }}
          >
            <Select.Option value="android">Android</Select.Option>
            <Select.Option value="ios">iOS</Select.Option>
          </Select>
        </Col>
      </Row>
    </div>
  )
}

export default PushNotificationPreview
