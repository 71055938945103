import React from "react"
import QueryString from "query-string"
import { gql, useQuery } from "@apollo/client"
import { Card, Col, Row, Spin } from "antd"
import {
  CouponAssigner,
  CouponCriterionRecap,
  CouponGenerator,
} from "../../../components/coupon"
import PageRoot from "../../../components/PageRoot"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_WAREHOUSE,
} from "../../../enums/UserRoles"
import ErrorScreen from "../../../components/ErrorScreen"
import LoadingScreen from "../../../components/LoadingScreen"
import { uiHandleError } from "../../../utils"

const GET_COUPON_CRITERION_QUERY = gql`
  query getCouponCriterion($id: uuid!) {
    coupon_criteria_by_pk(id: $id) {
      id
      title
      subtitle
      description
      short_description
      picture_id
      created_at
      created_by
      discount_type
      end_at
      exclusive_for_content_id
      exclusive_for_content_type
      percentage
      start_at
      value
      total_codes: codes_aggregate {
        aggregate {
          count
        }
      }
      consumed_codes: codes_aggregate(
        where: { consumed_at: { _is_null: false } }
      ) {
        aggregate {
          count
        }
      }
      available_codes: codes_aggregate(where: { user_id: { _is_null: true } }) {
        aggregate {
          count
        }
      }
    }
  }
`

const authorizedRoles = [
  ROLE_ADMIN,
  ROLE_ACCOUNTING,
  ROLE_SALE,
  ROLE_WAREHOUSE,
  ROLE_PRODUCT_MANAGER,
]

const CouponSummaryViewPage = ({ location, noLayout = false }) => {
  const params = QueryString.parse(location.search)

  const { data, error, loading, refetch } = useQuery(
    GET_COUPON_CRITERION_QUERY,
    {
      variables: { id: params.id },
      fetchPolicy: "network-only",
    }
  )

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const pageContent = (
    <Spin spinning={loading}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="Dettaglio coupon">
            <CouponCriterionRecap
              loading={loading}
              data={data?.coupon_criteria_by_pk}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Genera coupon liberi">
            <CouponGenerator
              data={data?.coupon_criteria_by_pk}
              onFinish={refetch}
              onError={error => uiHandleError({ error })}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Genera per utenti specifici">
            <CouponAssigner
              loading={loading}
              data={data?.coupon_criteria_by_pk}
              onFinish={refetch}
              onError={error => uiHandleError({ error })}
            />
          </Card>
        </Col>
      </Row>
    </Spin>
  )

  return noLayout ? (
    pageContent
  ) : (
    <PageRoot authorizedRoles={authorizedRoles}>{pageContent}</PageRoot>
  )
}

export default CouponSummaryViewPage
