import { Tag } from "antd"
import React from "react"

const itemColors = {
  videos: "blue",
  courses: "yellow",
  certifications: "green",
  gift_cards: "red",
}

const typeNames = {
  videos: "Video",
  courses: "Corso",
  certifications: "Certificazione",
  gift_cards: "Gift Card",
}

const TypeTag = ({ type }) => (
  <Tag color={itemColors[type] ?? "red"}>{typeNames[type] ?? type}</Tag>
)

export default TypeTag
