import * as React from 'react'
import PageRoot from '../components/PageRoot'

// markup
const IndexPage = () => {
  return (
    <PageRoot>
      index
    </PageRoot>
  )
}

export default IndexPage
