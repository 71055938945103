import React from 'react'

const view = () => {
  return (
    <div>
      view
    </div>
  )
}

export default view
