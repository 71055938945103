import React, { useState } from "react"
import PageRoot from "../../../../components/PageRoot"
import { gql, useMutation } from "@apollo/client"
import { FORM_TYPE_ADD } from "../../../../enums/FormTypes"
import { uiHandleError, uiHandleSuccess } from "../../../../utils"
import { navigate } from "gatsby"
import { Spin } from "antd"
import QueryString from "query-string"
import AuthorCourseCommissionSettingForm from "../../../../components/forms/AuthorCourseCommissionSettingForm"
import { ROLE_ADMIN } from "../../../../enums/UserRoles"

const INSERT_AUTHOR_COURSE_COMMISSION_MUTATION = gql`
  mutation insertAuthorCourseCommission(
    $data: author_course_commission_settings_insert_input!
  ) {
    insert_author_course_commission_settings_one(object: $data) {
      author_id
    }
  }
`

const AddAuthorCourseCommissionPage = ({ location }) => {
  const [insertAuthorCourseCommissionSetting] = useMutation(
    INSERT_AUTHOR_COURSE_COMMISSION_MUTATION
  )
  const [loading, setLoading] = useState(false)

  const { authorId, courseId } = QueryString.parse(location.search)

  const onFinish = async values => {
    setLoading(true)

    try {
      await insertAuthorCourseCommissionSetting({ variables: { data: values } })
      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/monetization/author-commissions/settings")
        },
      })
    } catch (error) {
      uiHandleError({
        error,
        action: () => {},
      })
    }
    setLoading(false)
  }

  return (
    <PageRoot allowedRoles={[ROLE_ADMIN]}>
      <Spin spinning={loading}>
        <AuthorCourseCommissionSettingForm
          onFinish={onFinish}
          formType={FORM_TYPE_ADD}
          initialValues={{ author_id: authorId, course_id: courseId }}
        />
      </Spin>
    </PageRoot>
  )
}

export default AddAuthorCourseCommissionPage
