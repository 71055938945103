import React from "react"
import { gql, useQuery } from "@apollo/client"
import ErrorScreen from "../ErrorScreen"
import { Select, Spin } from "antd"

const ALL_CERTIFICATION_LIST_QUERY = gql`
  {
    certifications(order_by: { title: asc }) {
      id
      title
    }
  }
`

const CertificationSelector = ({ value, onChange, multiple = false }) => {
  const { loading, error, data } = useQuery(ALL_CERTIFICATION_LIST_QUERY)

  if (loading) {
    return <Spin spinning />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const { certifications } = data

  const onCertificationChange = newValue => {
    onChange(newValue)
  }

  const defaultValue = multiple ? [] : null

  return (
    <>
      <Select
        defaultValue={value || defaultValue}
        style={{
          width: "100%",
        }}
        mode={multiple ? "multiple" : null}
        showSearch
        optionFilterProp="children"
        onChange={onCertificationChange}
      >
        {!multiple && (
          <Select.Option value={null} key={"null"}>
            nessuno
          </Select.Option>
        )}
        {certifications.map(({ id, title }) => (
          <Select.Option value={id} key={id}>
            {title}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

export default CertificationSelector
