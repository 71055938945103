import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import GiftCardForm from "../../../components/forms/GiftCardForm"
import { FORM_TYPE_EDIT } from "../../../enums/FormTypes"
import QueryString from "query-string"
import { gql, useMutation, useQuery } from "@apollo/client"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import { Button, Result, Spin } from "antd"
import { Link, navigate } from "gatsby"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
} from "../../../enums/UserRoles"
import moment from "moment"

const GET_GIFT_CARD_QUERY = gql`
  query getGiftCard($id: uuid!) {
    gift_cards_by_pk(id: $id) {
      id
      title
      subtitle
      description
      short_description
      picture_id
      created_at
      created_by
      criterion_id
      state
      seo {
        title
        description
        slug
        item_type
      }
      price
    }
  }
`

const UPDATE_GIFT_CARD_MUTATION = gql`
  mutation updateCourse(
    $id: uuid!
    $data: gift_cards_set_input!
    $seo: seo_insert_input!
  ) {
    update_gift_cards_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
    insert_seo_one(
      object: $seo
      on_conflict: {
        constraint: seo_item_id_key
        update_columns: [title, slug, description]
      }
    ) {
      item_id
    }
  }
`

const authorizedRoles = [
  ROLE_ADMIN,
  ROLE_ACCOUNTING,
  ROLE_SALE,
  ROLE_PRODUCT_MANAGER,
]

const EditGiftCardPage = ({ location }) => {
  const [updating, setUpdating] = useState(false)

  const params = QueryString.parse(location.search)

  const { data, error, loading } = useQuery(GET_GIFT_CARD_QUERY, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  })

  const [updateGiftCard] = useMutation(UPDATE_GIFT_CARD_MUTATION)

  const handleFormSubmit = async values => {
    setUpdating(true)
    let newValues = { ...values }
    delete newValues.seo

    try {
      const seo = values.seo
      seo.item_id = params.id

      await updateGiftCard({
        variables: { data: newValues, id: params.id, seo },
      })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/promo/gift-cards")
        },
      })
    } catch (error) {
      uiHandleError({ error })
    }
    setUpdating(false)
  }

  if (loading) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <LoadingScreen />
      </PageRoot>
    )
  }

  if (error) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  const couponCriterion = data.gift_cards_by_pk

  if (!couponCriterion) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <Result
          status="404"
          title="404"
          subTitle="Elemento non trovato!"
          extra={
            <Button type="primary">
              <Link to={"/promo/gift-cards"}>Torna indietro</Link>
            </Button>
          }
        />
      </PageRoot>
    )
  }

  const initialValues = {
    ...couponCriterion,
    start_at: couponCriterion.start_at
      ? moment(couponCriterion.start_at)
      : null,
    end_at: couponCriterion.end_at ? moment(couponCriterion.end_at) : null,
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={updating}>
        <GiftCardForm
          initialValues={initialValues}
          onFinish={handleFormSubmit}
          formType={FORM_TYPE_EDIT}
        />
      </Spin>
    </PageRoot>
  )
}

export default EditGiftCardPage
