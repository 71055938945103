import React from "react"
import * as QueryString from "query-string"
import { gql, useQuery } from "@apollo/client"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_WAREHOUSE,
} from "../../../enums/UserRoles"
import PageRoot from "../../../components/PageRoot"
import { Card, Col, List, Row } from "antd"
import { Link } from "gatsby"
import NoDataScreen from "../../../components/NoDataScreen"
import { formatDate } from "../../../utils"
import CertificationShippedSetter from "../../../components/CertificationShippedSetter"

const GET_USER_CERTIFICATION_DATA_QUERY = gql`
  query getUserCertification($itemId: uuid!, $userId: String!) {
    user_inventory_items_by_pk(item_id: $itemId, user_id: $userId) {
      certification_state {
        exam_submit_at
        last_exam_at
        passed
        shipped
        shipping_required
        shipping_address
        exam_result
      }
      certification {
        title
        type
        id
      }
      user {
        role
        basic_profile {
          firstname
          lastname
          email
          phone_number
        }
        billing_profile {
          firstname
          lastname
          email
          phone_number
        }
      }
    }
  }
`

const UserCertificationDetailPage = ({ location }) => {
  const { itemId, userId } = QueryString.parse(location.search)

  const { loading, error, data } = useQuery(GET_USER_CERTIFICATION_DATA_QUERY, {
    variables: {
      itemId,
      userId: atob(userId),
    },
    fetchPolicy: "network-only",
  })

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const { certification_state, certification, user } =
    data.user_inventory_items_by_pk

  const { firstname, lastname, email, phone_number } = {
    ...user.basic_profile,
    ...user.billing_profile,
  }

  return (
    <PageRoot
      authorizedRoles={[
        ROLE_ADMIN,
        ROLE_ACCOUNTING,
        ROLE_WAREHOUSE,
        ROLE_SALE,
        ROLE_PRODUCT_MANAGER,
      ]}
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card
            title="Profilo utente"
            extra={<Link href={`/users/view?id=${userId}`}>più info</Link>}
          >
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta title={"Nome"} />
                <div>{firstname}</div>
              </List.Item>
              <List.Item>
                <List.Item.Meta title={"Cognome"} />
                <div>{lastname}</div>
              </List.Item>
              <List.Item>
                <List.Item.Meta title={"Email"} />
                <div>{email}</div>
              </List.Item>
              <List.Item>
                <List.Item.Meta title={"Telefono"} />
                <div>{phone_number}</div>
              </List.Item>
            </List>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title="Info certificazione"
            extra={
              <Link href={`/contents/certifications/edit?id=${itemId}`}>
                più info
              </Link>
            }
          >
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta title={"Titolo"} />
                <div>{certification.title}</div>
              </List.Item>
              <List.Item>
                <List.Item.Meta title={"Tipologia"} />
                <div>{certification.type}</div>
              </List.Item>
            </List>
          </Card>
        </Col>
        <Col span={24}>
          <Card title="Stato certificazione">
            {certification_state ? (
              <List itemLayout="horizontal">
                <List.Item>
                  <List.Item.Meta title={"Titolo"} />
                  <div>{certification.title}</div>
                </List.Item>
                <List.Item>
                  <List.Item.Meta title={"Stato"} />
                  <div>
                    {certification_state?.passed ? "Passato" : "Non passato"}
                  </div>
                </List.Item>
                <List.Item>
                  <List.Item.Meta title={"Esame sostenuto il"} />
                  <div>
                    {certification_state?.last_exam_at
                      ? formatDate(certification_state.last_exam_at)
                      : "Non ancora sostenuto"}
                  </div>
                </List.Item>
                {certification_state?.exam_result && (
                  <List.Item>
                    <List.Item.Meta title={"Risultato esame"} />
                    <p>
                      Risposte corrette totali:{" "}
                      {certification_state?.exam_result.totalCorrectAnswers}
                      <br />
                      Risposte corrette:{" "}
                      {certification_state?.exam_result.totalUserCorrectAnswers}
                      <br />
                      Risposte sbagliate:
                      {certification_state?.exam_result.wrongAnswers}
                    </p>
                  </List.Item>
                )}
                <List.Item>
                  <List.Item.Meta title={"Spedizione?"} />
                  <div>
                    {certification_state?.shipping_required
                      ? "Richiede spedizione"
                      : "No"}
                  </div>
                </List.Item>
                {certification_state?.shipping_required && (
                  <List.Item>
                    <List.Item.Meta title={"Spedito?"} />
                    <div>
                      <CertificationShippedSetter
                        userId={atob(userId)}
                        certificationId={itemId}
                        shipped={certification_state?.shipped}
                      />
                    </div>
                  </List.Item>
                )}
                {certification_state?.shipping_address && (
                  <List.Item>
                    <List.Item.Meta title={"Indirizzo di spedizione:"} />
                    <p>
                      {certification_state?.shipping_address.firstname}{" "}
                      {certification_state?.shipping_address.lastname}
                      <br />
                      {certification_state?.shipping_address?.address && (
                        <>
                          {
                            certification_state?.shipping_address?.address
                              ?.address_line1
                          }
                          <br />
                          {certification_state?.shipping_address?.address?.city}
                          <br />
                          {
                            certification_state?.shipping_address?.address
                              ?.postal_code
                          }
                        </>
                      )}
                    </p>
                  </List.Item>
                )}
              </List>
            ) : (
              <NoDataScreen />
            )}
          </Card>
        </Col>
      </Row>
    </PageRoot>
  )
}

export default UserCertificationDetailPage
