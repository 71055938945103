import React from 'react'
import PageRoot from '../components/PageRoot'
import NoDataScreen from '../components/NoDataScreen'

const NotFoundPage = () => (
  <PageRoot>
    <h1>404: Not Found</h1>
    <NoDataScreen/>
  </PageRoot>
)

export default NotFoundPage
