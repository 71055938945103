import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import SpecialPriceForm from "../../../components/forms/SpecialPriceForm"
import { FORM_TYPE_EDIT } from "../../../enums/FormTypes"
import QueryString from "query-string"
import { gql, useMutation, useQuery } from "@apollo/client"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import { Button, Result, Spin } from "antd"
import { Link, navigate } from "gatsby"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import moment from "moment"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
} from "../../../enums/UserRoles"

const authorizedRoles = [
  ROLE_ADMIN,
  ROLE_ACCOUNTING,
  ROLE_SALE,
  ROLE_PRODUCT_MANAGER,
]

const GET_SPECIAL_PRICE_QUERY = gql`
  query getSpecialPrice($id: uuid!) {
    special_prices_by_pk(item_id: $id) {
      end_at
      final_price
      item_id
      start_at
      title
    }
  }
`

const UPDATE_SPECIAL_PRICE_MUTATION = gql`
  mutation updateSpecialPrice($id: uuid!, $data: special_prices_set_input) {
    update_special_prices_by_pk(pk_columns: { item_id: $id }, _set: $data) {
      item_id
    }
  }
`

const EditSpecialPricePage = ({ location }) => {
  const [updating, setUpdating] = useState(false)

  const params = QueryString.parse(location.search)

  const { data, error, loading } = useQuery(GET_SPECIAL_PRICE_QUERY, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  })

  const [updateSpecialPrice] = useMutation(UPDATE_SPECIAL_PRICE_MUTATION)

  const handleFormSubmit = async values => {
    setUpdating(true)
    try {
      await updateSpecialPrice({
        variables: { data: values, id: params.id },
      })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/promo/special-prices")
        },
      })
    } catch (error) {
      uiHandleError({ error })
    }
    setUpdating(false)
  }

  if (loading) {
    return (
      <PageRoot>
        <LoadingScreen />
      </PageRoot>
    )
  }

  if (error) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  const specialPrice = data.special_prices_by_pk

  if (!specialPrice) {
    return (
      <PageRoot>
        <Result
          status="404"
          title="404"
          subTitle="Elemento non trovato!"
          extra={
            <Button type="primary">
              <Link to={"/promo/special-prices"}>Torna indietro</Link>
            </Button>
          }
        />
      </PageRoot>
    )
  }

  const { start_at, end_at } = specialPrice

  const initialValues = {
    ...specialPrice,
    start_at: start_at ? moment(start_at) : null,
    end_at: end_at ? moment(end_at) : null,
  }

  return (
    <PageRoot>
      <Spin spinning={updating}>
        <SpecialPriceForm
          initialValues={initialValues}
          onFinish={handleFormSubmit}
          formType={FORM_TYPE_EDIT}
        />
      </Spin>
    </PageRoot>
  )
}

export default EditSpecialPricePage
