import { gql, useMutation } from "@apollo/client"

const UPSERT_PRODUCT_CODE_REL_MUTATION = gql`
  mutation upsertProductCodeRel($data: item_code_rels_insert_input!) {
    insert_item_code_rels_one(
      object: $data
      on_conflict: {
        constraint: item_code_rels_pkey
        update_columns: [default_code]
      }
    ) {
      item_id
    }
  }
`

const useProductCode = () => {
  // user mutation to update product code_rel
  const [upsertProductCodeRel] = useMutation(UPSERT_PRODUCT_CODE_REL_MUTATION)

  return {
    upsertProductCodeRel,
  }
}

export default useProductCode
