import React from 'react'
import { gql, useQuery } from '@apollo/client'
import ErrorScreen from '../ErrorScreen'
import { Select, Spin, Tag } from 'antd'

const ALL_ITEM_LIST_QUERY = gql`
    {
        videos(where: {state: {_eq: PUBLISHED}}) {
            __typename
            id
            title
            subtitle
        }
        courses(where: {state: {_eq: PUBLISHED}}) {
            __typename
            id
            title
            subtitle
        }
    }
`

const TypeTag = ({ type }) => (
  <Tag color={type === 'videos' ? 'blue' : 'green'}>{type === 'videos' ? 'Video' : 'Corso'}</Tag>
)

const ItemSelector = ({ value, onChange, multiple = false }) => {
  const { loading, error, data } = useQuery(ALL_ITEM_LIST_QUERY)

  if (loading) {
    return <Spin spinning/>
  }

  if (error) {
    return <ErrorScreen error={error}/>
  }

  const { videos, courses } = data

  const items = [...videos, ...courses]

  const onItemChange = (newValue) => {
    onChange(newValue)
  }

  const defaultValue = multiple ? [] : null

  return (
    <>
      <Select
        defaultValue={value || defaultValue}
        style={{
          width: '100%',
        }}
        mode={multiple ? 'multiple' : null}
        showSearch
        optionFilterProp="children"
        onChange={onItemChange}
      >
        {!multiple && <Select.Option value={null} key={'null'}>nessuna</Select.Option>}
        {items.map(({ id, title, __typename }) => (
          <Select.Option value={id} key={id}><TypeTag type={__typename}/>{title}</Select.Option>
        ))}
      </Select>
    </>
  )
}

export default ItemSelector
