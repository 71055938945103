import React, { useState } from "react"
import { Modal, Select } from "antd"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_TRAINER,
  ROLE_USER,
  ROLE_VIP,
  ROLE_WAREHOUSE,
} from "../enums/UserRoles"
import { gql, useMutation } from "@apollo/client"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { getRoleTranslation } from "../utils"

const UPDATE_USER_ROLE_MUTATION = gql`
  mutation updateRole($userId: String!, $role: enum_user_roles_enum) {
    update_auth0_users_by_pk(
      pk_columns: { user_id: $userId }
      _set: { role: $role }
    ) {
      user_id
    }
  }
`

const UserRoleSetter = ({
  userId,
  role = ROLE_USER,
  presetRoles = [
    ROLE_USER,
    ROLE_ADMIN,
    ROLE_ACCOUNTING,
    ROLE_SALE,
    ROLE_PRODUCT_MANAGER,
    ROLE_WAREHOUSE,
    ROLE_TRAINER,
    ROLE_VIP,
  ],
}) => {
  const [updateRole] = useMutation(UPDATE_USER_ROLE_MUTATION)
  const [internalRole, setInternalRole] = useState(role)

  const handleSelect = async value => {
    Modal.confirm({
      title: "Impostazione ruolo",
      icon: <ExclamationCircleOutlined />,
      content: `Stai cambiando il ruolo da "${getRoleTranslation(
        role
      )}" a "${getRoleTranslation(value)}" di questo utente.`,
      onOk: async () => {
        try {
          await updateRole({
            variables: {
              userId,
              role: value,
            },
          })
          setInternalRole(value)
        } catch (err) {
          console.log({ updateUserRoleError: err })
        }
      },
      onCancel() {
        setInternalRole(internalRole)
      },
    })
  }

  return (
    <Select onChange={handleSelect} value={internalRole} style={{ width: 160 }}>
      {presetRoles.map((role, index) => (
        <Select.Option key={index} value={role}>
          {getRoleTranslation(role)}
        </Select.Option>
      ))}
    </Select>
  )
}

export default UserRoleSetter
