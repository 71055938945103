import React, { useEffect, useState } from "react"
import { Table } from "antd"
import { useQuery } from "@apollo/client"
import ErrorScreen from "./ErrorScreen"

const DataTable = ({
  query,
  queryVariables,
  dataKey = null,
  columns,
  rowKey,
  dataTransformer,
  updating,
  fetchPolicy = "network-only",
  onLoad,
  ...restProps
}) => {
  const { loading, error, data, refetch } = useQuery(query, {
    variables: queryVariables,
    fetchPolicy: fetchPolicy,
  })

  const [lastUpdating, setLastUpdating] = useState(false)

  useEffect(() => {
    if (lastUpdating !== updating && updating === false) {
      refetch()
    }

    setLastUpdating(updating)
  }, [updating])

  useEffect(() => {
    if (!loading) {
      onLoad && onLoad({ loading, data, error, refetch })
    }
  }, [loading])

  if (error) {
    return <ErrorScreen error={error} />
  }

  let tableData = []

  if (!loading) {
    tableData = dataKey ? data[dataKey] : data
    tableData = dataTransformer ? dataTransformer(data) : tableData
  }

  return (
    <Table
      {...restProps}
      bordered
      rowKey={rowKey || "id"}
      loading={loading}
      dataSource={tableData}
      columns={columns}
      // pagination={{ pageSize: 100 }}
      scroll={{ x: "100%" }}
    />
  )
}

export default DataTable
