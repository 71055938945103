import React, { useMemo, useState } from "react"
import {
  Avatar,
  Button,
  Card,
  Col,
  Modal,
  Popconfirm,
  Row,
  Space,
  Statistic,
} from "antd"
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteFilled,
} from "@ant-design/icons"
import { useGlobalValue } from "../hooks/useGlobalValue"
import { ROLE_ADMIN } from "../enums/UserRoles"
import UserSelector from "./customFormItems/UserSelector"
import { gql, useMutation } from "@apollo/client"
import { renderUserLabel, uiHandleError } from "../utils"

const ADD_USER_AUTHOR_REL_MUTATION = gql`
  mutation addUserAuthorRel($data: user_author_rels_insert_input!) {
    insert_user_author_rels_one(object: $data) {
      user_id
    }
  }
`

const REMOVE_USER_AUTHOR_REL_MUTATION = gql`
  mutation removeUserAuthorRel($authorId: uuid!, $userId: String!) {
    delete_user_author_rels_by_pk(author_id: $authorId, user_id: $userId) {
      user_id
    }
  }
`

const UserAuthorManager = ({ author }) => {
  const authorUser = author.user_rel?.user

  const [showUserSelectModal, setShowUserSelectModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(authorUser?.user_id)

  const [addUserAuthorRel] = useMutation(ADD_USER_AUTHOR_REL_MUTATION)
  const [removeUserAuthorRel] = useMutation(REMOVE_USER_AUTHOR_REL_MUTATION)

  const handleAssignUser = async () => {
    try {
      if (!selectedUser) {
        return uiHandleError({
          error: new Error("Selezionare un utente da associare!"),
        })
      }

      await addUserAuthorRel({
        variables: { data: { user_id: selectedUser, author_id: author.id } },
      })

      setShowUserSelectModal(false)
      window.location.reload()
    } catch (error) {
      uiHandleError({ error })
    }
  }

  const handleRemoveUserButtonClick = async () => {
    try {
      await removeUserAuthorRel({
        variables: { userId: authorUser.user_id, authorId: author.id },
      })

      window.location.reload()
    } catch (error) {
      uiHandleError({ error })
    }
  }

  const authorUserElement = useMemo(() => {
    if (authorUser) {
      // remove user
      return (
        <Space>
          <span>{renderUserLabel(authorUser)}</span>
          <Popconfirm
            title={
              "Sei sicuro di voler eliminare il collegamento con questo utente?"
            }
            onConfirm={handleRemoveUserButtonClick}
            okText="Si"
            cancelText="No"
          >
            <Button type="danger" icon={<DeleteFilled />} />
          </Popconfirm>
        </Space>
      )
    }

    // add user
    return (
      <Button
        type="primary"
        onClick={() => {
          setShowUserSelectModal(true)
        }}
      >
        Assegna ad un utente
      </Button>
    )
  }, [authorUser])

  return (
    <div>
      <Statistic
        title="Account associato"
        value={" "}
        prefix={authorUserElement}
      />

      <Modal
        title={"Selezionare l'utente da associare"}
        visible={showUserSelectModal}
        onCancel={() => {
          setShowUserSelectModal(false)
        }}
        onOk={handleAssignUser}
      >
        <UserSelector onChange={item => setSelectedUser(item)} />
      </Modal>
    </div>
  )
}

const AuthorInvestmentCard = ({
  author,
  totalInvestment,
  totalRecessed,
  totalCourseCount,
  totalSalesCount,
}) => {
  const profit = totalRecessed - totalInvestment

  const [{ user }] = useGlobalValue()

  return (
    <Card loading={!author}>
      {author && (
        <>
          <Row gutter={64}>
            <Col>
              <Space>
                <Avatar
                  size={64}
                  src={`/.netlify/functions/get-download-url?key=${author.picture?.s3_key}`}
                />
                <div>
                  <h4>{author.title}</h4>
                  <h2>
                    {author.firstname} {author.lastname}
                  </h2>
                </div>
              </Space>
            </Col>
            <Col>
              {user.role === ROLE_ADMIN && (
                <Statistic
                  title="Profitto"
                  value={profit}
                  decimalSeparator={","}
                  groupSeparator={"."}
                  precision={2}
                  valueStyle={{ color: profit > 0 ? "#3f8600" : "#cf1322" }}
                  prefix={
                    profit > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />
                  }
                  suffix="EUR"
                />
              )}
            </Col>
            <Col>
              <UserAuthorManager author={author} />
            </Col>
          </Row>
          <br />
          {user.role === ROLE_ADMIN && (
            <Row gutter={[16, 16]}>
              <Col>
                <Statistic
                  title="Investimento complessivo"
                  value={totalInvestment}
                />
              </Col>
              <Col>
                <Statistic title="Incasso generato" value={totalRecessed} />
              </Col>
              <Col>
                <Statistic
                  title="Numero di Corsi contribuiti"
                  value={totalCourseCount}
                />
              </Col>
              <Col>
                <Statistic
                  title="Numero di corsi venduti"
                  value={totalSalesCount}
                />
              </Col>
            </Row>
          )}
        </>
      )}
    </Card>
  )
}

export default AuthorInvestmentCard
