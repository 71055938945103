import React from "react"
import { Skeleton } from "antd"

const LoadingScreen = () => {
  return (
    <div style={{ padding: 16 }}>
      <Skeleton active round />
    </div>
  )
}

export default LoadingScreen
