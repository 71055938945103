import { Button, DatePicker, Space } from "antd"
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons"
import React, { useState } from "react"
import { formatDate, getValueByPath } from "../utils"
import moment from "moment"

const { RangePicker } = DatePicker

const useColumnDateFilter = ({ dataIndex }) => {
  let searchInput

  const [dateRange, setDateRange] = useState([])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
  }

  const handleReset = clearFilters => {
    clearFilters()
    setDateRange([])
  }

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Space>
          <RangePicker
            size="large"
            value={selectedKeys[0] ?? []}
            onChange={value => {
              setSelectedKeys([value])
            }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="large"
          >
            Filtra
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="large">
            Reimposta
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <CalendarOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex]
    //     ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    //     : "",
    onFilter: (value, record) => {
      let date = getValueByPath(record, dataIndex)
      if (!date) {
        return false
      }

      date = moment(date)

      return date >= value[0] && date <= value[1]
    },
    onFilterDropdownVisibleChange: visible => {
      // if (visible) {
      //   setTimeout(() => searchInput.select(), 100)
      // }
    },
    render: record => {
      return getValueByPath(record, dataIndex)
        ? formatDate(getValueByPath(record, dataIndex), { format: "L LT" })
        : null
    },
  }
}

export default useColumnDateFilter
