import React from "react"
import { Affix, Button, Card, Col, Form, Input, Row, Select } from "antd"
import { FORM_TYPE_ADD } from "../../enums/FormTypes"
import FormFileUploader from "../customFormItems/FormFileUploader"
import FormQuill from "../customFormItems/FormQuill"
import { ARCHIVED, DRAFT, PUBLISHED } from "../../enums/RecordStates"
import useSeoFormItems from "../../hooks/useSeoFormItems"

const AuthorForm = ({ onFinish, initialValues, formType }) => {
  const [form] = Form.useForm()
  const seoItems = useSeoFormItems({ form, itemId: initialValues?.id })
  return (
    <Form
      scrollToFirstError
      initialValues={initialValues}
      onFinish={onFinish}
      layout={"vertical"}
      form={form}
    >
      <Row gutter={16}>
        <Col span={16}>
          <Card title="Autore">
            <Form.Item label="Stato" name="state" rules={[{ required: true }]}>
              <Select>
                <Select.Option value={DRAFT}>Bozza</Select.Option>
                <Select.Option value={PUBLISHED}>Pubblicato</Select.Option>
                <Select.Option value={ARCHIVED}>Archiviato</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label="Foto" name="picture_id">
              <FormFileUploader accept={"image/*"} />
            </Form.Item>

            <Form.Item
              label="Nome"
              name="firstname"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Cognome"
              name="lastname"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Titolo" name="title">
              <Input />
            </Form.Item>

            <Form.Item label="Biografia breve" name="short_biography">
              <Input.TextArea row={4} />
            </Form.Item>

            <Form.Item label="Biografia" name="biography">
              <FormQuill />
            </Form.Item>

            <Form.Item label="Wallpaper" name="wallpaper_id">
              <FormFileUploader accept={"image/*"} />
            </Form.Item>
          </Card>
        </Col>
        <Col span={8}>
          <Affix offsetTop={16}>
            <Card title="SEO">{seoItems}</Card>
          </Affix>
        </Col>
      </Row>

      <br />

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {formType === FORM_TYPE_ADD ? "inserisci" : "aggiorna"}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AuthorForm
