import React from "react"
import { Alert, Button, Card, Col, Form, InputNumber, Row, Select } from "antd"
import { FORM_TYPE_ADD } from "../../enums/FormTypes"
import useSeoFormItems from "../../hooks/useSeoFormItems"
import AuthorSelector from "../customFormItems/AuthorSelector"
import CourseSelector from "../customFormItems/CourseSelector"
import { ON_DEMAND } from "../../enums/PricePolicies"
import { PERCENTAGE, VALUE } from "../../enums/ValueTypes"

const paydays = []
for (let i = 1; i <= 28; i++) {
  paydays.push(i)
}

const AuthorCourseCommissionSettingForm = ({
  onFinish,
  initialValues,
  formType,
}) => {
  const [form] = Form.useForm()
  const seoItems = useSeoFormItems({ form, itemId: initialValues?.id })

  return (
    <Form
      scrollToFirstError
      initialValues={initialValues}
      onFinish={onFinish}
      layout={"vertical"}
      form={form}
    >
      <Alert
        message="Attenzione"
        description="Una volta inseriti i valori, non possono essere cambiati!"
        type="warning"
        showIcon
      />
      <Row gutter={16}>
        <Col span={16}>
          <Card title="Provvigione autore">
            <Form.Item
              label="Autore"
              name="author_id"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <AuthorSelector multiple={false} />
            </Form.Item>

            <Form.Item
              label="Corso"
              name="course_id"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <CourseSelector
                multiple={false}
                dataTransformer={courses =>
                  courses.filter(course => course.price_policy === ON_DEMAND)
                }
              />
            </Form.Item>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item label="Tipo di provvigione" name="type">
                  <Select>
                    <Select.Option value={VALUE}>quota fissa</Select.Option>
                    <Select.Option value={PERCENTAGE}>
                      percentuale
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    const discountType = getFieldValue("type")

                    if (discountType === VALUE) {
                      return (
                        <Form.Item name="value" label={`Quota fissa`}>
                          <InputNumber
                            style={{ width: "100%" }}
                            min={0}
                            max={100}
                          />
                        </Form.Item>
                      )
                    }

                    if (discountType === PERCENTAGE) {
                      return (
                        <Form.Item name="value" label={`Percentuale`}>
                          <InputNumber
                            style={{ width: "100%" }}
                            min={0}
                            max={1}
                            step={0.1}
                            formatter={value =>
                              `${((value || 0) * 100).toFixed(0)}%`
                            }
                            parser={value =>
                              Number(value.replace("%", "")) / 100.0
                            }
                          />
                        </Form.Item>
                      )
                    }

                    return null
                  }}
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <br />
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {formType === FORM_TYPE_ADD ? "inserisci" : "aggiorna"}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default AuthorCourseCommissionSettingForm
