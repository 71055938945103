import React, { useEffect, useState } from "react"
import { gql } from "@apollo/client"
import DataTable from "../../../components/DataTable"
import { Link } from "gatsby"
import { Button, Space } from "antd"
import PageRoot from "../../../components/PageRoot"
import useColumnSearch from "../../../hooks/useColumnSearch"
import LoadingScreen from "../../../components/LoadingScreen"
import { formatDate } from "../../../utils"
import moment from "moment"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_WAREHOUSE,
} from "../../../enums/UserRoles"

const ALL_ONDEMAND_PRODUCTS_LIST_QUERY = gql`
  {
    user_purchases(
      order_by: { created_at: desc }
      where: { item_type: { _neq: SUBSCRIPTION } }
      distinct_on: created_at
    ) {
      id
      price
      description
      created_at
      user {
        user_id
        basic_profile {
          email
          firstname
          lastname
          phone_number
          user_id
        }
        email
        subscriptions {
          braintree_subscription_id
          state
        }
      }
    }
  }
`

const OndemandListPage = () => {
  const emailSearchColumnOptions = useColumnSearch({
    dataIndex: "user.basic_profile.email",
  })
  const productDescriptionSearchColumnOptions = useColumnSearch({
    dataIndex: "description",
  })
  const firstnameSearchColumnOptions = useColumnSearch(
    "user.basic_profile.firstname"
  )
  const lastnameSearchColumnOptions = useColumnSearch(
    "user.basic_profile.lastname"
  )
  const phoneNumberSearchColumnOptions = useColumnSearch(
    "user.basic_profile.phone_number"
  )

  const [refreshing, setRefreshing] = useState(false)

  useEffect(() => {
    if (refreshing) {
      setRefreshing(false)
    }
  }, [refreshing])

  if (refreshing) {
    return (
      <PageRoot>
        {" "}
        <LoadingScreen />{" "}
      </PageRoot>
    )
  }

  const columns = [
    {
      title: "Nome Prodotto",
      dataIndex: "description",
      ...productDescriptionSearchColumnOptions,
    },
    {
      title: "Prezzo",
      dataIndex: "price",
    },
    {
      title: "Acquistato il",
      render: record => {
        return formatDate(record.created_at)
      },
      sorter: (a, b) => {
        return moment(a.created_at) - moment(b.created_at)
      },
    },
    {
      title: "Email utente",
      ...emailSearchColumnOptions,
      render: record => {
        return record.user?.basic_profile?.email
      },
    },
    {
      title: "Nome",
      ...firstnameSearchColumnOptions,
      render: record => {
        return record.user?.basic_profile?.firstname
      },
    },
    {
      title: "Cognome",
      ...lastnameSearchColumnOptions,
      render: record => {
        return record.user?.basic_profile?.lastname
      },
    },
    {
      title: "Numero di telefono",
      ...phoneNumberSearchColumnOptions,
      render: record => {
        return record.user?.basic_profile?.phone_number
      },
    },
    {
      title: "Tipo utente",
      render: record => {
        return record.user?.subscriptions.length > 0 ? "Abbonato" : "Freemium"
      },
      filters: [
        {
          text: "Abbonato",
          value: "premium",
        },
        {
          text: "Freemium",
          value: "freemium",
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        return (
          (value === "premium" && record.user?.subscriptions.length > 0) ||
          (value === "freemium" && record.user?.subscriptions.length === 0)
        )
      },
    },
    {
      title: "Azioni",
      render: record => {
        return (
          <Space>
            <Button type="primary">
              <Link to={`/users/all/view?id=${btoa(record.user?.user_id)}`}>
                Dettaglio
              </Link>
            </Button>
          </Space>
        )
      },
    },
  ]

  return (
    <PageRoot
      authorizedRoles={[
        ROLE_ADMIN,
        ROLE_ACCOUNTING,
        ROLE_SALE,
        ROLE_WAREHOUSE,
        ROLE_PRODUCT_MANAGER,
      ]}
    >
      <DataTable
        dataKey={"user_purchases"}
        query={ALL_ONDEMAND_PRODUCTS_LIST_QUERY}
        columns={columns}
      />
    </PageRoot>
  )
}

export default OndemandListPage
