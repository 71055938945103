import { gql } from "@apollo/client"
import useColumnSearch from "../../../hooks/useColumnSearch"
import useColumnDateFilter from "../../../hooks/useColumnDateFilter"
import React, { useState } from "react"
import { Button, Drawer, Modal, Tag } from "antd"
import {
  BUNDLE,
  CERTIFICATION,
  COURSE,
  GIFT_CARD,
} from "../../../enums/ItemTypes"
import { SUBSCRIPTION } from "../../../enums/PricePolicies"
import moment from "moment"
import PageRoot from "../../../components/PageRoot"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_WAREHOUSE,
} from "../../../enums/UserRoles"
import DataTable from "../../../components/DataTable"
import UserViewPage from "../../users/all/view"
import CouponSummaryViewPage from "../../promo/coupons/view"

const ALL_FAILED_TRANSACTIONS_QUERY = gql`
  query failedTransactions($from: timestamptz) {
    user_purchases(
      order_by: { created_at: desc }
      where: { state: { _eq: FAILED }, created_at: { _gte: $from } }
      distinct_on: created_at
    ) {
      braintree_transaction_id
      created_at
      description
      note
      price
      final_price
      special_price
      coupon_code
      coupon_code_rel {
        criterion {
          id
          title
        }
      }
      state
      user_id
      item_type
      item_id
      id
      user {
        user_id
        basic_profile {
          firstname
          lastname
          email
          phone_number
          address
        }
        billing_profile {
          billing_address
          business_address
          created_at
          email
          firstname
          id
          lastname
          phone_number
          tax_code
          tax_name
          user_id
          vat_number
        }
      }
    }
  }
`

const FailedTransactionsPage = () => {
  const productSearchColumnOptions = useColumnSearch({
    dataIndex: "description",
  })

  const rangeFilter = useColumnDateFilter({ dataIndex: "created_at" })

  const [now, setNow] = useState(moment().add(-1, "month").toDate())

  const [userToView, setUserToView] = useState(null)
  const [couponToView, setCouponToView] = useState(null)

  const columns = [
    {
      title: "Tipologia",
      render: record => {
        return <Tag>{record.item_type}</Tag>
      },
      filters: [
        {
          text: "Corso",
          value: COURSE,
        },
        {
          text: "Abbonamento",
          value: SUBSCRIPTION,
        },
        {
          text: "Certificazione/diploma/master",
          value: CERTIFICATION,
        },
        {
          text: "Gift Card",
          value: GIFT_CARD,
        },
        {
          text: "Bundle",
          value: BUNDLE,
        },
      ],
      onFilter: (value, record) => record.item_type === value,
    },
    {
      title: "Data acquisto",
      ...rangeFilter,
      sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
    },
    {
      title: "Articolo",
      dataIndex: "description",
      ...productSearchColumnOptions,
    },
    {
      title: "Errore",
      render: record => (
        <Button
          onClick={() => {
            Modal.info({
              title: "Errore transazione",
              content: record.note,
            })
          }}
        >
          mostra errore
        </Button>
      ),
    },
    {
      title: "Prezzo originale",
      dataIndex: "price",
    },
    {
      title: "Codice bonus",
      dataIndex: "coupon_code",
    },
    {
      title: "Bonus",
      filters: [
        {
          text: "Bonus applicato",
          value: true,
        },
      ],
      onFilter: (value, record) => !!record.coupon_code && value,
      render: item => {
        const { coupon_code, coupon_code_rel } = item

        if (!coupon_code) {
          return null
        }

        return (
          <a
            onClick={() => {
              setCouponToView(coupon_code_rel?.criterion)
            }}
          >
            {coupon_code_rel?.criterion?.title}
          </a>
        )
      },
    },
    {
      title: "Utente",
      render: record => {
        return (
          <a
            onClick={() => {
              setUserToView(record)
            }}
          >
            {`${record.user?.basic_profile?.firstname} ${record.user?.basic_profile?.lastname}`}
          </a>
        )
      },
    },
    {
      title: "Braintree",
      render: record => {
        return (
          <a
            target="_blank"
            href={`https://www.braintreegateway.com/merchants/${process.env.GATSBY_BRAINTREE_MERCHANT_ID}/transactions/${record.braintree_transaction_id}`}
          >
            {record.braintree_transaction_id}
          </a>
        )
      },
    },
  ]

  return (
    <PageRoot
      authorizedRoles={[
        ROLE_ADMIN,
        ROLE_ACCOUNTING,
        ROLE_SALE,
        ROLE_PRODUCT_MANAGER,
        ROLE_WAREHOUSE,
      ]}
    >
      <DataTable
        dataKey={"user_purchases"}
        rowKey={"id"}
        query={ALL_FAILED_TRANSACTIONS_QUERY}
        queryVariables={{ from: now }}
        columns={columns}
        fetchPolicy={"cache-first"}
        sticky
      />
      {userToView && (
        <Drawer
          width={"80%"}
          title={userToView.email}
          placement="right"
          onClose={() => {
            setUserToView(null)
          }}
          visible={true}
        >
          <UserViewPage
            noLayout
            location={{ search: `?id=${btoa(userToView.user_id)}` }}
          />
        </Drawer>
      )}

      {couponToView && (
        <Drawer
          width={"80%"}
          title={couponToView.title}
          placement="right"
          onClose={() => {
            setCouponToView(null)
          }}
          visible={true}
        >
          <CouponSummaryViewPage
            noLayout
            location={{ search: `?id=${couponToView.id}` }}
          />
        </Drawer>
      )}
    </PageRoot>
  )
}

export default FailedTransactionsPage
