const useFrontSiteUrls = () => {
  const sectionPathnameSet = {
    it_IT: {
      HOME_PAGE: ``,
      ON_DEMAND: `on-demand`,
      CATEGORIES: `tutte-le-categorie`,
      CERTIFICATIONS: `tutte-le-certificazioni`,
      CHANNELS: `tutti-i-canali`,
      AUTHORS: `tutti-gli-autori`,
      LIBRARY: `me/library`,
      WEBINAR: `webinar`,
      HUMAN_3D: `human`,
      MY_CERTIFICATIONS: `me/certification`,
      SPECIAL_PRICES: `special-price`,
      GIFT_CARDS: `gift-card`,
      BUNDLES: `bundle`,
    },
    en_US: {
      HOME_PAGE: ``,
      ON_DEMAND: `on-demand`,
      CATEGORIES: `all-categories`,
      CERTIFICATIONS: `all-certifications`,
      CHANNELS: `all-channels`,
      AUTHORS: `all-authors`,
      LIBRARY: `me/library`,
      WEBINAR: `webinar`,
      HUMAN_3D: `human`,
      MY_CERTIFICATIONS: `me/certification`,
      SPECIAL_PRICES: `special-price`,
      GIFT_CARDS: `gift-card`,
      BUNDLES: `bundle`,
    },
  }

  const getPathname = key => {
    const pathnameList = sectionPathnameSet[process.env.GATSBY_SITE_LANGUAGE]
    if (!pathnameList) {
      return key
    }

    return pathnameList[key] ?? key
  }

  const getItemUrl = item => {
    const parts = item.split(":")
    const itemId = parts.length === 2 ? parts[1] : parts[0]

    return `${process.env.GATSBY_FRONT_SITE}/go?id=${itemId}`
  }

  const getSectionUrl = section => {
    return `${process.env.GATSBY_FRONT_SITE}/${getPathname(section)}`
  }

  const getActionUrl = (action, actionValue) => {
    switch (action) {
      case "none":
        return null
      case "item":
        return getItemUrl(actionValue)
      case "section":
        return getSectionUrl(actionValue)
      case "webPage":
        return actionValue
      default:
        return null
    }
  }

  return {
    getItemUrl,
    getSectionUrl,
    getActionUrl,
  }
}

export default useFrontSiteUrls
