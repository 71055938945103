import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import CertificationForm from "../../../components/forms/CertificationForm"
import { FORM_TYPE_EDIT } from "../../../enums/FormTypes"
import QueryString from "query-string"
import { gql, useMutation, useQuery } from "@apollo/client"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import { Button, Result, Spin } from "antd"
import { Link, navigate } from "gatsby"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import { CERTIFICATION, COURSE } from "../../../enums/ItemTypes"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"

const GET_CERTIFICATION_QUERY = gql`
  query getCertification($id: uuid!) {
    certifications_by_pk(id: $id) {
      description
      id
      short_description
      state
      subtitle
      title
      exam_id
      seo {
        title
        description
        slug
        item_type
      }
      landscape_locked_id
      landscape_success_id
      price
      type
      wallpaper_id
      where_to_use
      proTips
      requirements {
        required_item_id
        required_item_type
      }
      badge_id
      badge_title
      trailer_id
      trailer_poster_id
      release_info
      extra_info
      authorities
      odoo_certificate_template_id
    }
  }
`

const UPDATE_CERTIFICATION_MUTATION = gql`
  mutation updateCertification(
    $id: uuid!
    $data: certifications_set_input!
    $seo: seo_insert_input!
    $requirements: [certification_requirements_insert_input!]!
  ) {
    update_certifications_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
    insert_seo_one(
      object: $seo
      on_conflict: {
        constraint: seo_item_id_key
        update_columns: [title, slug, description]
      }
    ) {
      item_id
    }
    delete_certification_requirements(
      where: { certification_id: { _eq: $id } }
    ) {
      affected_rows
    }
    insert_certification_requirements(objects: $requirements) {
      affected_rows
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const EditCertificationPage = ({ location }) => {
  const [updating, setUpdating] = useState(false)

  const params = QueryString.parse(location.search)

  const { data, error, loading } = useQuery(GET_CERTIFICATION_QUERY, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  })

  const [updateCertification] = useMutation(UPDATE_CERTIFICATION_MUTATION)

  const handleFormSubmit = async values => {
    setUpdating(true)
    let newValues = { ...values }
    delete newValues.seo
    delete newValues.required_certifications
    delete newValues.required_courses
    delete newValues.required_videos

    try {
      const seo = values.seo
      seo.item_id = params.id

      if (values.required_certifications?.includes(params.id)) {
        throw new Error(
          "Non puoi assegnare propria certificazione come requisito."
        )
      }

      let requirements =
        values.required_certifications?.map(item => ({
          certification_id: params.id,
          required_item_id: item,
          required_item_type: CERTIFICATION,
        })) ?? []

      requirements = requirements.concat(
        values.required_courses?.map(item => ({
          certification_id: params.id,
          required_item_id: item,
          required_item_type: COURSE,
        })) ?? []
      )

      await updateCertification({
        variables: { data: newValues, id: params.id, seo, requirements },
      })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/contents/certifications")
        },
      })
    } catch (error) {
      uiHandleError({ error })
    }
    setUpdating(false)
  }

  if (loading) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <LoadingScreen />
      </PageRoot>
    )
  }

  if (error) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  const certification = data.certifications_by_pk

  if (!certification) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <Result
          status="404"
          title="404"
          subTitle="Elemento non trovato!"
          extra={
            <Button type="primary">
              <Link to={"/contents/certifications"}>Torna indietro</Link>
            </Button>
          }
        />
      </PageRoot>
    )
  }

  const initialValues = {
    ...certification,
    required_certifications: certification.requirements
      .filter(item => item.required_item_type === CERTIFICATION)
      .map(item => item.required_item_id),
    required_courses: certification.requirements
      .filter(item => item.required_item_type === COURSE)
      .map(item => item.required_item_id),
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={updating}>
        <CertificationForm
          initialValues={initialValues}
          onFinish={handleFormSubmit}
          formType={FORM_TYPE_EDIT}
        />
      </Spin>
    </PageRoot>
  )
}

export default EditCertificationPage
