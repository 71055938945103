import React, { useState } from "react"
import { Avatar, Button, Col, Drawer, Row, Skeleton, Statistic } from "antd"
import { ReadOutlined } from "@ant-design/icons"
import CouponCodesViewer from "./CouponCodesViewer"

const CouponCriterionRecap = ({ data, loading }) => {
  const [showCodesDrawer, setShowCodesDrawer] = useState(false)

  if (loading) {
    return <Skeleton active round />
  }

  const {
    id,
    picture,
    title,
    subtitle,
    short_description,
    description,
    total_codes,
    consumed_codes,
    available_codes,
  } = data

  return (
    <div>
      <Row gutter={16}>
        <Col flex="auto">
          <Row gutter={[16, 16]}>
            <Col>
              <Avatar
                size={64}
                src={`/.netlify/functions/get-download-url?key=${picture?.s3_key}`}
              />
            </Col>
            <Col>
              <h4>{subtitle}</h4>
              <h2>{title}</h2>
            </Col>
            <Col span={24}>
              <p>{short_description}</p>
            </Col>
          </Row>
        </Col>

        <Col flex="auto">
          <Row>
            <Col span={8}>
              <Statistic title="Totali" value={total_codes.aggregate.count} />
            </Col>
            <Col span={8}>
              <Statistic
                title="Utilizzati"
                value={consumed_codes.aggregate.count}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title="Disponibili"
                value={available_codes.aggregate.count}
                suffix={
                  <Button
                    onClick={() => {
                      setShowCodesDrawer(true)
                    }}
                    size="small"
                    shape="circle"
                    icon={<ReadOutlined />}
                  />
                }
              />

              <Drawer
                title="Lista codice"
                width="90%"
                visible={showCodesDrawer}
                onOk={() => {
                  setShowCodesDrawer(false)
                }}
                onClose={() => {
                  setShowCodesDrawer(false)
                }}
              >
                {showCodesDrawer && <CouponCodesViewer couponId={id} />}
              </Drawer>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default CouponCriterionRecap
