import React from "react"
import {
  Affix,
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
} from "antd"
import { FORM_TYPE_ADD } from "../../enums/FormTypes"
import { ARCHIVED, DRAFT, PUBLISHED } from "../../enums/RecordStates"
import FormQuill from "../customFormItems/FormQuill"
import useSeoFormItems from "../../hooks/useSeoFormItems"
import CouponCriterionSelector from "../customFormItems/CouponCriterionSelector"
import FormFileUploader from "../customFormItems/FormFileUploader"

const GiftCardForm = ({ onFinish, initialValues, formType }) => {
  const [form] = Form.useForm()
  const seoItems = useSeoFormItems({ form, itemId: initialValues?.id })

  return (
    <Form
      scrollToFirstError
      form={form}
      initialValues={initialValues}
      onFinish={onFinish}
      layout={"vertical"}
    >
      <Row gutter={16}>
        <Col span={16}>
          <Card title="Gift Card">
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item
                  label="Stato"
                  name="state"
                  rules={[{ required: true }]}
                >
                  <Select>
                    <Select.Option value={DRAFT}>Bozza</Select.Option>
                    <Select.Option value={PUBLISHED}>Pubblicato</Select.Option>
                    <Select.Option value={ARCHIVED}>Archiviato</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Prezzo in €"
                  name="price"
                  rules={[{ required: true }]}
                >
                  <InputNumber min={0} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Titolo"
                      name="title"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="Sottotitolo" name="subtitle">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Form.Item label="Descrizione breve" name="short_description">
                  <Input.TextArea rows={6} maxLength={230} showCount />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item label="Descrizione" name="description">
              <FormQuill />
            </Form.Item>

            <Form.Item label="Immagine" name="picture_id">
              <FormFileUploader accept={"image/*"} />
            </Form.Item>

            <Form.Item
              label="Criterio"
              name="criterion_id"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <CouponCriterionSelector />
            </Form.Item>
          </Card>
        </Col>

        <Col span={8}>
          <Affix offsetTop={16}>
            <Card title="SEO">{seoItems}</Card>
          </Affix>
        </Col>
      </Row>

      <br />

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {formType === FORM_TYPE_ADD ? "inserisci" : "aggiorna"}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default GiftCardForm
