export const HOME_PAGE = "HOME_PAGE"
export const ON_DEMAND = "ON_DEMAND"
export const CATEGORIES = "CATEGORIES"
export const CERTIFICATIONS = "CERTIFICATIONS"
export const CHANNELS = "CHANNELS"
export const AUTHORS = "AUTHORS"
export const LIBRARY = "LIBRARY"
export const WEBINAR = "WEBINAR"
export const HUMAN_3D = "HUMAN_3D"
export const MY_CERTIFICATIONS = "MY_CERTIFICATIONS"
export const SPECIAL_PRICES = "SPECIAL_PRICES"
export const GIFT_CARDS = "GIFT_CARDS"
export const BUNDLES = "BUNDLES"
