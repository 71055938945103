import React from "react"
import { gql, useQuery } from "@apollo/client"
import ErrorScreen from "../ErrorScreen"
import { Select, Spin } from "antd"

const ALL_ITEM_LIST_QUERY = gql`
  {
    courses(where: { state: { _eq: PUBLISHED } }) {
      __typename
      id
      title
      subtitle
      price_policy
    }
  }
`

const ALL_ONDEMAND_COURSES_QUERY = gql`
  {
    courses(
      where: { state: { _eq: PUBLISHED }, price_policy: { _eq: ON_DEMAND } }
    ) {
      __typename
      id
      title
      subtitle
      price_policy
    }
  }
`

const ItemSelector = ({
  value,
  onChange,
  multiple = false,
  dataTransformer,
  onDemandOnly,
}) => {
  const { loading, error, data } = useQuery(
    onDemandOnly ? ALL_ONDEMAND_COURSES_QUERY : ALL_ITEM_LIST_QUERY
  )

  if (loading) {
    return <Spin spinning />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const { courses } = data

  const items = dataTransformer ? dataTransformer([...courses]) : [...courses]

  const onItemChange = newValue => {
    onChange(newValue)
  }

  const defaultValue = multiple ? [] : null

  return (
    <>
      <Select
        defaultValue={value || defaultValue}
        style={{
          width: "100%",
        }}
        mode={multiple ? "multiple" : null}
        showSearch
        optionFilterProp="children"
        onChange={onItemChange}
      >
        {!multiple && (
          <Select.Option value={null} key={"null"}>
            nessuno
          </Select.Option>
        )}
        {items.map(({ id, title }) => (
          <Select.Option value={id} key={id}>
            {title}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

export default ItemSelector
