import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import ProductForm from "../../../components/forms/ProductForm"
import { gql, useMutation } from "@apollo/client"
import { FORM_TYPE_ADD } from "../../../enums/FormTypes"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import { navigate } from "gatsby"
import { Spin } from "antd"
import { DRAFT } from "../../../enums/RecordStates"
import { FREE } from "../../../enums/PricePolicies"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"

const INSERT_PRODUCT_MUTATION = gql`
  mutation insertProduct($data: external_products_insert_input!) {
    insert_external_products_one(object: $data) {
      id
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const AddProductPage = () => {
  const [insertProduct] = useMutation(INSERT_PRODUCT_MUTATION)
  const [loading, setLoading] = useState(false)

  const onFinish = async data => {
    setLoading(true)
    try {
      let newValues = { ...data }

      await insertProduct({ variables: { data: newValues } })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/contents/products")
        },
      })
    } catch (error) {
      uiHandleError({
        error,
        action: () => {},
      })
    }
    setLoading(false)
  }

  const initialValues =
    process.env.NODE_ENV === "development"
      ? {
          state: DRAFT,
          price_policy: FREE,
          title: "titolo",
          subtitle: "sottotitolo",
          short_description: "descrizione breve",
          description: "descrizione completa",
          file_id: "1d67c838-ef12-4e6c-bd2c-c1941f26f815",
          duration: 5,
          videos: [],
        }
      : {
          state: DRAFT,
          price_policy: FREE,
          videos: [],
        }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={loading}>
        <ProductForm
          initialValues={initialValues}
          onFinish={onFinish}
          formType={FORM_TYPE_ADD}
        />
      </Spin>
    </PageRoot>
  )
}

export default AddProductPage
