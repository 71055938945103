import React, { useState } from "react"
import ErrorScreen from "../ErrorScreen"
import { Table } from "antd"
import { gql, useQuery } from "@apollo/client"
import useColumnSearch from "../../hooks/useColumnSearch"
import LoadingScreen from "../LoadingScreen"
import useColumnDateFilter from "../../hooks/useColumnDateFilter"
import moment from "moment"

const AVAILABLE_CODES = gql`
  query couponCodes($criterionId: uuid, $limit: Int = 10, $available: Boolean) {
    coupon_codes(
      where: {
        criterion_id: { _eq: $criterionId }
        user_id: { _is_null: $available }
      }
      limit: $limit
      order_by: { consumed_at: desc_nulls_first, user_id: asc_nulls_first }
    ) {
      id
      code
      user_id
      consumed_at
      created_at
      user {
        basic_profile {
          firstname
          lastname
        }
        email
      }
    }
  }
`

const getDisplayName = user => {
  if (!user) {
    return null
  }
  let result = ""
  if (user.basic_profile?.firstname && user.basic_profile?.lastname) {
    result = `${user.basic_profile?.firstname} ${user.basic_profile?.lastname}`
  }

  return result
}

const mapData = data => {
  return data.map(({ code, user_id, consumed_at, created_at, user }) => {
    return {
      code,
      userEmail: user?.email,
      userDisplayName: getDisplayName(user),
      consumedAt: consumed_at,
      createdAt: created_at,
    }
  })
}

const CouponCodesViewer = ({ couponId, onFinish, onError }) => {
  const [limit, setLimit] = useState(3000)
  const codeSearch = useColumnSearch({
    dataIndex: "code",
  })
  const emailSearch = useColumnSearch({
    dataIndex: "userEmail",
  })
  const userSearch = useColumnSearch({
    dataIndex: "userDisplayName",
  })

  const createdAtFilter = useColumnDateFilter({ dataIndex: "createdAt" })
  const consumedAtFilter = useColumnDateFilter({ dataIndex: "consumedAt" })

  const { data, loading, error } = useQuery(AVAILABLE_CODES, {
    fetchPolicy: "network-only",
    variables: {
      criterionId: couponId,
      limit,
    },
  })

  const handleLoadMore = () => {
    setLimit(limit => limit + 10)
  }

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const mappedData = mapData(data.coupon_codes)

  const columns = [
    {
      title: "Codice",
      dataIndex: "code",
      ...codeSearch,
    },
    {
      title: "Utente",
      dataIndex: "userDisplayName",
      ...userSearch,
    },
    {
      title: "Email",
      dataIndex: "userEmail",
      ...emailSearch,
    },
    {
      title: "Data generazione",
      ...createdAtFilter,
      sorter: (a, b) => moment(a.createdAt) - moment(b.createdAt),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Data utilizzo",
      ...consumedAtFilter,
      sorter: (a, b) => {
        if (a.consumedAt && b.consumedAt) {
          return moment(a.consumedAt) - moment(b.consumedAt)
        }

        if (!a.consumedAt && b.consumedAt) {
          return -1
        }

        if (a.consumedAt && !b.consumedAt) {
          return 1
        }

        return 0
      },
      sortDirections: ["descend", "ascend"],
    },
  ]

  return (
    <Table
      dataSource={mappedData}
      columns={columns}
      pagination={{ pageSize: 20, position: ["topRight", "bottomRight"] }}
    />
  )
}

export default CouponCodesViewer
