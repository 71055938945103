import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import AuthorForm from "../../../components/forms/AuthorForm"
import { FORM_TYPE_EDIT } from "../../../enums/FormTypes"
import QueryString from "query-string"
import { gql, useMutation, useQuery } from "@apollo/client"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import { Button, Result, Spin } from "antd"
import { Link, navigate } from "gatsby"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"

const GET_AUTHOR_QUERY = gql`
  query getAuthor($id: uuid!) {
    authors_by_pk(id: $id) {
      id
      state
      firstname
      lastname
      title
      picture_id
      biography
      wallpaper_id
      short_biography
      seo {
        title
        description
        slug
        item_type
      }
    }
  }
`

const UPDATE_AUTHOR_MUTATION = gql`
  mutation updateAuthor(
    $id: uuid!
    $data: authors_set_input!
    $seo: seo_insert_input!
  ) {
    update_authors_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
    insert_seo_one(
      object: $seo
      on_conflict: {
        constraint: seo_item_id_key
        update_columns: [title, slug, description]
      }
    ) {
      item_id
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const EditAuthorPage = ({ location }) => {
  const [updating, setUpdating] = useState(false)

  const params = QueryString.parse(location.search)

  const { data, error, loading } = useQuery(GET_AUTHOR_QUERY, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  })

  const [updateAuthor] = useMutation(UPDATE_AUTHOR_MUTATION)

  const handleFormSubmit = async values => {
    setUpdating(true)
    try {
      const seo = values.seo
      seo.item_id = params.id
      delete values.seo
      await updateAuthor({ variables: { data: values, id: params.id, seo } })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/contents/authors")
        },
      })
    } catch (error) {
      uiHandleError({ error })
    }
    setUpdating(false)
  }

  if (loading) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <LoadingScreen />
      </PageRoot>
    )
  }

  if (error) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  const author = data.authors_by_pk

  if (!author) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <Result
          status="404"
          title="404"
          subTitle="Elemento non trovato!"
          extra={
            <Button type="primary">
              <Link to={"/contents/authors"}>Torna indietro</Link>
            </Button>
          }
        />
      </PageRoot>
    )
  }

  const initialValues = {
    ...author,
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={updating}>
        <AuthorForm
          initialValues={initialValues}
          onFinish={handleFormSubmit}
          formType={FORM_TYPE_EDIT}
        />
      </Spin>
    </PageRoot>
  )
}

export default EditAuthorPage
