import React, { useEffect, useState } from "react"
import { gql } from "@apollo/client"
import DataTable from "../../../components/DataTable"
import { Button, Col, Drawer, Row, Table, Tag, Tooltip } from "antd"
import PageRoot from "../../../components/PageRoot"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_WAREHOUSE,
} from "../../../enums/UserRoles"
import {
  BUNDLE,
  CERTIFICATION,
  COURSE,
  GIFT_CARD,
} from "../../../enums/ItemTypes"
import { SUBSCRIPTION } from "../../../enums/PricePolicies"
import moment from "moment"
import useColumnSearch from "../../../hooks/useColumnSearch"
import { useAuth0 } from "@auth0/auth0-react"
import useColumnDateFilter from "../../../hooks/useColumnDateFilter"
import { downloadCsvFile, formatPrice, uiHandleError } from "../../../utils"
import { QuestionCircleOutlined } from "@ant-design/icons"
import _ from "lodash"
import useCommonQueries from "../../../hooks/useCommonQueries"
import UserViewPage from "../../users/all/view"
import CouponSummaryViewPage from "../../promo/coupons/view"

const ALL_USER_PURCHASES_QUERY = gql`
  {
    user_purchases(
      order_by: { created_at: desc }
      where: { state: { _eq: COMPLETED } }
      distinct_on: created_at
    ) {
      braintree_transaction_id
      created_at
      description
      price
      final_price
      special_price
      coupon_code
      coupon_code_rel {
        criterion {
          id
          title
        }
      }
      state
      user_id
      item_type
      item_id
      id
      user {
        user_id
        basic_profile {
          firstname
          lastname
          email
          phone_number
          address
        }
        billing_profile {
          billing_address
          business_address
          created_at
          email
          firstname
          id
          lastname
          phone_number
          tax_code
          tax_name
          user_id
          vat_number
        }
      }
    }
  }
`

const downloadTransactions = data => {
  const result = [
    [
      "Stato",
      "Data di acquisto",
      "Tipologia",
      "Articolo",
      "Prezzo originale",
      "Prezzo finale",
      "Bonus applicato",
      "Bonus",
      "Codice bonus",
      "Nome",
      "Cognome",
      "Codice fiscale",
      "Email",
      "Telefono",
      "Citta",
      "CAP",
      "Indirizzo",
      "Ragione sociale",
      "P.IVA",
      "Citta di fatturazione",
      "CAP di fatturazione",
      "Indirizzo di fatturazione",
    ],
  ]

  data.map(purchase => {
    const {
      created_at,
      description,
      price,
      final_price,
      special_price,
      coupon_code,
      coupon_code_rel,
      state,
      user_id,
      item_type,
      item_id,
      id,
      user,
    } = purchase

    const { basic_profile, billing_profile } = user

    const mergedProfile = { ...basic_profile, ...billing_profile }

    const {
      email,
      firstname,
      lastname,
      phone_number,
      tax_code,
      tax_name,
      vat_number,
      address,
      billing_address,
      business_address,
    } = mergedProfile

    const primaryAddress = billing_address || address

    result.push([
      state,
      moment(created_at).format("L LT"),
      item_type,
      description,
      price,
      final_price ?? price,
      !!coupon_code ? "sì" : "no",
      coupon_code,
      _.get(coupon_code_rel, "criterion.title"),
      firstname,
      lastname,
      tax_code,
      email,
      phone_number,
      primaryAddress ? primaryAddress.city : "",
      primaryAddress ? primaryAddress.postal_code : "",
      primaryAddress ? primaryAddress.address_line1 : "",
      tax_name,
      vat_number,
      business_address ? business_address.city : "",
      business_address ? business_address.postal_code : "",
      business_address ? business_address.address_line1 : "",
    ])
  })

  downloadCsvFile(result, `lista_transazioni_${new Date().getTime()}.csv`)
}

const UserPurchasesPage = () => {
  const { getCurrentUser } = useCommonQueries()

  const [showSummary, setShowSummary] = useState(false)

  const productSearchColumnOptions = useColumnSearch({
    dataIndex: "description",
  })
  const [downloading, setDownloading] = useState(false)
  const [downloadRange, setDownloadRange] = useState([
    moment().add(7, "day"),
    moment(),
  ])

  const [userToView, setUserToView] = useState(null)
  const [couponToView, setCouponToView] = useState(null)

  const rangeFilter = useColumnDateFilter({ dataIndex: "created_at" })
  const [filteredData, setFilteredData] = useState([])

  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await getCurrentUser()
      if ([ROLE_ADMIN, ROLE_ACCOUNTING].indexOf(currentUser.role) >= 0) {
        setShowSummary(true)
      }
    }
    fetchUser()
  }, [])

  const columns = [
    {
      title: "Tipologia",
      render: record => {
        return <Tag>{record.item_type}</Tag>
      },
      filters: [
        {
          text: "Corso",
          value: COURSE,
        },
        {
          text: "Abbonamento",
          value: SUBSCRIPTION,
        },
        {
          text: "Certificazione/diploma/master",
          value: CERTIFICATION,
        },
        {
          text: "Gift Card",
          value: GIFT_CARD,
        },
        {
          text: "Bundle",
          value: BUNDLE,
        },
      ],
      onFilter: (value, record) => record.item_type === value,
    },
    {
      title: "Data acquisto",
      ...rangeFilter,
      sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
    },
    {
      title: "Articolo",
      dataIndex: "description",
      ...productSearchColumnOptions,
    },
    {
      title: "Prezzo originale",
      dataIndex: "price",
    },
    {
      title: "Prezzo finale",
      render: record => {
        return record.final_price ?? record.price
      },
    },
    {
      title: "Codice bonus",
      dataIndex: "coupon_code",
    },
    {
      title: "Bonus",
      filters: [
        {
          text: "Bonus applicato",
          value: true,
        },
      ],
      onFilter: (value, record) => !!record.coupon_code && value,
      render: item => {
        const { coupon_code, coupon_code_rel } = item

        if (!coupon_code) {
          return null
        }

        return (
          <a
            onClick={() => {
              setCouponToView(coupon_code_rel?.criterion)
            }}
          >
            {coupon_code_rel?.criterion?.title}
          </a>
        )
      },
    },
    {
      title: "Utente",
      render: record => {
        return (
          <a
            onClick={() => {
              setUserToView(record.user)
            }}
          >
            {`${record.user?.basic_profile?.firstname} ${record.user?.basic_profile?.lastname}`}
          </a>
        )
      },
    },
    {
      title: "Braintree",
      render: record => {
        return (
          <a
            target="_blank"
            href={`https://www.braintreegateway.com/merchants/${process.env.GATSBY_BRAINTREE_MERCHANT_ID}/transactions/${record.braintree_transaction_id}`}
          >
            {record.braintree_transaction_id}
          </a>
        )
      },
    },
  ]

  const handleDownloadAllTransactions = async () => {
    setDownloading(true)
    try {
      if (!downloadRange || !downloadRange[0] || !downloadRange[1]) {
        throw new Error("Selezionare il range del tempo")
      }

      const token = await getAccessTokenSilently()
      const config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          start_at: downloadRange[0].format(),
          end_at: downloadRange[1].format(),
        }),
      }
      const response = await fetch(
        "/.netlify/functions/download-transactions",
        config
      )
      response.blob().then(b => {
        const a = document.createElement("a")
        a.href = URL.createObjectURL(b)
        a.setAttribute("download", `Transazioni-${moment().format("L")}`)
        a.click()
        setDownloading(false)
      })
    } catch (err) {
      uiHandleError({ error: err })
      setDownloading(false)
    }
  }

  const handleChange = (pagination, filters, sorter, extra) => {
    setFilteredData(extra.currentDataSource)
  }

  const handleOnLoad = ({ loading, data, error, refetch }) => {
    if (!error) {
      setFilteredData(data.user_purchases)
    }
  }

  const summary = pageData => {
    if (!filteredData) {
      return null
    }

    let quantity = filteredData.length
    let totalOriginalPrice = 0
    let totalFinalPrice = 0
    let discountedPrice = 0
    let couponCount = 0

    filteredData.map(record => {
      const { price, final_price, coupon_code } = record
      totalOriginalPrice += price
      totalFinalPrice += final_price ?? price
      if (coupon_code) {
        couponCount++
      }
    })

    discountedPrice = totalOriginalPrice - totalFinalPrice

    return (
      <Table.Summary fixed="top">
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={1}>
            <b>
              Totale articoli{" "}
              <Tooltip title="Quantità totale">
                <QuestionCircleOutlined />
              </Tooltip>
            </b>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <b>{quantity}</b>
          </Table.Summary.Cell>

          <Table.Summary.Cell colSpan={1}>
            <b>
              Somma totale originale{" "}
              <Tooltip title="Somma teorica senza sconto applicato">
                <QuestionCircleOutlined />
              </Tooltip>
            </b>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <b>{formatPrice(totalOriginalPrice)}</b>
          </Table.Summary.Cell>
        </Table.Summary.Row>
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={1}>
            <b>
              Somma totale scontato{" "}
              <Tooltip title="Somma incassata">
                <QuestionCircleOutlined />
              </Tooltip>
            </b>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <b>{formatPrice(totalFinalPrice)}</b>
          </Table.Summary.Cell>

          <Table.Summary.Cell colSpan={1}>
            <b>Somma sconti</b>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <b>{formatPrice(discountedPrice)}</b>
          </Table.Summary.Cell>

          <Table.Summary.Cell colSpan={1}>
            <b>Bonus utilizzati</b>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <b>{couponCount}</b>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    )
  }

  return (
    <PageRoot
      authorizedRoles={[
        ROLE_ADMIN,
        ROLE_ACCOUNTING,
        ROLE_SALE,
        ROLE_WAREHOUSE,
        ROLE_PRODUCT_MANAGER,
      ]}
    >
      <Row gutter={[16, 16]}>
        <Col>
          <Button
            disabled={!filteredData?.length}
            loading={downloading}
            size="large"
            type="primary"
            onClick={() => {
              downloadTransactions(filteredData)
            }}
          >
            Scarica tutto
          </Button>
        </Col>
      </Row>
      <DataTable
        dataKey={"user_purchases"}
        rowKey={"id"}
        query={ALL_USER_PURCHASES_QUERY}
        columns={columns}
        fetchPolicy={"cache-first"}
        onLoad={handleOnLoad}
        onChange={handleChange}
        summary={showSummary ? summary : null}
        sticky
      />
      {userToView && (
        <Drawer
          width={"80%"}
          title={userToView.email}
          placement="right"
          onClose={() => {
            setUserToView(null)
          }}
          visible={true}
        >
          <UserViewPage
            noLayout
            location={{ search: `?id=${btoa(userToView.user_id)}` }}
          />
        </Drawer>
      )}
      {couponToView && (
        <Drawer
          width={"80%"}
          title={couponToView.title}
          placement="right"
          onClose={() => {
            setCouponToView(null)
          }}
          visible={true}
        >
          <CouponSummaryViewPage
            noLayout
            location={{ search: `?id=${couponToView.id}` }}
          />
        </Drawer>
      )}
    </PageRoot>
  )
}

export default UserPurchasesPage
