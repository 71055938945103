import PageRoot from "../../components/PageRoot"
import React from "react"
import { Button, Col, Form, Row } from "antd"
import UserSelector from "../../components/customFormItems/UserSelector"
import { ROLE_ADMIN } from "../../enums/UserRoles"
import { uiHandleError, uiHandleSuccess } from "../../utils"
import { useAuth0 } from "@auth0/auth0-react"

const authorizedRoles = [ROLE_ADMIN]
const TransferUserDataPage = () => {
  const { getAccessTokenSilently } = useAuth0()
  const handleSubmit = async ({ from, to }) => {
    try {
      if (from === to) {
        throw new Error("Impossibile trasferire stesso utente")
      }
      const token = await getAccessTokenSilently()

      const response = await fetch("/.netlify/functions/transfer-user-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ from, to }),
      })

      console.log({ response })

      if (response.errors) {
        throw response.errors[0]
      }

      if (response.status !== 200) {
        throw new Error(response.statusText)
      }

      uiHandleSuccess({ message: "Dati trasferiti", action: () => {} })
    } catch (error) {
      uiHandleError({ error })
    }
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Form onFinish={handleSubmit}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Da"
              name="from"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <UserSelector />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="A"
              name="to"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <UserSelector />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Trasferisce
          </Button>
        </Form.Item>
      </Form>
    </PageRoot>
  )
}

export default TransferUserDataPage
