import React, { useEffect, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import { Link } from "gatsby"
import PageRoot from "../../../components/PageRoot"
import { EyeOutlined } from "@ant-design/icons"
import {
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_WAREHOUSE,
} from "../../../enums/UserRoles"
import { formatDuration } from "../../../utils"
import useColumns from "../../../hooks/useColumns"
import moment from "moment"
import { DatePicker, Table } from "antd"
import ErrorScreen from "../../../components/ErrorScreen"

const { RangePicker } = DatePicker

const ALL_VIDEOS_QUERY = gql`
  query getVideoPlayback($startAt: timestamptz, $endAt: timestamptz) {
    videos(order_by: { title: asc }) {
      id
      title
    }
    user_video_play_ranges(
      where: { created_at: { _gte: $startAt, _lte: $endAt } }
    ) {
      video_id
      id
      duration
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_WAREHOUSE, ROLE_PRODUCT_MANAGER]
const now = moment()

const VideoListReportPage = () => {
  const columnOptions = useColumns()

  const [startAt, setStartAt] = useState(now.clone().add(-7, "day").toDate())
  const [endAt, setEndAt] = useState(now.clone().toDate())

  const [mappedData, setMappedData] = useState([])

  const { data, loading, error } = useQuery(ALL_VIDEOS_QUERY, {
    variables: {
      startAt,
      endAt,
    },
  })

  useEffect(() => {
    if (data && data.videos) {
      const videoDuration = {}

      data.user_video_play_ranges.map(({ video_id, duration }) => {
        videoDuration[video_id] = videoDuration[video_id] || 0
        videoDuration[video_id] += duration ?? 0
      })

      const result = data.videos.map(video => {
        return {
          ...video,
          played_ranges_aggregate: {
            aggregate: {
              sum: {
                duration: videoDuration[video.id] ?? 0,
              },
            },
          },
        }
      })

      console.log({ result })

      setMappedData(result)
    }
  }, [data])

  const columns = [
    { ...columnOptions.title },
    {
      title: "Riproduzione",
      sorter: (a, b) =>
        a.played_ranges_aggregate.aggregate.sum?.duration -
        b.played_ranges_aggregate.aggregate.sum?.duration,
      render: item => (
        <div style={{ textAlign: "right" }}>
          {formatDuration(item.played_ranges_aggregate.aggregate.sum.duration)}
        </div>
      ),
    },
    // {
    //   title: "Azioni",
    //   sorter: (a, b) =>
    //     a.user_actions_aggregate.aggregate.count -
    //     b.user_actions_aggregate.aggregate.count,
    //   render: item => (
    //     <div style={{ textAlign: "right" }}>
    //       {item.user_actions_aggregate.aggregate.count}
    //     </div>
    //   ),
    // },
    {
      title: "Azioni",
      render: item => (
        <Link to={`/reports/video/view?id=${item.id}`}>
          <EyeOutlined />
        </Link>
      ),
    },
  ]

  if (error) {
    return <ErrorScreen error={error} />
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <RangePicker
        size="large"
        disabledDate={current =>
          current &&
          current > now.clone() &&
          current < now.clone().add(-7, "day")
        }
        ranges={{
          "Ultimi 7 giorni": [moment().add(-7, "day"), moment()],
        }}
        allowClear={false}
        value={[moment(startAt), moment(endAt)]}
        onChange={value => {
          setStartAt(value[0].toDate())
          setEndAt(value[1].toDate())
        }}
      />
      <Table dataSource={mappedData} loading={loading} columns={columns} />
    </PageRoot>
  )
}

export default VideoListReportPage
