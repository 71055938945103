import React, { useState } from "react"
import { Button, Col, InputNumber, Row, Skeleton } from "antd"
import useCommonQueries from "../../hooks/useCommonQueries"

const CouponGenerator = ({ data, onFinish, onError }) => {
  const [loading, setLoading] = useState(false)
  const [couponCount, setCouponCount] = useState(0)
  const { generateCoupon } = useCommonQueries()

  if (loading) {
    return <Skeleton active round />
  }

  const { id } = data

  const handleGenerateButtonClick = async () => {
    setLoading(true)
    try {
      const result = await generateCoupon({ couponId: id, count: couponCount })
      onFinish && onFinish(result)
    } catch (error) {
      onError && onError(error)
    }

    setLoading(false)
  }

  const {} = data

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col flex="auto">
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            value={couponCount}
            onChange={val => {
              setCouponCount(Number(val))
            }}
          />
        </Col>

        <Col>
          <Button type="primary" onClick={handleGenerateButtonClick}>
            Genera
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default CouponGenerator
