import React from "react"
import PageRoot from "../../../components/PageRoot"
import { gql, useMutation, useQuery } from "@apollo/client"
import * as QueryString from "query-string"
import ErrorScreen from "../../../components/ErrorScreen"
import LoadingScreen from "../../../components/LoadingScreen"
import { Button, Card, Col, Empty, List, Popconfirm, Row, Space } from "antd"
import { formatDate, uiHandleError } from "../../../utils"
import AddItemToUserButton from "../../../components/AddItemToUserButton"
import { useGlobalValue } from "../../../hooks/useGlobalValue"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_TRAINER,
  ROLE_USER,
  ROLE_VIP,
  ROLE_WAREHOUSE,
} from "../../../enums/UserRoles"
import UserRoleSetter from "../../../components/UserRoleSetter"
import { PREFERENCE_TAGS } from "../../../enums/UserPreferenceKeys"
import TypeTag from "../../../components/TypeTag"

const GET_USER_DATA_QUERY = gql`
  query getUserData($user_id: String!) {
    auth0_users_by_pk(user_id: $user_id) {
      user_id
      email
      role
      last_login
      basic_profile {
        firstname
        lastname
        email
        newsletter
        phone_number
      }
      braintree_customer {
        customer_id
      }
      billing_profile {
        email
        firstname
        lastname
        phone_number
      }
      subscriptions(order_by: { end_at: desc }) {
        state
        start_at
        end_at
      }
      settings {
        key
        value
      }
    }
    user_purchases(
      where: { user_id: { _eq: $user_id } }
      order_by: { created_at: desc }
    ) {
      created_at
      description
      state
      item_type
      item_id
    }
    user_inventory_items(where: { user_id: { _eq: $user_id } }) {
      created_at
      item_type
      item_id
      video {
        __typename
        title
        subtitle
        landscape {
          s3_key
        }
      }
      course {
        __typename
        title
        subtitle
        landscape {
          s3_key
        }
      }
      certification {
        __typename
        id
        title
        subtitle
      }
    }
  }
`

const REMOVE_USER_INVETORY_ITEM_MUTAITON = gql`
  mutation removeUserInventoryItem($itemId: uuid!, $userId: String!) {
    delete_user_inventory_items_by_pk(item_id: $itemId, user_id: $userId) {
      item_id
    }
  }
`

const makeUserSettingsObject = dictionary => {
  const result = {}

  dictionary.map(({ key, value }) => {
    result[key] = JSON.parse(value || {})
  })

  return result
}

const UserViewPage = ({ location, noLayout = false }) => {
  const params = QueryString.parse(location.search)

  const [{ user }] = useGlobalValue()

  const customerId = atob(params.id)

  const { loading, error, data, refetch } = useQuery(GET_USER_DATA_QUERY, {
    variables: {
      user_id: customerId,
    },
    fetchPolicy: "network-only",
  })

  const [removeUserInventoryItem] = useMutation(
    REMOVE_USER_INVETORY_ITEM_MUTAITON
  )

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const {
    user_id,
    basic_profile,
    billing_profile,
    subscriptions,
    role,
    settings,
    braintree_customer,
  } = data.auth0_users_by_pk

  const { firstname, lastname, email, phone_number } = {
    ...basic_profile,
    ...billing_profile,
  }

  const subscription = subscriptions[0]

  const purchases = data.user_purchases

  const inventoryItems = data.user_inventory_items

  const userSettings = makeUserSettingsObject(settings)

  const pageContent = (
    <Row gutter={[16, 16]}>
      <Col span={7}>
        <Card title="Profilo utente" hoverable>
          <List itemLayout="horizontal">
            {[ROLE_ADMIN, ROLE_PRODUCT_MANAGER].includes(user?.role) && (
              <>
                <List.Item>
                  <List.Item.Meta title={"ID"} />
                  <div>
                    <a
                      href={`https://manage.auth0.com/dashboard/eu/sportscience/users/${window.btoa(user_id)}`}
                      target="_blank"
                    >
                      {user_id}
                    </a>
                  </div>
                </List.Item>
                <List.Item>
                  <List.Item.Meta title={"Braintree Customer"} />
                  {braintree_customer?.customer_id && (
                    <div>
                      <a
                        href={`https://www.braintreegateway.com/merchants/${process.env.GATSBY_BRAINTREE_MERCHANT_ID}/customers/${braintree_customer?.customer_id}`}
                        target="_blank"
                      >
                        {braintree_customer?.customer_id}
                      </a>
                    </div>
                  )}
                </List.Item>
              </>
            )}
            <List.Item>
              <List.Item.Meta title={"Nome"} />
              <div>{firstname}</div>
            </List.Item>
            <List.Item>
              <List.Item.Meta title={"Cognome"} />
              <div>{lastname}</div>
            </List.Item>
            <List.Item>
              <List.Item.Meta title={"Email"} />
              <div>{email}</div>
            </List.Item>
            <List.Item>
              <List.Item.Meta title={"Telefono"} />
              <div>{phone_number}</div>
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title="Preferenze"
                description={(userSettings[PREFERENCE_TAGS] || []).join(", ")}
              />
            </List.Item>
            {[ROLE_ADMIN, ROLE_PRODUCT_MANAGER].includes(user?.role) && (
              <List.Item>
                <List.Item.Meta title={"Ruolo"} />
                <div>
                  <UserRoleSetter
                    userId={user_id}
                    role={role}
                    presetRoles={
                      user?.role === ROLE_PRODUCT_MANAGER
                        ? [ROLE_USER, ROLE_VIP, ROLE_TRAINER]
                        : undefined
                    }
                  />
                </div>
              </List.Item>
            )}
          </List>
        </Card>
      </Col>
      <Col span={7}>
        <Card title="Abbonamento" hoverable>
          {subscription ? (
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta title={"Stato"} />
                <div>{subscription.state}</div>
              </List.Item>
              <List.Item>
                <List.Item.Meta title={"Data inizio"} />
                <div>{formatDate(subscription.start_at)}</div>
              </List.Item>
              <List.Item>
                <List.Item.Meta title={"Valido fino al"} />
                <div>{formatDate(subscription.end_at)}</div>
              </List.Item>
            </List>
          ) : (
            <Empty />
          )}
        </Card>
      </Col>
      <Col span={10}>
        <Card title="Storico acquisto" hoverable>
          <List
            dataSource={purchases}
            renderItem={({
              description,
              state,
              created_at,
              item_type,
              item_id,
            }) => (
              <List.Item>
                <List.Item.Meta
                  avatar={state}
                  title={item_type}
                  description={formatDate(created_at)}
                />
                <div>{description}</div>
              </List.Item>
            )}
          />
        </Card>
      </Col>
      <Col span={24}>
        <Card
          title={
            <Space>
              <span>Inventario utente</span>
              <AddItemToUserButton
                userId={customerId}
                onAdd={() => {
                  refetch()
                }}
              />
            </Space>
          }
        >
          {inventoryItems.length > 0 ? (
            <List
              dataSource={inventoryItems}
              renderItem={({
                created_at,
                item_type,
                item_id,
                video,
                course,
                certification,
              }) => {
                const item = course || video || certification
                if (!item) {
                  return null
                }

                const { title, subtitle, landscape } = item
                return (
                  <List.Item
                    actions={[
                      <Popconfirm
                        placement="topRight"
                        title={`Sei sicuro di voler togliere questo articolo a "${firstname} ${lastname}"`}
                        onConfirm={async () => {
                          try {
                            await removeUserInventoryItem({
                              variables: {
                                userId: customerId,
                                itemId: item_id,
                              },
                            })

                            await refetch()
                          } catch (error) {
                            uiHandleError({ error })
                          }
                        }}
                        okText="Confermo"
                        cancelText="Annullo"
                      >
                        <Button danger>Rimuovi</Button>
                      </Popconfirm>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={<TypeTag type={item.__typename} />}
                      title={title}
                      description={formatDate(created_at)}
                    />
                  </List.Item>
                )
              }}
            />
          ) : (
            <Empty />
          )}
        </Card>
      </Col>
    </Row>
  )

  return noLayout ? (
    pageContent
  ) : (
    <PageRoot
      authorizedRoles={[
        ROLE_ADMIN,
        ROLE_ACCOUNTING,
        ROLE_WAREHOUSE,
        ROLE_PRODUCT_MANAGER,
        ROLE_SALE,
      ]}
    >
      {pageContent}
    </PageRoot>
  )
}

export default UserViewPage
