import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import WebinarForm from "../../../components/forms/WebinarForm"
import { gql, useMutation } from "@apollo/client"
import { FORM_TYPE_ADD } from "../../../enums/FormTypes"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import { navigate } from "gatsby"
import { Spin } from "antd"
import { DRAFT } from "../../../enums/RecordStates"
import { FREE } from "../../../enums/PricePolicies"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"

const INSERT_WEBINAR_MUTATION = gql`
  mutation insertWebinar($data: webinars_insert_input!) {
    insert_webinars_one(object: $data) {
      id
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const AddWebinarPage = () => {
  const [insertWebinar] = useMutation(INSERT_WEBINAR_MUTATION)
  const [loading, setLoading] = useState(false)

  const onFinish = async data => {
    setLoading(true)
    try {
      let newValues = { ...data }
      delete newValues.author_ids
      newValues.author_rels = {
        data: data.author_ids.map(author_id => ({ author_id })),
      }

      await insertWebinar({ variables: { data: newValues } })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/contents/webinars")
        },
      })
    } catch (error) {
      uiHandleError({
        error,
        action: () => {},
      })
    }
    setLoading(false)
  }

  const initialValues =
    process.env.NODE_ENV === "development"
      ? {
          state: DRAFT,
          price_policy: FREE,
          title: "titolo",
          subtitle: "sottotitolo",
          short_description: "descrizione breve",
          description: "descrizione completa",
          landscape_id: "e6f2364b-cd77-40dd-9ff4-790097d53402",
          duration: 5,
          videos: [],
        }
      : {
          state: DRAFT,
        }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={loading}>
        <WebinarForm
          initialValues={initialValues}
          onFinish={onFinish}
          formType={FORM_TYPE_ADD}
        />
      </Spin>
    </PageRoot>
  )
}

export default AddWebinarPage
