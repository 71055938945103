import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import SpecialPriceForm from "../../../components/forms/SpecialPriceForm"
import { gql, useMutation } from "@apollo/client"
import { FORM_TYPE_ADD } from "../../../enums/FormTypes"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import { navigate } from "gatsby"
import { Spin } from "antd"
import { SPECIAL_PRICE } from "../../../enums/ItemTypes"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
} from "../../../enums/UserRoles"

const INSERT_SPECIAL_PRICE_MUTATION = gql`
  mutation insertSpecialPrice($data: special_prices_insert_input!) {
    insert_special_prices_one(object: $data) {
      item_id
    }
  }
`

const authorizedRoles = [
  ROLE_ADMIN,
  ROLE_ACCOUNTING,
  ROLE_SALE,
  ROLE_PRODUCT_MANAGER,
]

const AddSpecialPricePage = () => {
  const [insertSpecialPrice] = useMutation(INSERT_SPECIAL_PRICE_MUTATION)
  const [loading, setLoading] = useState(false)

  const onFinish = async values => {
    setLoading(true)
    try {
      await insertSpecialPrice({ variables: { data: { ...values } } })
      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/promo/special-prices")
        },
      })
    } catch (error) {
      uiHandleError({
        error,
        action: () => {},
      })
    }
    setLoading(false)
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={loading}>
        <SpecialPriceForm
          onFinish={onFinish}
          formType={FORM_TYPE_ADD}
          initialValues={{ seo: { item_type: SPECIAL_PRICE } }}
        />
      </Spin>
    </PageRoot>
  )
}

export default AddSpecialPricePage
