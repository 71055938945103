//TODO theming
export const defaultTheme = {
  topBarHeight: 40,
  mainBackground: '#1A1B1E',
  accentColor: '#FF0039',
  textColor: '#CDD5DF',
  borderRadius: 10,
  imageSizeList: {
    landscape: {
      small: {
        width: 192,
        height: 108,
      },
      medium: {
        width: 192 * 2,
        height: 108 * 2,
      },
      large: {
        width: 192 * 3,
        height: 108 * 3,
      },
    },
    portrait: {
      small: {
        height: 192,
        width: 108,
      },
      medium: {
        height: 192 * 2,
        width: 108 * 2,
      },
      large: {
        height: 192 * 3,
        width: 108 * 3,
      },
    },
  },
}
