import React, { useMemo, useState } from "react"
import PageRoot from "../../../components/PageRoot"
import VideoForm from "../../../components/forms/VideoForm"
import { FORM_TYPE_EDIT } from "../../../enums/FormTypes"
import QueryString from "query-string"
import { gql, useMutation, useQuery } from "@apollo/client"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import { Button, Result, Spin } from "antd"
import { Link, navigate } from "gatsby"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import { VIDEO } from "../../../enums/ItemTypes"
import moment from "moment"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"

const GET_VIDEO_QUERY = gql`
  query getVideo($id: uuid!) {
    videos_by_pk(id: $id) {
      author_rels {
        author_id
      }
      description
      duration
      file_id
      landscape_id
      id
      portrait_id
      available_date
      short_description
      state
      subtitle
      title
      level
      price_policy
      price
      wallpaper_id
      header_image_id
      show_faq
      faq
      faq_json
      show_learning_path
      learning_path
      attachments {
        file_id
      }
      category_rels {
        category_id
      }
      channel_rels {
        channel_id
      }
      product_rels {
        product_id
      }
      seo {
        title
        description
        slug
        item_type
      }
    }
  }
`

const UPDATE_VIDEO_MUTATION = gql`
  mutation updateVideo(
    $id: uuid!
    $data: videos_set_input!
    $seo: seo_insert_input!
  ) {
    update_videos_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
    insert_seo_one(
      object: $seo
      on_conflict: {
        constraint: seo_item_id_key
        update_columns: [title, slug, description]
      }
    ) {
      item_id
    }
  }
`

const DELETE_ALL_VIDEO_RELS_MUTATION = gql`
  mutation deleteVideoRels($id: uuid!) {
    delete_video_attachments(where: { video_id: { _eq: $id } }) {
      affected_rows
    }
    delete_video_author_rels(where: { video_id: { _eq: $id } }) {
      affected_rows
    }
    delete_item_category_rels(where: { item_id: { _eq: $id } }) {
      affected_rows
    }
    delete_item_channel_rels(where: { item_id: { _eq: $id } }) {
      affected_rows
    }
    delete_video_product_rels(where: { video_id: { _eq: $id } }) {
      affected_rows
    }
  }
`

const INSERT_ALL_VIDEO_RELS_MUTATION = gql`
  mutation insertVideoRels(
    $attachments: [video_attachments_insert_input!]!
    $categories: [item_category_rels_insert_input!]!
    $authors: [video_author_rels_insert_input!]!
    $channels: [item_channel_rels_insert_input!]!
    $products: [video_product_rels_insert_input!]!
  ) {
    insert_video_attachments(objects: $attachments) {
      affected_rows
    }
    insert_video_author_rels(objects: $authors) {
      affected_rows
    }
    insert_item_category_rels(objects: $categories) {
      affected_rows
    }
    insert_item_channel_rels(objects: $channels) {
      affected_rows
    }
    insert_video_product_rels(objects: $products) {
      affected_rows
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const EditVideoPage = ({ location, onUpdate, noLayout = false }) => {
  const [updating, setUpdating] = useState(false)

  const params = QueryString.parse(location.search)

  const { data, error, loading } = useQuery(GET_VIDEO_QUERY, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  })

  const [updateVideo] = useMutation(UPDATE_VIDEO_MUTATION)
  const [insertVideoRels] = useMutation(INSERT_ALL_VIDEO_RELS_MUTATION)
  const [deleteVideoRels] = useMutation(DELETE_ALL_VIDEO_RELS_MUTATION)

  const handleFormSubmit = async values => {
    setUpdating(true)
    let newValues = { ...values }
    delete newValues.author_ids
    delete newValues.category_ids
    delete newValues.channel_ids
    delete newValues.product_ids
    delete newValues.attachments
    delete newValues.seo

    const videoAuthorRels = values.author_ids
      ? values.author_ids.map(author_id => ({
          video_id: params.id,
          author_id,
        }))
      : []

    const videoCategoryRels = values.category_ids
      ? values.category_ids.map(category_id => ({
          item_id: params.id,
          category_id,
          item_type: VIDEO,
        }))
      : []

    const videoChannelRels = values.channel_ids
      ? values.channel_ids.map(channel_id => ({
          item_id: params.id,
          channel_id,
          item_type: VIDEO,
        }))
      : []

    const videoProductRels = values.product_ids
      ? values.product_ids.map(product_id => ({
          video_id: params.id,
          product_id,
        }))
      : []

    const attachmentsData = values.attachments
      ? values.attachments.map(file_id => ({
          video_id: params.id,
          file_id,
        }))
      : []

    try {
      const seo = values.seo
      seo.item_id = params.id

      await updateVideo({ variables: { data: newValues, id: params.id, seo } })
      await deleteVideoRels({ variables: { id: params.id } })
      await insertVideoRels({
        variables: {
          attachments: attachmentsData,
          authors: videoAuthorRels,
          channels: videoChannelRels,
          categories: videoCategoryRels,
          products: videoProductRels,
        },
      })

      if (onUpdate) {
        onUpdate()
      } else {
        uiHandleSuccess({
          message: "Operazione completata",
          action: () => {
            navigate("/contents/videos")
          },
        })
      }
    } catch (error) {
      uiHandleError({ error })
    }
    setUpdating(false)
  }

  const pageContent = useMemo(() => {
    if (loading) {
      return <LoadingScreen />
    }

    if (error) {
      return <ErrorScreen error={error} />
    }

    const video = data.videos_by_pk

    if (!video) {
      return (
        <Result
          status="404"
          title="404"
          subTitle="Elemento non trovato!"
          extra={
            <Button type="primary">
              <Link to={"/contents/videos"}>Torna indietro</Link>
            </Button>
          }
        />
      )
    }

    const initialValues = {
      ...video,
      author_ids: video.author_rels.map(({ author_id }) => author_id),
      category_ids: video.category_rels.map(({ category_id }) => category_id),
      channel_ids: video.channel_rels.map(({ channel_id }) => channel_id),
      attachments: video.attachments.map(({ file_id }) => file_id),
      product_ids: video.product_rels.map(({ product_id }) => product_id),
      available_date: video.available_date
        ? moment(video.available_date)
        : null,
    }

    return (
      <Spin spinning={updating}>
        <VideoForm
          initialValues={initialValues}
          onFinish={handleFormSubmit}
          formType={FORM_TYPE_EDIT}
        />
      </Spin>
    )
  }, [loading, error, data, updating])

  return noLayout ? (
    pageContent
  ) : (
    <PageRoot authorizedRoles={authorizedRoles}>{pageContent}</PageRoot>
  )
}

export default EditVideoPage
