import fetch from "cross-fetch"

const useOneSignal = () => {
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
    Authorization: `Basic ${process.env.GATSBY_ONESIGNAL_API_KEY}`,
  }

  const getAllNotifications = async ({ limit = 10, offset = 0, kind }) => {
    let url = `${process.env.GATSBY_ONESIGNAL_API_ENDPOINT}/notifications?app_id=${process.env.GATSBY_ONESIGNAL_APP_ID}&limit=${limit}&offset=${offset}`
    if (kind >= 0) {
      url += `&kind=${kind}`
    }

    const response = await fetch(url, {
      method: "GET",
      headers,
    })

    const result = await response.json()
    return result
  }

  const getAppStatus = async () => {
    let url = `${process.env.GATSBY_ONESIGNAL_API_ENDPOINT}/apps/${process.env.GATSBY_ONESIGNAL_APP_ID}`

    const response = await fetch(url, {
      method: "GET",
      headers,
    })

    const result = await response.json()

    return result
  }

  const createNotification = async ({
    name,
    title,
    subtitle,
    message,
    isIos,
    isAndroid,
    isAnyWeb,
    ...otherOptions
  }) => {
    let url = `${process.env.GATSBY_ONESIGNAL_API_ENDPOINT}/notifications`

    const lang = (process.env.GATSBY_SITE_LANGUAGE || "it_IT").split("_")[0]

    const userSegment =
      process.env.GATSBY_ONESIGNAL_LANG_USER_SEGMENT || "Subscribed Users"

    const data = {
      app_id: process.env.GATSBY_ONESIGNAL_APP_ID,
      title: {
        en: title,
        [lang]: title,
      },
      subtitle: {
        en: subtitle,
        [lang]: subtitle,
      },
      contents: {
        en: message,
        [lang]: message,
      },
      included_segments: [
        process.env.NODE_ENV !== "production" ? "Test Users" : userSegment,
      ],
      name,
      isIos,
      isAndroid,
      isAnyWeb,
      ...otherOptions,
    }
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(data),
      headers,
    })

    const result = await response.json()

    const { errors } = result

    if (errors) {
      console.log({ errors })
      throw new Error(errors[0])
    }

    return result
  }

  return {
    getAllNotifications,
    createNotification,
    getAppStatus,
  }
}

export default useOneSignal
