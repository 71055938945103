import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import VideoForm from "../../../components/forms/VideoForm"
import { gql, useMutation } from "@apollo/client"
import { FORM_TYPE_ADD } from "../../../enums/FormTypes"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import { navigate } from "gatsby"
import { Spin } from "antd"
import { DRAFT } from "../../../enums/RecordStates"
import { FREE } from "../../../enums/PricePolicies"
import { VIDEO } from "../../../enums/ItemTypes"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"

const INSERT_VIDEO_MUTATION = gql`
  mutation insertSeo($data: seo_insert_input!) {
    insert_seo(objects: [$data]) {
      affected_rows
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const AddVideoPage = () => {
  const [insertVideo] = useMutation(INSERT_VIDEO_MUTATION)
  const [loading, setLoading] = useState(false)

  const onFinish = async values => {
    setLoading(true)
    try {
      let newValues = { ...values }
      delete newValues.author_ids
      delete newValues.category_ids
      delete newValues.channel_ids
      delete newValues.product_ids
      delete newValues.attachments
      delete newValues.seo

      const authorRelData = values.author_ids
        ? values.author_ids.map(authorId => ({
            author_id: authorId,
          }))
        : []

      const attachmentData = values.attachments
        ? values.attachments.map(file_id => ({
            file_id,
          }))
        : []

      const categoryData = values.category_ids
        ? values.category_ids.map(category_id => ({
            category_id,
            item_type: VIDEO,
          }))
        : []

      const channelData = values.channel_ids
        ? values.channel_ids.map(channel_id => ({
            channel_id,
            item_type: VIDEO,
          }))
        : []

      const productData = values.product_ids
        ? values.product_ids.map(product_id => ({
            product_id,
          }))
        : []

      newValues.author_rels = { data: authorRelData }
      newValues.attachments = { data: attachmentData }
      newValues.category_rels = { data: categoryData }
      newValues.channel_rels = { data: channelData }
      newValues.product_rels = { data: productData }

      const data = {
        ...values.seo,
        video: {
          data: {
            ...newValues,
          },
        },
      }

      await insertVideo({ variables: { data } })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/contents/videos")
        },
      })
    } catch (error) {
      uiHandleError({
        error,
        action: () => {},
      })
    }
    setLoading(false)
  }

  const initialValues =
    process.env.NODE_ENV === "development"
      ? {
          state: DRAFT,
          price_policy: FREE,
          title: "titolo",
          subtitle: "sottotitolo",
          short_description: "descrizione breve",
          description: "descrizione completa",
          file_id: "1d67c838-ef12-4e6c-bd2c-c1941f26f815",
          duration: 5,
          seo: {
            item_type: VIDEO,
          },
        }
      : {
          state: DRAFT,
          price_policy: FREE,
          seo: {
            item_type: VIDEO,
          },
        }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={loading}>
        <VideoForm
          initialValues={initialValues}
          onFinish={onFinish}
          formType={FORM_TYPE_ADD}
        />
      </Spin>
    </PageRoot>
  )
}

export default AddVideoPage
