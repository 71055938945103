import React, { useEffect, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import DataTable from "../../../components/DataTable"
import { Link } from "gatsby"
import { Affix, Button, Popconfirm, Space, Tag } from "antd"
import PageRoot from "../../../components/PageRoot"
import { PlusOutlined } from "@ant-design/icons"
import LoadingScreen from "../../../components/LoadingScreen"
import useColumns from "../../../hooks/useColumns"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_WAREHOUSE,
} from "../../../enums/UserRoles"

const authorizedRoles = [
  ROLE_ADMIN,
  ROLE_ACCOUNTING,
  ROLE_SALE,
  ROLE_WAREHOUSE,
  ROLE_PRODUCT_MANAGER,
]

const ALL_SPECIAL_PRICE_LIST_QUERY = gql`
  {
    special_prices(order_by: { created_at: desc }) {
      item_id
      created_at
      start_at
      end_at
      final_price
      title
      course {
        __typename
        title
      }
      video {
        __typename
        title
      }
      certification {
        __typename
        title
      }
      gift_card {
        __typename
        title
      }
    }
  }
`

const DELETE_CAETGORY_MUATION = gql`
  mutation deleteSpecialPrice($id: uuid!) {
    delete_special_prices_by_pk(item_id: $id) {
      item_id
    }
  }
`

const SpecialPriceListPage = () => {
  const columnOptions = useColumns()

  const [refreshing, setRefreshing] = useState(false)
  const [deleteSpecialPrice] = useMutation(DELETE_CAETGORY_MUATION)
  useEffect(() => {
    if (refreshing) {
      setRefreshing(false)
    }
  }, [refreshing])

  if (refreshing) {
    return (
      <PageRoot>
        <LoadingScreen />
      </PageRoot>
    )
  }

  const deleteRecord = async id => {
    try {
      await deleteSpecialPrice({ variables: { id } })
      setRefreshing(true)
    } catch (error) {}
  }

  const columns = [
    {
      ...columnOptions.title,
    },
    {
      title: "Articolo",
      render: ({ video, course, certification, gift_card }) => {
        const item = video || course || certification || gift_card

        return (
          <span>
            <Tag>{item.__typename}</Tag>
            {item.title}
          </span>
        )
      },
    },
    {
      ...columnOptions.startAt,
    },
    {
      ...columnOptions.endAt,
    },
    {
      title: "Prezzo finale",
      dataIndex: "final_price",
      key: "final_price",
    },
    {
      title: "Azioni",
      render: record => {
        return (
          <Space>
            {/*<Button><Link to={`/promo/special-prices/view?id=${record.id}`}>mostra</Link></Button>*/}
            <Button type="primary">
              <Link to={`/promo/special-prices/edit?id=${record.item_id}`}>
                modifica
              </Link>
            </Button>
            <Popconfirm
              placement="topRight"
              title={"Sei sicuro di voler eliminare questo record?"}
              onConfirm={() => {
                deleteRecord(record.item_id)
              }}
              okText="elimina"
              cancelText="annulla"
            >
              <Button type="danger">elimina</Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
        <Link to={`/promo/special-prices/add`}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            shape="circle"
            size="large"
          />
        </Link>
      </Affix>
      <DataTable
        rowKey={"item_id"}
        dataKey={"special_prices"}
        query={ALL_SPECIAL_PRICE_LIST_QUERY}
        columns={columns}
      />
    </PageRoot>
  )
}

export default SpecialPriceListPage
