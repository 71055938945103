import React from "react"
import { Button, Result } from "antd"

const ErrorScreen = ({ error }) => {
  console.log({ error })
  return (
    <Result
      status="500"
      title="500"
      subTitle={error.message}
      extra={
        <div>
          <Button
            type="primary"
            onClick={() => {
              window.history.back()
            }}
          >
            Torna indietro
          </Button>
          <br />
          <Button type="primary" href="/auth/sign-out" danger>
            Cambia utente
          </Button>
        </div>
      }
    />
  )
}

export default ErrorScreen
