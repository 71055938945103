import React from "react"
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
} from "antd"
import { FORM_TYPE_ADD } from "../../enums/FormTypes"
import useSeoFormItems from "../../hooks/useSeoFormItems"
import PurchasableItemSelector from "../customFormItems/PurchasableItemSelector"

const CategoryForm = ({ onFinish, initialValues, formType }) => {
  const [form] = Form.useForm()
  const seoItems = useSeoFormItems({ form, itemId: initialValues?.id })

  return (
    <Form
      scrollToFirstError
      initialValues={initialValues}
      onFinish={onFinish}
      layout={"vertical"}
      form={form}
    >
      <Row gutter={16}>
        <Col span={24}>
          <Card title="Promozione">
            <Form.Item
              label="Seleziona articolo"
              name="item_id"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <PurchasableItemSelector />
            </Form.Item>

            <Form.Item
              label="Titolo (questo campo non viene visualizzato nel sito.)"
              name="title"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item label="Data inizio" name="start_at">
                  <DatePicker showTime />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Data fine" name="end_at">
                  <DatePicker showTime />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label="Prezzo finale"
              name="final_price"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </Card>
        </Col>
      </Row>
      <br />
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {formType === FORM_TYPE_ADD ? "inserisci" : "aggiorna"}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CategoryForm
