import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import QueryString from "query-string"
import { gql, useMutation } from "@apollo/client"
import { Divider, InputNumber, Space, Tag } from "antd"
import { Link } from "gatsby"
import { uiHandleError } from "../../../utils"
import { COURSE, VIDEO } from "../../../enums/ItemTypes"
import DataTable from "../../../components/DataTable"
import AuthorInvestmentCard from "../../../components/AuthorInvestmentCard"
import { EditOutlined } from "@ant-design/icons"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_WAREHOUSE,
} from "../../../enums/UserRoles"

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const GET_AUTHOR_VIDEOS_LIST = gql`
  query getAuthor($id: uuid!) {
    authors_by_pk(id: $id) {
      id
      state
      firstname
      lastname
      title
      picture {
        s3_key
      }
      user_rel {
        user {
          user_id
          email
        }
      }
    }
    courses(
      where: {
        price_policy: { _eq: ON_DEMAND }
        videos: { video: { author_rels: { author_id: { _eq: $id } } } }
      }
    ) {
      id
      title
      price
      price_policy
      videos(where: { video: { author_rels: { author_id: { _eq: $id } } } }) {
        video_id
        video {
          id
          title
          price
          investment_cost
        }
      }
    }
    user_purchases(
      where: {
        course: {
          videos: { video: { author_rels: { author_id: { _eq: $id } } } }
        }
        state: { _eq: COMPLETED }
      }
    ) {
      course {
        id
        title
        videos(where: { video: { author_rels: { author_id: { _eq: $id } } } }) {
          video_id
          video {
            price
            investment_cost
          }
        }
      }
    }
  }
`

const UPDATE_INVESTMENT_PRICE_MUTATION = gql`
  mutation updateInvestmentPrice($id: uuid!, $investment_cost: numeric!) {
    update_videos_by_pk(
      pk_columns: { id: $id }
      _set: { investment_cost: $investment_cost }
    ) {
      id
    }
  }
`

const DetailAuthorPage = ({ location }) => {
  const [reloadingTable, setReloadingTable] = useState(false)
  const [updating, setUpdating] = useState(false)
  const [author, setAuthor] = useState()
  const [totalInvestment, setTotalInvestment] = useState(0)
  const [totalCourseCount, setTotalCourseCount] = useState(0)
  const [totalRecessed, setTotalRecessed] = useState(0)
  const [totalSalesCount, setTotalSalesCount] = useState(0)

  const params = QueryString.parse(location.search)

  const [updateInvestmentPrice] = useMutation(UPDATE_INVESTMENT_PRICE_MUTATION)

  const handleCostChange = async (investmentCost, videoId) => {
    setUpdating(true)
    try {
      await updateInvestmentPrice({
        variables: { investment_cost: investmentCost, id: videoId },
      })
    } catch (error) {
      uiHandleError({ error })
    }

    setUpdating(false)
  }

  const columns = [
    {
      key: "name",
      title: "nome",
      render: record => {
        return (
          <Space>
            <Tag>{record.type}</Tag>
            {record.pricePolicy && <Tag>{record.pricePolicy}</Tag>}
            <span>{record.name}</span>
            <Link
              to={`${
                record.type === COURSE
                  ? "/contents/courses"
                  : "/contents/videos"
              }/edit?id=${record.id}`}
              target="_blank"
            >
              <EditOutlined />
            </Link>
          </Space>
        )
      },
    },
    {
      key: "price",
      title: "Prezzo",
      dataIndex: "price",
    },
    {
      key: "authorCourseContribution",
      title: "Contributo per corso",
      dataIndex: "authorCourseContribution",
    },
    {
      key: "salesCount",
      title: "Numero di vendita",
      dataIndex: "salesCount",
    },
    {
      key: "recessed",
      title: "Vendita totale per corso",
      dataIndex: "recessed",
    },
    {
      title: "Costo di realizzazione",
      render: record => {
        if (record.type === COURSE) {
          return record.cost
        }

        return (
          <InputNumber
            min={0}
            defaultValue={record.cost}
            onBlur={evt => {
              const investmentCost = Number(evt.target.value)
              const videoId = record.videoId
              const previousInvestmentCost = record.cost

              if (investmentCost != previousInvestmentCost) {
                handleCostChange(
                  investmentCost,
                  videoId,
                  previousInvestmentCost
                )
              }
            }}
          />
        )
      },
    },
  ]

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <AuthorInvestmentCard
        author={author}
        totalCourseCount={totalCourseCount}
        totalInvestment={totalInvestment}
        totalRecessed={totalRecessed}
        totalSalesCount={totalSalesCount}
      />
      <Divider orientation="left" plain>
        Video autore
      </Divider>

      {!reloadingTable && (
        <DataTable
          updating={updating}
          columns={columns}
          query={GET_AUTHOR_VIDEOS_LIST}
          queryVariables={{ id: params.id }}
          dataTransformer={data => {
            const {
              courses,
              user_purchases: userPurchases,
              authors_by_pk: author,
            } = data

            setAuthor(author)

            let _totalCost = 0
            let _totalSalesCount = 0
            let _totalRecessed = 0

            const tableData = courses.map((course, index) => {
              const authorCourseContribution = course.videos.reduce(
                (accumulator, video_rel, index) => {
                  return accumulator + video_rel?.video?.price ?? 0
                },
                0
              )

              const courseSalesCount = userPurchases.filter(
                item => item.course?.id === course.id
              ).length

              _totalSalesCount += courseSalesCount

              const recessed = userPurchases.reduce(
                (accumulator, purchase, index) => {
                  if (purchase.course?.id === course.id) {
                    return accumulator + authorCourseContribution
                  } else {
                    return accumulator
                  }
                },
                0
              )

              _totalRecessed += recessed

              return {
                key: `course_${index}`,
                id: course.id,
                name: course.title,
                price: course.price,
                pricePolicy: course.price_policy,
                cost: course.videos.reduce((accumulator, video_rels, index) => {
                  return accumulator + video_rels?.video.investment_cost ?? 0
                }, 0),
                authorCourseContribution,
                recessed,
                salesCount: courseSalesCount,
                type: COURSE,
                children: course.videos.map((video_rel, index) => {
                  const video = video_rel?.video ?? {
                    title: "video inesistente",
                  }
                  _totalCost += video.investment_cost ?? 0
                  return {
                    key: `video_${index}`,
                    id: video.id,
                    name: video.title,
                    price: video.price,
                    copiesSold: null,
                    grossEarnings: null,
                    cost: video.investment_cost,
                    videoId: video_rel.video_id,
                    type: VIDEO,
                  }
                }),
              }
            })

            setTotalSalesCount(_totalSalesCount)
            setTotalInvestment(_totalCost)
            setTotalCourseCount(data.courses.length)
            setTotalRecessed(_totalRecessed)

            return tableData
          }}
        />
      )}
    </PageRoot>
  )
}

export default DetailAuthorPage
