import React, { useEffect, useState } from "react"
import { Button, Popconfirm, Space } from "antd"
import { uiHandleError } from "../utils"
import fetch from "cross-fetch"

const DeploySiteButton = () => {
  const statusBadge = process.env.GATSBY_NETLIFY_DEPLOY_STATUS_BADGE
  const [badgeUrl, setBadgeUrl] = useState(statusBadge)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setBadgeUrl(`${statusBadge}?t=${new Date().getTime()}`)
    }, 5000)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  const handleSiteBuild = async () => {
    try {
      setBadgeUrl(`${statusBadge}?t=${new Date().getTime()}`)

      const response = await fetch(process.env.GATSBY_NETLIFY_BUILD_HOOK, {
        method: "post",
      })
    } catch (error) {
      uiHandleError({ error })
    }
  }

  return (
    <Space>
      <img src={badgeUrl} alt="status" />
      <Popconfirm
        placement="bottomRight"
        title={
          "Sei sicuro di voler avviare il processo di compilazione del sito?"
        }
        onConfirm={handleSiteBuild}
        okText="Si"
        cancelText="No"
      >
        <Button type="primary">Pubblica le modifiche</Button>
      </Popconfirm>
    </Space>
  )
}

export default DeploySiteButton
