import React, { useEffect, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import DataTable from "../../../components/DataTable"
import { Link } from "gatsby"
import { Affix, Button, Popconfirm, Space } from "antd"
import PageRoot from "../../../components/PageRoot"
import { PlusOutlined } from "@ant-design/icons"
import useColumnSearch from "../../../hooks/useColumnSearch"
import LoadingScreen from "../../../components/LoadingScreen"
import useColumnDateFilter from "../../../hooks/useColumnDateFilter"
import useColumns from "../../../hooks/useColumns"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"

const ALL_VIDEO_LIST_QUERY = gql`
  {
    videos(order_by: { created_at: desc }, distinct_on: created_at) {
      created_at
      id
      state
      title
      subtitle
      short_description
      author_rels {
        author {
          firstname
          lastname
        }
      }
      course_rel {
        course {
          title
        }
      }
    }
  }
`

const DELETE_VIDEO_MUATION = gql`
  mutation deleteVideo($id: uuid!) {
    delete_videos_by_pk(id: $id) {
      id
    }
    delete_seo(where: { item_id: { _eq: $id } }) {
      affected_rows
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const VideoListPage = () => {
  const columnOptions = useColumns()

  const nameSearchColumnOptions = useColumnSearch({ dataIndex: "title" })
  const authorSearchColumnOptions = useColumnSearch({
    dataIndex: "author",
    transformer: record => {
      const authors = record.author_rels.map(({ author }) => {
        return author ? `${author.firstname} ${author.lastname}` : ""
      })
      return authors.join(",")
    },
  })
  const courseSearchColumnOptions = useColumnSearch({
    dataIndex: "course",
    transformer: record => {
      return record.course_rel?.course?.title ?? ""
    },
  })
  const rangeFilter = useColumnDateFilter({ dataIndex: "created_at" })

  const [refreshing, setRefreshing] = useState(false)
  const [deleteVideo] = useMutation(DELETE_VIDEO_MUATION)
  useEffect(() => {
    if (refreshing) {
      setRefreshing(false)
    }
  }, [refreshing])

  if (refreshing) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <LoadingScreen />
      </PageRoot>
    )
  }

  const deleteRecord = async id => {
    try {
      await deleteVideo({ variables: { id } })
      setRefreshing(true)
    } catch (error) {}
  }

  const columns = [
    {
      ...columnOptions.state,
    },
    {
      ...columnOptions.createdAt,
    },
    {
      ...columnOptions.title,
    },
    {
      ...columnOptions.subtitle,
    },
    {
      ...columnOptions.author,
    },
    {
      ...columnOptions.course,
    },
    {
      title: "Azioni",
      render: record => {
        return (
          <Space>
            {/*<Button><Link to={`/videos/view?id=${record.id}`}>mostra</Link></Button>*/}
            <Button type="primary">
              <Link to={`/contents/videos/edit?id=${record.id}`}>modifica</Link>
            </Button>
            <Popconfirm
              placement="topRight"
              title={"Sei sicuro di voler eliminare questo record?"}
              onConfirm={() => {
                deleteRecord(record.id)
              }}
              okText="elimina"
              cancelText="annulla"
            >
              <Button type="danger">elimina</Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
        <Link to={`/contents/videos/add`}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            shape="circle"
            size="large"
          />
        </Link>
      </Affix>
      <DataTable
        dataKey={"videos"}
        query={ALL_VIDEO_LIST_QUERY}
        columns={columns}
      />
    </PageRoot>
  )
}

export default VideoListPage
