import React, { useEffect, useState } from "react"
import PageRoot from "../../../components/PageRoot"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"
import useOneSignal from "../../../hooks/useOneSignal"
import {
  Affix,
  Button,
  Card,
  Col,
  Row,
  Skeleton,
  Statistic,
  Table,
  Tooltip,
} from "antd"
import { formatDate } from "../../../utils"
import { Link } from "gatsby"
import {
  EyeOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons"
import ErrorScreen from "../../../components/ErrorScreen"

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const OneSignalAppStatus = () => {
  const { getAppStatus } = useOneSignal()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    getAppStatus()
      .then(data => {
        setData(data)
        setLoading(false)
      })
      .catch(err => {
        setError(err)
        setLoading(false)
      })
  }, [])

  if (loading) {
    return <Skeleton active round />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  return (
    <Card title="Dispositivi">
      <Row gutter={16}>
        <Col span={12}>
          <Statistic
            title={
              <Tooltip
                placement="topLeft"
                title="Dispositivi che hanno aggiornato all'ultima versione del software, con la possibilità di ricevere le notifiche push."
              >
                <span>Dispositivi configurati </span>
                <InfoCircleOutlined />
              </Tooltip>
            }
            value={data.players}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title={
              <Tooltip
                placement="topLeft"
                title="Dispositivi che hanno abilitato la notifica push. Solo su questi dispositivi arrivano le notifiche push."
              >
                <span>Dispositivi attivi </span>
                <InfoCircleOutlined />
              </Tooltip>
            }
            value={data.messageable_players}
          />
        </Col>
      </Row>
    </Card>
  )
}

const NotificationListPage = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [data, setData] = useState(null)

  const [limit, setLimit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [kind, setKind] = useState(1)

  const [totalCount, setTotalCount] = useState(0)

  const { getAllNotifications } = useOneSignal()

  const loadData = async () => {
    setLoading(true)
    try {
      const { notifications, total_count } = await getAllNotifications({
        limit,
        offset,
        kind,
      })
      setData(notifications)
      setTotalCount(total_count)
    } catch (error) {
      setError(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    loadData()
  }, [limit, offset, kind])

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setOffset((pagination.current - 1) * limit)
  }

  const columns = [
    {
      title: "Stato",
      render: ({ completed_at }) =>
        completed_at ? "Inviato" : "Invio in corso...",
    },
    { title: "Nome interno", dataIndex: "name" },
    { title: "Data", render: item => formatDate(item.queued_at * 1000) },
    { title: "Numeri inviati", dataIndex: "successful" },
    {
      title: "% di click",
      render: ({ successful, converted }) =>
        `${((converted / successful) * 100).toFixed(0)}%`,
    },
    {
      title: "Azioni",
      render: ({ id }) => (
        <Button
          type="primary"
          href={`https://app.onesignal.com/apps/${process.env.GATSBY_ONESIGNAL_APP_ID}/notifications/${id}`}
          target="_blank"
        >
          <EyeOutlined />
        </Button>
      ),
    },
  ]

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <OneSignalAppStatus />
      <br />
      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        pagination={{
          pageSize: limit,
          total: totalCount,
          current: Math.floor(offset / limit) + 1,
        }}
      />
      <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
        <Link to={`/messaging/push-notification/add`}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            shape="circle"
            size="large"
          />
        </Link>
      </Affix>
    </PageRoot>
  )
}

export default NotificationListPage
