import React, {createContext, useContext, useReducer} from "react";

export const GlobalContext = createContext();

export const GlobalProvider = ({children, reducer, initialState}) => (
    <GlobalContext.Provider value={useReducer(reducer, initialState)}>{children}</GlobalContext.Provider>
);

export const useGlobalValue = () => useContext(GlobalContext);

