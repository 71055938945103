import React from "react"
import { gql, useQuery } from "@apollo/client"
import ErrorScreen from "../ErrorScreen"
import { Select, Spin } from "antd"
import { renderUserLabel } from "../../utils"

const ALL_USER_LIST_QUERY = gql`
  {
    auth0_users {
      user_id
      email
      basic_profile {
        firstname
        lastname
      }
    }
  }
`

const UserSelector = ({ value, onChange, multiple = false }) => {
  const { loading, error, data, refetch } = useQuery(ALL_USER_LIST_QUERY)

  if (loading) {
    return <Spin spinning />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const users = data.auth0_users

  const onUserChange = newValue => {
    onChange && onChange(newValue)
  }

  const defaultValue = multiple ? [] : null

  return (
    <>
      <Select
        defaultValue={value || defaultValue}
        style={{
          width: "100%",
        }}
        mode={multiple ? "multiple" : null}
        showSearch
        optionFilterProp="children"
        onChange={onUserChange}
      >
        {!multiple && (
          <Select.Option value={null} key={"null"}>
            nessuno
          </Select.Option>
        )}
        {users.map(({ user_id, email, basic_profile }) => (
          <Select.Option value={user_id} key={user_id}>
            {renderUserLabel({ user_id, email, basic_profile })}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

export default UserSelector
