import React from "react"
import it_IT from "antd/es/locale/it_IT"
import { ConfigProvider } from "antd"
import "../style/style.less"
import { GlobalProvider } from "../hooks/useGlobalValue"
import { initialState, reducer } from "../reducer"
import AuthHandler from "./AuthHandler"
import { Auth0Provider } from "@auth0/auth0-react"

import moment from "moment"
import "moment/locale/it"

moment.locale("it")

const RootWrapper = ({ element }) => {
  return (
    <ConfigProvider locale={it_IT}>
      <GlobalProvider initialState={initialState} reducer={reducer}>
        <Auth0Provider
          domain={process.env.GATSBY_AUTH0_DOMAIN}
          clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
          redirectUri={process.env.GATSBY_AUTH0_REDIRECT_URI}
          audience="hasura"
          cacheLocation="localstorage"
          useRefreshTokens={true}
        >
          <AuthHandler>{element}</AuthHandler>
        </Auth0Provider>
      </GlobalProvider>
    </ConfigProvider>
  )
}

export default RootWrapper
