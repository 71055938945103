import React from 'react'
import { Button, Form, Input, InputNumber } from 'antd'
import { FORM_TYPE_ADD } from '../../enums/FormTypes'
import FormFileUploader from '../customFormItems/FormFileUploader'

const ProductForm = ({ onFinish, initialValues, formType }) => {
  return (
    <Form initialValues={initialValues} onFinish={onFinish} layout={'vertical'}>
      <Form.Item
        label="Titolo"
        name="title"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="Sottotitolo"
        name="subtitle"
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="Descrizione breve"
        name="short_description"
      >
        <Input.TextArea row={4} maxLength={230} showCount/>
      </Form.Item>

      <Form.Item
        label="Immagine"
        name="picture_id"
      >
        <FormFileUploader accept={'image/*'}/>
      </Form.Item>

      <Form.Item
        label="Link"
        name="url"
        rules={[
          { type: 'url' },
        ]}
      >
        <Input/>
      </Form.Item>

      <Form.Item
        label="Prezzo"
        name="price"
      >
        <InputNumber/>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {formType === FORM_TYPE_ADD ? 'inserisci' : 'aggiorna'}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ProductForm
