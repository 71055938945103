import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import CertificationForm from "../../../components/forms/CertificationForm"
import { gql, useMutation } from "@apollo/client"
import { FORM_TYPE_ADD } from "../../../enums/FormTypes"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import { navigate } from "gatsby"
import { Spin } from "antd"
import { DRAFT } from "../../../enums/RecordStates"
import { FREE } from "../../../enums/PricePolicies"
import { CERTIFICATION, COURSE } from "../../../enums/ItemTypes"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"

const INSERT_CERTIFICATION_MUTATION = gql`
  mutation insertSeo($data: seo_insert_input!) {
    insert_seo_one(object: $data) {
      certification {
        id
      }
    }
  }
`

const INSERT_CERTIFICATION_REQUIREMENTS_MUTATION = gql`
  mutation insertCertificationRequirements(
    $data: [certification_requirements_insert_input!]!
  ) {
    insert_certification_requirements(objects: $data) {
      affected_rows
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const AddCertificationPage = () => {
  const [insertCertification] = useMutation(INSERT_CERTIFICATION_MUTATION)
  const [insertCertificationRequirements] = useMutation(
    INSERT_CERTIFICATION_REQUIREMENTS_MUTATION
  )
  const [loading, setLoading] = useState(false)

  const onFinish = async values => {
    setLoading(true)
    try {
      let newValues = { ...values }
      delete newValues.required_certifications
      delete newValues.required_courses
      delete newValues.seo

      const data = {
        ...values.seo,
        certification: {
          data: {
            ...newValues,
          },
        },
      }

      const result = await insertCertification({ variables: { data } })
      const certification_id = result.data.insert_seo_one.certification.id

      let requirements =
        values.required_certifications?.map(item => ({
          certification_id,
          required_item_id: item,
          required_item_type: CERTIFICATION,
        })) ?? []

      requirements = requirements.concat(
        values.required_courses?.map(item => ({
          certification_id,
          required_item_id: item,
          required_item_type: COURSE,
        })) ?? []
      )

      if (requirements.length) {
        await insertCertificationRequirements({
          variables: {
            data: requirements,
          },
        })
      }

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/contents/certifications")
        },
      })
    } catch (error) {
      uiHandleError({
        error,
        action: () => {},
      })
    }
    setLoading(false)
  }

  const initialValues =
    process.env.NODE_ENV === "development"
      ? {
          state: DRAFT,
          title: "titolo",
          subtitle: "sottotitolo",
          short_description: "descrizione breve",
          description: "descrizione completa",
          landscape_locked_id: "e6f2364b-cd77-40dd-9ff4-790097d53402",
          landscape_success_id: "e6f2364b-cd77-40dd-9ff4-790097d53402",
          wallpaper_id: "e6f2364b-cd77-40dd-9ff4-790097d53402",
          badge_title: "Badge title",
          badge_id: "e6f2364b-cd77-40dd-9ff4-790097d53402",
          trailer_poster_id: "e6f2364b-cd77-40dd-9ff4-790097d53402",
          trailer_id: "1d67c838-ef12-4e6c-bd2c-c1941f26f815",
          extra_info: "informazioni utili",
          release_info: {
            description: "Release info description",
            pictures: [
              "e6f2364b-cd77-40dd-9ff4-790097d53402",
              "e6f2364b-cd77-40dd-9ff4-790097d53402",
              "e6f2364b-cd77-40dd-9ff4-790097d53402",
            ],
          },
          authorities: [
            "e6f2364b-cd77-40dd-9ff4-790097d53402",
            "e6f2364b-cd77-40dd-9ff4-790097d53402",
            "e6f2364b-cd77-40dd-9ff4-790097d53402",
          ],
          where_to_use: ["utilizzato in ambito xxx"],
          seo: {
            item_type: CERTIFICATION,
          },
        }
      : {
          state: DRAFT,
          price_policy: FREE,
          videos: [],
          seo: {
            item_type: CERTIFICATION,
          },
        }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={loading}>
        <CertificationForm
          initialValues={initialValues}
          onFinish={onFinish}
          formType={FORM_TYPE_ADD}
        />
      </Spin>
    </PageRoot>
  )
}

export default AddCertificationPage
