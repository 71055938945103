import { ARCHIVED, DRAFT, PUBLISHED } from "../enums/RecordStates"
import { PublishStatus } from "../components/Labels"
import React from "react"
import moment from "moment"
import useColumnDateFilter from "./useColumnDateFilter"
import useColumnSearch from "./useColumnSearch"
import { CERTIFICATION, DIPLOMA, MASTER } from "../enums/CertificationTypes"
import { Tag } from "antd"

const useColumns = () => {
  const createdAtRangeFilter = useColumnDateFilter({ dataIndex: "created_at" })
  const startAtRangeFilter = useColumnDateFilter({ dataIndex: "start_at" })
  const endAtRangeFilter = useColumnDateFilter({ dataIndex: "end_at" })
  const availableAtRangeFilter = useColumnDateFilter({
    dataIndex: "available_date",
  })
  const titleSearchColumnOptions = useColumnSearch({ dataIndex: "title" })
  const subtitleSearchColumnOptions = useColumnSearch({ dataIndex: "subtitle" })

  const authorSearchColumnOptions = useColumnSearch({
    dataIndex: "author",
    transformer: record => {
      const authors = record.author_rels.map(({ author }) => {
        return author ? `${author.firstname} ${author.lastname}` : ""
      })
      return authors.join(",")
    },
  })
  const courseSearchColumnOptions = useColumnSearch({
    dataIndex: "course",
    transformer: record => {
      return record.course_rel?.course?.title ?? ""
    },
  })

  const firstnameSearchColumnOptions = useColumnSearch({
    dataIndex: "firstname",
  })
  const lastnameSearchColumnOptions = useColumnSearch({ dataIndex: "lastname" })

  return {
    state: {
      title: "Stato",
      filters: [
        {
          text: "Bozza",
          value: DRAFT,
        },
        {
          text: "Pubblicato",
          value: PUBLISHED,
        },
        {
          text: "Archiviato",
          value: ARCHIVED,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.state === value,
      render: record => {
        return <PublishStatus state={record.state} />
      },
    },
    certificationType: {
      title: "Tipologia",
      filters: [
        {
          text: "Certificazione",
          value: CERTIFICATION,
        },
        {
          text: "Diploma",
          value: DIPLOMA,
        },
        {
          text: "Master",
          value: MASTER,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.type === value,
      render: record => {
        return <Tag>{record.type}</Tag>
      },
    },
    createdAt: {
      title: "Inserito il",
      ...createdAtRangeFilter,
      sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
    },
    startAt: {
      title: "Inizia il",
      ...startAtRangeFilter,
      sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
    },
    endAt: {
      title: "Finisce il",
      ...endAtRangeFilter,
      sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
    },
    availableAt: {
      title: "Disponibile il",
      ...availableAtRangeFilter,
      sorter: (a, b) => moment(a.created_at) - moment(b.created_at),
    },
    title: {
      title: "Titolo",
      dataIndex: "title",
      ...titleSearchColumnOptions,
      sorter: (a, b) =>
        a.title?.toLowerCase() > b.title?.toLowerCase() ? 1 : -1,
    },
    subtitle: {
      title: "Sottotitolo",
      dataIndex: "subtitle",
      ...subtitleSearchColumnOptions,
      sorter: (a, b) =>
        a.subtitle?.toLowerCase() > b.subtitle?.toLowerCase() ? 1 : -1,
    },
    author: {
      title: "Autore",
      ...authorSearchColumnOptions,
    },
    course: {
      title: "Corso",
      ...courseSearchColumnOptions,
    },
    firstname: {
      title: "Nome",
      dataIndex: "firstname",
      ...firstnameSearchColumnOptions,
      sorter: (a, b) =>
        a.firstname?.toLowerCase() > b.firstname?.toLowerCase() ? 1 : -1,
    },
    lastname: {
      title: "Cognome",
      dataIndex: "lastname",
      ...lastnameSearchColumnOptions,
      sorter: (a, b) =>
        a.lastname?.toLowerCase() > b.lastname?.toLowerCase() ? 1 : -1,
    },
  }
}

export default useColumns
