import { Button, Form, Input } from "antd"
import { FORM_TYPE_ADD } from "../../enums/FormTypes"
import React from "react"

const ItemCodeRelForm = ({ initialValues, onFinish, formType, showAction }) => {
  return (
    <Form initialValues={initialValues} onFinish={onFinish} layout={"vertical"}>
      <Form.Item label="Item id" name="item_id" hidden>
        <Input />
      </Form.Item>
      <Form.Item label="Codice ERP" name="default_code">
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {formType === FORM_TYPE_ADD ? "inserisci" : "aggiorna"}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default ItemCodeRelForm
