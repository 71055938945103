import React, { useEffect, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import LoadingScreen from "./LoadingScreen"
import ErrorScreen from "./ErrorScreen"
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client"
import fetch from "cross-fetch"
import { setContext } from "@apollo/client/link/context"

import { onError } from "@apollo/client/link/error"

const AuthHandler = ({ children }) => {
  const {
    isAuthenticated,
    isLoading,
    error: authError,
    logout,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0()

  const [pageReady, setPageReady] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    setPageReady(true)
  }, [])

  if (isLoading) {
    return <LoadingScreen />
  }

  if (!pageReady) {
    return <LoadingScreen />
  }

  if (authError || error) {
    return <ErrorScreen error={authError || error} />
  }

  if (!isAuthenticated) {
    if (window.location.pathname.indexOf("/auth") < 0) {
      loginWithRedirect()
      //navigate("/auth/sign-in", { replace: true })
      return null
    } else {
      return children
    }
  }

  const httpLink = createHttpLink({
    uri: process.env.GATSBY_GRAPHQL_ENDPOINT,
    fetch,
  })

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = await getAccessTokenSilently()

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        "x-hasura-role": "manager",
      },
    }
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )

    if (networkError) console.log(`[Network error]: ${networkError}`)

    const errorCode = graphQLErrors[0]?.extensions?.code
    if (errorCode === "access-denied") {
      setError(new Error(errorCode))
    }
  })

  const apolloClient = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default AuthHandler
