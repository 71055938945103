import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import BundleForm from "../../../components/forms/BundleForm"
import { gql, useMutation } from "@apollo/client"
import { FORM_TYPE_ADD } from "../../../enums/FormTypes"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import { navigate } from "gatsby"
import { Spin } from "antd"
import { DRAFT } from "../../../enums/RecordStates"
import { BUNDLE } from "../../../enums/ItemTypes"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
} from "../../../enums/UserRoles"

const INSERT_BUNDLE_MUTATION = gql`
  mutation insertSeo($data: seo_insert_input!) {
    insert_seo(objects: [$data]) {
      affected_rows
    }
  }
`

const authorizedRoles = [
  ROLE_ADMIN,
  ROLE_ACCOUNTING,
  ROLE_SALE,
  ROLE_PRODUCT_MANAGER,
]

const AddBundlePage = () => {
  const [insertBundle] = useMutation(INSERT_BUNDLE_MUTATION)
  const [loading, setLoading] = useState(false)

  const onFinish = async values => {
    setLoading(true)
    try {
      let newValues = { ...values }
      delete newValues.items
      delete newValues.seo

      const itemData = values.items
        ? values.items.map((id, index) => ({
            item_id: id,
          }))
        : []

      newValues.item_rels = { data: itemData }

      const data = {
        ...values.seo,
        bundle: {
          data: {
            ...newValues,
          },
        },
      }

      await insertBundle({ variables: { data } })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/promo/bundles")
        },
      })
    } catch (error) {
      uiHandleError({
        error,
        action: () => {},
      })
    }
    setLoading(false)
  }

  const initialValues =
    process.env.NODE_ENV === "development"
      ? {
          state: DRAFT,
          title: "titolo",
          subtitle: "sottotitolo",
          short_description: "descrizione breve",
          description: "descrizione completa",
          landscape_id: "e6f2364b-cd77-40dd-9ff4-790097d53402",
          wallpaper_id: "e6f2364b-cd77-40dd-9ff4-790097d53402",
          header_image_id: "e6f2364b-cd77-40dd-9ff4-790097d53402",
          seo: {
            item_type: BUNDLE,
          },
        }
      : {
          state: DRAFT,
          items: [],
          seo: {
            item_type: BUNDLE,
          },
        }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={loading}>
        <BundleForm
          initialValues={initialValues}
          onFinish={onFinish}
          formType={FORM_TYPE_ADD}
        />
      </Spin>
    </PageRoot>
  )
}

export default AddBundlePage
