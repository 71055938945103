import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import LoadingScreen from "../../components/LoadingScreen"


const SignIn = () => {
  const { loginWithRedirect } = useAuth0()

  loginWithRedirect()

  return <LoadingScreen />

}

export default SignIn
