import React from "react"
import { gql } from "@apollo/client"
import useColumnSearch from "../../hooks/useColumnSearch"
import DataTable from "../../components/DataTable"
import PageRoot from "../../components/PageRoot"

const GET_ALL_FILES_QUERY = gql`
  query getAllFiles {
    files(order_by: { filename: asc }) {
      id
      filename
      mimetype
      created_at
      created_by
      duration
      extension
      height
      is_public
      s3_key
      size
      width
    }
  }
`

const FileListPage = () => {
  const nameSearchColumnOptions = useColumnSearch({ dataIndex: "filename" })

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Nome",
      dataIndex: "filename",
      ...nameSearchColumnOptions,
    },
    {
      title: "mimetype",
      dataIndex: "mimetype",
    },
    {
      title: "extension",
      dataIndex: "extension",
    },
    {
      title: "s3_key",
      dataIndex: "s3_key",
    },
    {
      title: "size",
      dataIndex: "size",
    },
  ]

  return (
    <PageRoot>
      <DataTable
        dataKey={"files"}
        query={GET_ALL_FILES_QUERY}
        columns={columns}
      />
    </PageRoot>
  )
}

export default FileListPage
