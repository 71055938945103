import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import CouponCriterionForm from "../../../components/forms/CouponCriterionForm"
import { FORM_TYPE_EDIT } from "../../../enums/FormTypes"
import QueryString from "query-string"
import { gql, useMutation, useQuery } from "@apollo/client"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import { Button, Result, Spin } from "antd"
import { Link, navigate } from "gatsby"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
} from "../../../enums/UserRoles"
import moment from "moment"

const GET_COUPON_CRITERION_QUERY = gql`
  query getCouponCriterion($id: uuid!) {
    coupon_criteria_by_pk(id: $id) {
      id
      title
      subtitle
      description
      short_description
      picture_id
      created_at
      created_by
      discount_type
      end_at
      exclusive_for_content_id
      exclusive_for_content_type
      percentage
      start_at
      value
    }
  }
`

const UPDATE_COUPON_CRITERION_MUTATION = gql`
  mutation updateCouponCriterion(
    $id: uuid!
    $data: coupon_criteria_set_input!
  ) {
    update_coupon_criteria_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`

const authorizedRoles = [
  ROLE_ADMIN,
  ROLE_ACCOUNTING,
  ROLE_SALE,
  ROLE_PRODUCT_MANAGER,
]

const EditCouponCriterionPage = ({ location }) => {
  const [updating, setUpdating] = useState(false)

  const params = QueryString.parse(location.search)

  const { data, error, loading } = useQuery(GET_COUPON_CRITERION_QUERY, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  })

  const [updateCouponCriterion] = useMutation(UPDATE_COUPON_CRITERION_MUTATION)

  const handleFormSubmit = async values => {
    setUpdating(true)
    let newValues = { ...values }
    try {
      await updateCouponCriterion({
        variables: { data: newValues, id: params.id },
      })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/promo/coupons")
        },
      })
    } catch (error) {
      uiHandleError({ error })
    }
    setUpdating(false)
  }

  if (loading) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <LoadingScreen />
      </PageRoot>
    )
  }

  if (error) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  const couponCriterion = data.coupon_criteria_by_pk

  if (!couponCriterion) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <Result
          status="404"
          title="404"
          subTitle="Elemento non trovato!"
          extra={
            <Button type="primary">
              <Link to={"/promo/coupons"}>Torna indietro</Link>
            </Button>
          }
        />
      </PageRoot>
    )
  }

  const initialValues = {
    ...couponCriterion,
    start_at: couponCriterion.start_at
      ? moment(couponCriterion.start_at)
      : null,
    end_at: couponCriterion.end_at ? moment(couponCriterion.end_at) : null,
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={updating}>
        <CouponCriterionForm
          initialValues={initialValues}
          onFinish={handleFormSubmit}
          formType={FORM_TYPE_EDIT}
        />
      </Spin>
    </PageRoot>
  )
}

export default EditCouponCriterionPage
