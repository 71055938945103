import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Menu } from "antd"
import useCommonQueries from "../hooks/useCommonQueries"
import PermissionDeniedScreen from "./PermissionDeniedScreen"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_WAREHOUSE,
} from "../enums/UserRoles"
import Layout from "./Layout"
import { useGlobalValue } from "../hooks/useGlobalValue"
import { SET_USER } from "../enums/ReducerTypes"

const { SubMenu } = Menu

const PageRoot = ({
  children,
  authorizedRoles = [
    ROLE_ADMIN,
    ROLE_ACCOUNTING,
    ROLE_WAREHOUSE,
    ROLE_SALE,
    ROLE_PRODUCT_MANAGER,
  ],
}) => {
  const { getCurrentUser } = useCommonQueries()
  const [, dispatch] = useGlobalValue()
  const [checkingPermission, setCheckingPermission] = useState(true)
  const [hasPermission, setHasPermission] = useState(false)
  // const { user } = useAuth0()
  const [user, setUser] = useState(null)

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await getCurrentUser()
      if (authorizedRoles.indexOf(currentUser.role) >= 0) {
        setHasPermission(true)
      }

      dispatch({ type: SET_USER, value: currentUser })
      setUser(currentUser)
      setCheckingPermission(false)
    }
    fetchUser()
  }, [])

  const pageContent = useMemo(() => {
    return <Layout user={user}>{children}</Layout>
  }, [user, children])

  if (!checkingPermission && !hasPermission) {
    return <PermissionDeniedScreen />
  }

  return pageContent
}

PageRoot.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PageRoot
