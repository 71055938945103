import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import UserForm from "../../../components/forms/UserForm"
import { FORM_TYPE_EDIT } from "../../../enums/FormTypes"
import QueryString from "query-string"
import { gql, useMutation, useQuery } from "@apollo/client"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import { Button, Result, Spin } from "antd"
import { Link, navigate } from "gatsby"
import { uiHandleError, uiHandleSuccess } from "../../../utils"

const GET_USER_QUERY = gql`
  query getUser($id: String!) {
    auth0_users_by_pk(user_id: $id) {
      user_id
      email
      basic_profile {
        id
        firstname
        lastname
        phone_number
        email
        user_id
      }
    }
  }
`

const UPDATE_USER_MUTATION = gql`
  mutation updateUser($data: user_profiles_insert_input!) {
    insert_user_profiles_one(
      object: $data
      on_conflict: {
        constraint: user_profiles_user_id_key
        update_columns: [firstname, lastname, email, address, phone_number]
      }
    ) {
      id
    }
  }
`

const EditUserPage = ({ location }) => {
  const [updating, setUpdating] = useState(false)

  const params = QueryString.parse(location.search)

  const userId = atob(params.id)

  const { data, error, loading } = useQuery(GET_USER_QUERY, {
    variables: { id: userId },
    fetchPolicy: "network-only",
  })

  const [updateUser] = useMutation(UPDATE_USER_MUTATION)

  const handleFormSubmit = async values => {
    setUpdating(true)
    try {
      await updateUser({
        variables: {
          data: values,
        },
      })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/users/transactions")
        },
      })
    } catch (error) {
      uiHandleError({ error })
    }
    setUpdating(false)
  }

  if (loading) {
    return (
      <PageRoot>
        <LoadingScreen />
      </PageRoot>
    )
  }

  if (error) {
    return (
      <PageRoot>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  const user = data.auth0_users_by_pk

  if (!user) {
    return (
      <PageRoot>
        <Result
          status="404"
          title="404"
          subTitle="Elemento non trovato!"
          extra={
            <Button type="primary">
              <Link to={"/users/transactions"}>Torna indietro</Link>
            </Button>
          }
        />
      </PageRoot>
    )
  }

  const initialValues = {
    ...(user.basic_profile || {}),
    email: user.basic_profile?.email || user.email,
    user_id: user.user_id,
  }

  return (
    <PageRoot>
      <Spin spinning={updating}>
        <UserForm
          initialValues={initialValues}
          onFinish={handleFormSubmit}
          formType={FORM_TYPE_EDIT}
        />
      </Spin>
    </PageRoot>
  )
}

export default EditUserPage
