import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import CouponCriterionForm from "../../../components/forms/CouponCriterionForm"
import { gql, useMutation } from "@apollo/client"
import { FORM_TYPE_ADD } from "../../../enums/FormTypes"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import { navigate } from "gatsby"
import { Spin } from "antd"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
} from "../../../enums/UserRoles"
import { VALUE } from "../../../enums/ValueTypes"

const INSERT_COUPON_CRITERION_MUTATION = gql`
  mutation insertCouponCriterion($data: coupon_criteria_insert_input!) {
    insert_coupon_criteria_one(object: $data) {
      id
    }
  }
`

const authorizedRoles = [
  ROLE_ADMIN,
  ROLE_ACCOUNTING,
  ROLE_SALE,
  ROLE_PRODUCT_MANAGER,
]

const AddCouponCriterionPage = () => {
  const [insertCouponCriterion] = useMutation(INSERT_COUPON_CRITERION_MUTATION)
  const [loading, setLoading] = useState(false)

  const onFinish = async data => {
    setLoading(true)
    try {
      let newValues = { ...data }

      await insertCouponCriterion({ variables: { data: newValues } })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/promo/coupons")
        },
      })
    } catch (error) {
      uiHandleError({
        error,
        action: () => {},
      })
    }
    setLoading(false)
  }

  const initialValues =
    process.env.NODE_ENV === "development"
      ? {
          title: "titolo",
          subtitle: "sottotitolo",
          short_description: "descrizione breve",
          description: "descrizione completa",
          picture_id: "e6f2364b-cd77-40dd-9ff4-790097d53402",
          exclusive_for_content_type: null,
          discount_type: VALUE,
        }
      : {
          exclusive_for_content_type: null,
          discount_type: VALUE,
        }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={loading}>
        <CouponCriterionForm
          initialValues={initialValues}
          onFinish={onFinish}
          formType={FORM_TYPE_ADD}
        />
      </Spin>
    </PageRoot>
  )
}

export default AddCouponCriterionPage
