import React, { useEffect, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import DataTable from "../../../components/DataTable"
import { Link } from "gatsby"
import { Affix, Button, Popconfirm, Space } from "antd"
import PageRoot from "../../../components/PageRoot"
import { PlusOutlined } from "@ant-design/icons"
import useColumnSearch from "../../../hooks/useColumnSearch"
import LoadingScreen from "../../../components/LoadingScreen"
import { useGlobalValue } from "../../../hooks/useGlobalValue"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"
import useColumns from "../../../hooks/useColumns"

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const ALL_AUTHOR_LIST_QUERY = gql`
  {
    authors(order_by: { firstname: asc }) {
      id
      state
      firstname
      lastname
    }
  }
`

const DELETE_AUTHOR_MUTATION = gql`
  mutation deleteAuthor($id: uuid!) {
    delete_authors_by_pk(id: $id) {
      id
    }
    delete_seo(where: { item_id: { _eq: $id } }) {
      affected_rows
    }
  }
`

const AuthorListPage = () => {
  const columnOptions = useColumns()

  const [{ user }] = useGlobalValue()
  const firstnameSearchColumnOptions = useColumnSearch({
    dataIndex: "firstname",
  })
  const lastnameSearchColumnOptions = useColumnSearch({ dataIndex: "lastname" })

  const [refreshing, setRefreshing] = useState(false)
  const [deleteCategory] = useMutation(DELETE_AUTHOR_MUTATION)
  useEffect(() => {
    if (refreshing) {
      setRefreshing(false)
    }
  }, [refreshing])

  if (refreshing) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <LoadingScreen />
      </PageRoot>
    )
  }

  const deleteRecord = async id => {
    try {
      await deleteCategory({ variables: { id } })
      setRefreshing(true)
    } catch (error) {}
  }

  const columns = [
    {
      ...columnOptions.state,
    },
    {
      ...columnOptions.firstname,
    },
    {
      ...columnOptions.lastname,
    },
    {
      title: "Azioni",
      render: record => {
        if (authorizedRoles.indexOf(user?.role) >= 0) {
          return (
            <Space>
              {/*<Button><Link to={`/contents/authors/view?id=${record.id}`}>mostra</Link></Button>*/}
              <Button type="primary">
                <Link to={`/contents/authors/edit?id=${record.id}`}>
                  modifica
                </Link>
              </Button>
              <Button type="primary">
                <Link to={`/contents/authors/view?id=${record.id}`}>
                  Dettaglio
                </Link>
              </Button>
              <Popconfirm
                placement="topRight"
                title={"Sei sicuro di voler eliminare questo record?"}
                onConfirm={() => {
                  deleteRecord(record.id)
                }}
                okText="elimina"
                cancelText="annulla"
              >
                <Button type="danger">elimina</Button>
              </Popconfirm>
            </Space>
          )
        } else {
          return (
            <Button type="primary">
              <Link to={`/contents/authors/view?id=${record.id}`}>
                Dettaglio
              </Link>
            </Button>
          )
        }
      },
    },
  ]

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
        <Link to={`/contents/authors/add`}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            shape="circle"
            size="large"
          />
        </Link>
      </Affix>

      <DataTable
        dataKey={"authors"}
        query={ALL_AUTHOR_LIST_QUERY}
        columns={columns}
      />
    </PageRoot>
  )
}

export default AuthorListPage
