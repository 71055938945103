import React from "react"
import PageRoot from "../../../components/PageRoot"
import { gql, useMutation, useQuery } from "@apollo/client"
import ErrorScreen from "../../../components/ErrorScreen"
import LoadingScreen from "../../../components/LoadingScreen"
import HomepageLayoutForm from "../../../components/forms/HomepageLayoutForm"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"

const GET_HOMEPAGE_LAYOUT_QUERY = gql`
  {
    global_settings(where: { key: { _eq: "HOME_PAGE_LAYOUT" } }) {
      value
    }
  }
`

const UPDATE_HOMEPAGE_LAYOUT_MUTATION = gql`
  mutation updateHomepageLayout($data: global_settings_insert_input!) {
    insert_global_settings_one(
      object: $data
      on_conflict: { constraint: global_settings_pkey, update_columns: value }
    ) {
      key
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const HomePageSettingsPage = () => {
  const { loading, error, data } = useQuery(GET_HOMEPAGE_LAYOUT_QUERY, {
    fetchPolicy: "cache-and-network",
  })

  const [updateHomepageLayout] = useMutation(UPDATE_HOMEPAGE_LAYOUT_MUTATION)

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const homepageSettings = JSON.parse(data.global_settings[0]?.value ?? "{}")

  const handleFormSubmit = async values => {
    const data = { ...homepageSettings, ...values }
    try {
      await updateHomepageLayout({
        variables: {
          data: {
            key: "HOME_PAGE_LAYOUT",
            value: JSON.stringify(data),
          },
        },
      })
      uiHandleSuccess({ message: "Impostazione aggiornata.", action: () => {} })
    } catch (error) {
      uiHandleError({ error })
    }
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <HomepageLayoutForm
        initialValues={homepageSettings}
        onFinish={handleFormSubmit}
      />
    </PageRoot>
  )
}

export default HomePageSettingsPage
