import React from "react"
import * as prettyMilliseconds from "pretty-ms"
import { Modal, Space } from "antd"
import moment from "moment"
import {
  FacebookOutlined,
  GoogleOutlined,
  MailOutlined,
} from "@ant-design/icons"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_TRAINER,
  ROLE_USER,
  ROLE_VIP,
  ROLE_WAREHOUSE,
} from "./enums/UserRoles"
import _ from "lodash"

export const formatDuration = (duration, colonNotation = true) => {
  return prettyMilliseconds(Number(duration).toFixed(0) * 1000, {
    colonNotation,
  })
}

export const uiHandleError = ({ error, action, message }) => {
  console.log({ error })
  Modal.error({
    content: message || error.message,
    onOk: () => {
      action && action()
    },
  })
}

export const uiHandleSuccess = ({ action, message }) => {
  Modal.success({
    content: message || "Operazione completata",
    onOk: () => {
      action ? action() : window.history.back()
    },
  })
}

export const getValueByPath = (object, path) => {
  const parts = path.split(".")
  let lastValue = object

  if (!lastValue) {
    return lastValue
  }

  for (let i = 0; i < parts.length; i++) {
    if (lastValue[parts[i]]) {
      lastValue = lastValue[parts[i]]
    } else {
      return lastValue[parts[i]]
    }
  }

  return lastValue
}

export const asyncLoadImageFromFile = file => {
  const objectUrl = URL.createObjectURL(file)

  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      URL.revokeObjectURL(objectUrl)
      resolve(img)
    }
    img.onerror = () => {
      URL.revokeObjectURL(objectUrl)
      reject()
    }
    img.src = objectUrl
  })
}

export const asyncLoadVideoFromFile = file => {
  const objectUrl = URL.createObjectURL(file)

  return new Promise((resolve, reject) => {
    const video = document.createElement("video")
    video.preload = "metadata"

    video.onloadedmetadata = () => {
      URL.revokeObjectURL(objectUrl)
      resolve(video)
    }
    video.onerror = error => {
      URL.revokeObjectURL(objectUrl)
      reject(error)
    }
    video.src = objectUrl
  })
}

export const wipeExtension = filename => {
  return filename.replace(/(\.[^\\]{3,4})$/, "")
}

export const formatDate = (date, { format = "lll" } = {}) => {
  return moment(date).format(format)
}

export const formatNumber = ({
  value,
  precision,
  decimalSeparator,
  groupSeparator,
}) => {
  const val = String(value)
  const cells = val.match(/^(-?)(\d*)(\.(\d+))?$/)

  if (!cells || val === "-") {
    return val
  } else {
    const negative = cells[1]
    let int = cells[2] || "0"
    let decimal = cells[4] || ""

    int = int.replace(/\B(?=(\d{3})+(?!\d))/g, groupSeparator)

    if (typeof precision === "number") {
      decimal = _.padEnd(decimal, precision, "0").slice(0, precision)
    }

    return `${negative}${int}${decimalSeparator}${decimal}`
  }
}

export const getCurrencySymbol = currency => {
  const symbols = {
    EUR: "€",
    USD: "$",
  }

  return symbols[currency] || currency || symbols.EUR
}

export const formatPrice = (price, currency = process.env.GATSBY_CURRENCY) => {
  if (isNaN(price)) {
    return "--"
  }

  const currencySymbol = getCurrencySymbol(currency)
  return `${formatNumber({
    value: price,
    precision: 2,
    decimalSeparator: process.env.GATSBY_DECIMAL_SEPARATOR || ".",
    groupSeparator: process.env.GATSBY_GROUP_SEPARATOR || ",",
  })} ${currencySymbol}`
}

export const getConnectionTypeByUserId = userId => {
  const types = {
    facebook: (
      <Space>
        <FacebookOutlined /> Facebook
      </Space>
    ),
    auth0: (
      <Space>
        <MailOutlined /> Email
      </Space>
    ),
    "google-oauth2": (
      <Space>
        <GoogleOutlined />
        Google
      </Space>
    ),
  }

  return types[userId.split("|")[0]]
}

const ROLE_TRANSLATIONS = {
  [ROLE_ADMIN]: "Amministrazione",
  [ROLE_USER]: "Utente normale",
  [ROLE_ACCOUNTING]: "Contabile",
  [ROLE_SALE]: "Commerciale",
  [ROLE_PRODUCT_MANAGER]: "Product Manager",
  [ROLE_WAREHOUSE]: "Magazzino",
  [ROLE_TRAINER]: "Trainer",
  [ROLE_VIP]: "VIP",
}

export const getRoleTranslation = role => {
  return ROLE_TRANSLATIONS[role] || role
}

export const downloadCsvFile = (data, filename) => {
  let planData =
    "data:text/csv;charset=utf-8," + data.map(e => e.join(",")).join("\n")
  const encodedUri = encodeURI(planData)
  const link = document.createElement("a")
  link.setAttribute("href", encodedUri)
  link.setAttribute("download", filename)
  document.body.appendChild(link) // Required for FF

  link.click()
  link.remove()
}

export const renderUserLabel = ({ email, basic_profile, user_id }) => {
  const accountType = user_id?.split("|")[0]

  if (!basic_profile) {
    return `${email} - [${accountType}]`
  }

  const { firstname, lastname } = basic_profile

  return `${email} - ${firstname} ${lastname} - [${accountType}]`
}

export const isSubscriptionValid = subscription => {
  if (!subscription) {
    return false
  }

  const now = moment()
  const endAt = moment(subscription.end_at).add(1, "day")

  return now < endAt
}
