import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import LoadingScreen from "../../components/LoadingScreen"

const SignOut = () => {
  const { logout } = useAuth0()

  logout({ returnTo: process.env.GATSBY_AUTH0_REDIRECT_URI })

  return <LoadingScreen />
}

export default SignOut
