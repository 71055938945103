import React from "react"
import { gql, useQuery } from "@apollo/client"
import ErrorScreen from "../ErrorScreen"
import { Select, Spin, Tag } from "antd"

const ALL_ITEM_LIST_QUERY = gql`
  {
    videos(
      where: {
        _or: [{ state: { _eq: PUBLISHED } }, { state: { _eq: COMING_SOON } }]
        _not: { course_rel: {} }
      }
      order_by: { title: asc }
    ) {
      __typename
      id
      title
    }
    courses(
      where: {
        _or: [{ state: { _eq: PUBLISHED } }, { state: { _eq: COMING_SOON } }]
      }
      order_by: { title: asc }
    ) {
      __typename
      id
      title
    }
    certifications(
      where: {
        _or: [{ state: { _eq: PUBLISHED } }, { state: { _eq: COMING_SOON } }]
      }
      order_by: { title: asc }
    ) {
      __typename
      id
      title
    }
    gift_cards(
      where: {
        _or: [{ state: { _eq: PUBLISHED } }, { state: { _eq: COMING_SOON } }]
      }
    ) {
      __typename
      id
      title
    }
    bundles(
      where: {
        _or: [
          { state: { _eq: "PUBLISHED" } }
          { state: { _eq: "COMING_SOON" } }
        ]
      }
      order_by: { title: asc }
    ) {
      __typename
      id
      title
    }
  }
`

const itemColors = {
  videos: "blue",
  courses: "yellow",
  certifications: "green",
  gift_cards: "red",
  bundles: "purple",
}

const typeNames = {
  videos: "Video",
  courses: "Corso",
  certifications: "Certificazione",
  gift_cards: "Gift Card",
  bundles: "Bundle",
}

const TypeTag = ({ type }) => (
  <Tag color={itemColors[type] ?? "red"}>{typeNames[type] ?? type}</Tag>
)

const AllItemSelector = ({ value, onChange, multiple = false }) => {
  const { loading, error, data } = useQuery(ALL_ITEM_LIST_QUERY)

  if (loading) {
    return <Spin spinning />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const { videos, courses, certifications, gift_cards, bundles } = data

  const items = [
    ...videos,
    ...courses,
    ...certifications,
    ...gift_cards,
    ...bundles,
  ]

  const onItemChange = newValue => {
    onChange && onChange(newValue)
  }

  const defaultValue = multiple ? [] : null

  return (
    <>
      <Select
        defaultValue={value || defaultValue}
        style={{
          width: "100%",
        }}
        mode={multiple ? "multiple" : null}
        showSearch
        optionFilterProp="children"
        onChange={onItemChange}
      >
        {!multiple && (
          <Select.Option value={null} key={"null"}>
            nessuna
          </Select.Option>
        )}
        {items.map(({ id, title, __typename }) => (
          <Select.Option value={`${__typename}:${id}`} key={id}>
            <TypeTag type={__typename} />
            {title}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

export default AllItemSelector
