import React, { useEffect, useMemo, useState } from "react"
import { gql, useQuery } from "@apollo/client"
import PageRoot from "../../../components/PageRoot"
import { ROLE_ADMIN, ROLE_WAREHOUSE } from "../../../enums/UserRoles"
import QueryString from "query-string"
import { Card, Col, DatePicker, List, Row, Skeleton } from "antd"
import ErrorScreen from "../../../components/ErrorScreen"
import _ from "lodash"
import moment from "moment"
import { formatDuration } from "../../../utils"

const { RangePicker } = DatePicker

const authorizedRoles = [ROLE_ADMIN, ROLE_WAREHOUSE]

const VIDEO_STATS_QUERY = gql`
  query getVideoStats($id: uuid!, $startAt: timestamptz, $endAt: timestamptz) {
    videos_by_pk(id: $id) {
      title
      subtitle
      short_description
      duration
      course_rel {
        course {
          title
        }
      }
      played_ranges_aggregate(
        where: { created_at: { _gte: $startAt, _lte: $endAt } }
      ) {
        aggregate {
          sum {
            duration
          }
        }
        nodes {
          start
          end
          duration
          created_at
        }
      }
      user_actions(where: { created_at: { _gte: $startAt, _lte: $endAt } }) {
        created_at
        action
        current_time
        previous_time
        session_id
      }
    }
  }
`

// reports on data:
/** reports
 1. chart su played range
 2. azioni su timeline
 3. numero di sessioni
 4. attivita nella giornata
 */

const PlaybackAreaChart = ({ videoDuration, playbackData }) => {
  const [ChartComponent, setChartComponent] = useState(null)

  useEffect(() => {
    const { Area } = require("@ant-design/charts")

    setChartComponent(Area)
  }, [])

  const chartData = useMemo(() => {
    const result = []

    for (let i = 0; i <= videoDuration; i++) {
      result[i] = {
        time: i,
        count: 0,
      }
    }

    playbackData.map(({ start, end, duration }) => {
      for (let i = Math.floor(start); i <= end; i++) {
        if (result[i]) {
          result[i].count++
        } else {
          result[i] = {
            time: i,
            count: 1,
          }
        }
      }
    })

    return result
  }, [playbackData])

  const config = {
    data: chartData,
    xField: "time",
    yField: "count",
    xAxis: {
      label: {
        formatter: value => {
          return formatDuration(value)
        },
      },
    },
    tooltip: {
      title: value => {
        return formatDuration(value)
      },
    },
  }
  return ChartComponent ? <ChartComponent {...config} /> : null
}

const UserActionTimelineChart = ({ videoDuration, actionData }) => {
  const [ChartComponent, setChartComponent] = useState(null)

  useEffect(() => {
    const { Column } = require("@ant-design/charts")

    setChartComponent(Column)
  }, [])

  const chartData = useMemo(() => {
    const result = []

    // for (let i = 0; i <= videoDuration; i++) {
    //   result[i] = {
    //     time: i,
    //     count: 0,
    //     action: "",
    //   }
    // }

    actionData.map(({ action, current_time, previous_time }) => {
      const integerTime = Math.floor(current_time)
      const item = result.find(
        item => item.time === integerTime && item.action === action
      )

      if (item) {
        item.count++
      } else {
        result.push({ time: integerTime, action: action, count: 1 })
      }
    })

    return _.orderBy(result, "time")
  }, [actionData])

  const config = {
    data: chartData,
    isGroup: true,
    xField: "time",
    yField: "count",
    seriesField: "action",
    xAxis: {
      label: {
        formatter: value => {
          return formatDuration(value)
        },
      },
    },
    tooltip: {
      title: value => {
        return formatDuration(value)
      },
    },
    slider: {
      start: 0,
      end: 0.3,
      formatter: value => {
        return formatDuration(value)
      },
    },
  }
  return ChartComponent ? <ChartComponent {...config} /> : null
}

const UserDaytimeActionChart = ({ actionData }) => {
  const [ChartComponent, setChartComponent] = useState(null)

  useEffect(() => {
    const { Column, Area } = require("@ant-design/charts")

    setChartComponent(Area)
  }, [])

  const chartData = useMemo(() => {
    const result = []
    actionData.map(({ created_at }) => {
      const time = moment(created_at).format("HH:mm")

      const item = result.find(record => record.time === time)
      if (item) {
        item.count++
      } else {
        result.push({
          time: time,
          count: 1,
        })
      }
    })

    return _.orderBy(result, "time")
  }, [actionData])

  const config = {
    data: chartData,
    xField: "time",
    yField: "count",
  }
  return ChartComponent ? <ChartComponent {...config} /> : null
}

const now = moment()

const VideoReportPage = ({ location }) => {
  const params = QueryString.parse(location.search)

  const [startAt, setStartAt] = useState(now.clone().add(-7, "day").toDate())
  const [endAt, setEndAt] = useState(now.clone().toDate())

  const { data, loading, error } = useQuery(VIDEO_STATS_QUERY, {
    variables: { id: params.id, startAt, endAt },
  })

  const sessionCount = useMemo(() => {
    if (!data) {
      return 0
    }

    const sessionIds = _.uniq(
      data.videos_by_pk.user_actions.map(({ session_id }) => session_id)
    )

    return sessionIds.length
  }, [data])

  if (loading) {
    return <Skeleton />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const video = data.videos_by_pk

  const { title, subtitle, short_description, duration } = video

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <RangePicker
            size="large"
            disabledDate={current =>
              current &&
              current > now.clone() &&
              current < now.clone().add(-1, "year")
            }
            ranges={{
              "Ultimi 7 giorni": [moment().add(-7, "day"), moment()],
              "Ultimi 30 giorni": [moment().add(-30, "day"), moment()],
              "Ultimo trimestre": [moment().add(-3, "month"), moment()],
            }}
            allowClear={false}
            value={[moment(startAt), moment(endAt)]}
            onChange={value => {
              setStartAt(value[0].toDate())
              setEndAt(value[1].toDate())
            }}
          />
        </Col>
        <Col span={12}>
          <Card hoverable title={"Dati video"}>
            <h2>{title}</h2>
            <h3>{subtitle}</h3>
            <p>{short_description}</p>
          </Card>
        </Col>
        <Col span={12}>
          <Card hoverable title={"Riassunti"}>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta title={"Durata video"} />
                <div>{formatDuration(duration)}</div>
              </List.Item>
              <List.Item>
                <List.Item.Meta title={"Numero di sessioni"} />
                <div>{sessionCount}</div>
              </List.Item>
              <List.Item>
                <List.Item.Meta title={"Riproduzione"} />
                <div>
                  {formatDuration(
                    data.videos_by_pk.played_ranges_aggregate.aggregate.sum
                      .duration
                  )}
                </div>
              </List.Item>
              <List.Item>
                <List.Item.Meta title={"Azioni utenti"} />
                <div>{data.videos_by_pk.user_actions.length}</div>
              </List.Item>
            </List>
          </Card>
        </Col>

        <Col span={24}>
          <Card hoverable title={"Grafico riproduzione"}>
            <PlaybackAreaChart
              videoDuration={duration}
              playbackData={data.videos_by_pk.played_ranges_aggregate.nodes}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Card hoverable title={"Grafico azioni"}>
            <UserActionTimelineChart
              videoDuration={duration}
              actionData={data.videos_by_pk.user_actions}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Card hoverable title={"Grafico giornata"}>
            <UserDaytimeActionChart
              actionData={data.videos_by_pk.user_actions}
            />
          </Card>
        </Col>
      </Row>
    </PageRoot>
  )
}

export default VideoReportPage
