import React, { useMemo, useState } from "react"
import { gql, useMutation, useQuery } from "@apollo/client"
import PageRoot from "../../../../components/PageRoot"
import { ROLE_ADMIN } from "../../../../enums/UserRoles"
import LoadingScreen from "../../../../components/LoadingScreen"
import ErrorScreen from "../../../../components/ErrorScreen"
import { Button, Col, Divider, Modal, Row, Select, Table } from "antd"
import _ from "lodash"
import { Link } from "gatsby"
import { PERCENTAGE } from "../../../../enums/ValueTypes"
import { ExclamationCircleOutlined } from "@ant-design/icons"

const ALL_AUTHOR_COURSE_COMMISSION_SETTING_LIST_QUERY = gql`
  {
    courses(where: { price_policy: { _eq: ON_DEMAND } }) {
      __typename
      id
      title
      videos {
        video {
          author_rels {
            author {
              id
              firstname
              lastname
            }
          }
        }
      }
    }
    authors(order_by: { firstname: asc_nulls_last }) {
      __typename
      id
      firstname
      lastname
      payday
      commission_settings {
        course_id
        author_id
        course {
          id
          title
        }
        value
        type
        created_at
      }
    }
  }
`

const UPDATE_PAYDAY_QUERY = gql`
  mutation updatePayday($authorId: uuid!, $payday: Int!) {
    update_authors_by_pk(
      pk_columns: { id: $authorId }
      _set: { payday: $payday }
    ) {
      payday
    }
  }
`

const availablePaydays = Array.from(Array(28).keys())

const PaydaySelect = ({ data }) => {
  const [selectedValue, setSelectedValue] = useState(data.payday)

  const [mutate] = useMutation(UPDATE_PAYDAY_QUERY)

  const handleChange = value => {
    Modal.confirm({
      title:
        "Sei sicuro di voler cambiare il giorno di paga per questo autore?",
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        return mutate({
          variables: {
            authorId: data.id,
            payday: value,
          },
        }).then(res => {
          setSelectedValue(value)
        })
      },
      onCancel() {
        setSelectedValue(data.payday)
      },
    })
  }

  return (
    <Select value={selectedValue} onChange={handleChange}>
      {availablePaydays.map(d => (
        <Select.Option key={d} value={d + 1}>
          {d + 1}
        </Select.Option>
      ))}
    </Select>
  )
}

const AuthorCourseCommissionListPage = () => {
  const { data, loading, error, refetch } = useQuery(
    ALL_AUTHOR_COURSE_COMMISSION_SETTING_LIST_QUERY,
    {
      fetchPolicy: "network-only",
    }
  )

  const authorCourseCommissionSuggestions = useMemo(() => {
    if (!data) {
      return null
    }

    let allCommissionSettings = []
    data.authors.map(author => {
      allCommissionSettings = allCommissionSettings.concat(
        author.commission_settings
      )
    })

    const insertAuthors = allCommissionSettings.map(
      ({ course_id, author_id }) => `${course_id};${author_id}`
    )

    return (
      <div>
        <h3>Provvigioni da impostare</h3>
        {data.courses.map(course => {
          let authors = []
          course.videos.map(({ video }) => {
            authors = authors.concat(video.author_rels.map(rel => rel.author))
          })

          authors = _.uniqBy(
            authors.filter(author => {
              if (!author) {
                return false
              }

              return insertAuthors.indexOf(`${course.id};${author.id}`) < 0
            }),
            "id"
          )

          if (authors.length === 0) {
            return null
          }

          return (
            <div key={course.id}>
              <Divider orientation="left">{course.title}</Divider>
              <ul style={{ listStyle: "none" }}>
                {authors.map((author, index) => (
                  <li key={index} style={{ marginBottom: 10 }}>
                    <Row>
                      <Col
                        span={8}
                      >{`${author.firstname} ${author.lastname}`}</Col>
                      <Col span={8}>
                        <Button type="primary">
                          <Link
                            to={`/monetization/author-commissions/settings/add?courseId=${course.id}&authorId=${author.id}`}
                          >
                            Imposta
                          </Link>
                        </Button>
                      </Col>
                    </Row>
                  </li>
                ))}
              </ul>
            </div>
          )
        })}
      </div>
    )
  }, [data])

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const mappedData = data.authors
    .filter(author => author.commission_settings.length > 0)
    .map(author => {
      const { commission_settings } = author
      const authorData = {
        ...author,
        children: [],
      }

      commission_settings.map(setting => {
        authorData.children.push(setting)
      })

      return authorData
    })

  const columns = [
    {
      title: "Autore",
      render: item =>
        item?.__typename === "authors" && `${item.firstname} ${item.lastname}`,
    },
    {
      title: "Corso",
      render: item => {
        console.log({ item })
        return item?.course?.title
      },
    },
    {
      title: "Provvigione",
      render: item => {
        if (item?.__typename === "authors") {
          return null
        }

        const { type, value } = item
        return type === PERCENTAGE ? `${value * 100}%` : value
      },
    },
    {
      title: "Giorno del saldo",
      render: item =>
        item?.__typename === "authors" && <PaydaySelect data={item} />,
    },
  ]

  return (
    <PageRoot authorizedRoles={[ROLE_ADMIN]}>
      {authorCourseCommissionSuggestions}
      <Table rowKey={"id"} dataSource={mappedData} columns={columns} />
    </PageRoot>
  )
}

export default AuthorCourseCommissionListPage
