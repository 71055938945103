import { gql, useApolloClient } from "@apollo/client"

const useNotification = () => {
  const client = useApolloClient()

  const getAllUserIds = async () => {
    const query = gql`
      {
        auth0_users {
          user_id
        }
      }
    `

    const { data, errors } = await client.query({
      query,
    })

    if (errors) {
      throw errors[0]
    }

    return data.auth0_users.map(({ user_id }) => user_id)
  }

  const insertNotificationFeeds = async (notificationId, userIds) => {
    const mutation = gql`
      mutation insertNotificationFeeds(
        $data: [notification_feeds_insert_input!]!
      ) {
        insert_notification_feeds(objects: $data) {
          affected_rows
        }
      }
    `
    const feedsData = userIds.map(userId => ({
      user_id: userId,
      notification_id: notificationId,
    }))

    const { data, errors } = await client.mutate({
      mutation,
      variables: { data: feedsData },
    })

    return data
  }

  const insertNotification = async notification => {
    const mutation = gql`
      mutation insertNotification($data: notifications_insert_input!) {
        insert_notifications_one(object: $data) {
          id
        }
      }
    `
    const { data, errors } = await client.mutate({
      mutation,
      variables: { data: notification },
    })

    return data.insert_notifications_one.id
  }

  return {
    getAllUserIds,
    insertNotificationFeeds,
    insertNotification,
  }
}

export default useNotification
