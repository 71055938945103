import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"
import { FORM_TYPE_ADD } from "../../../enums/FormTypes"
import useOneSignal from "../../../hooks/useOneSignal"
import { Spin } from "antd"
import NotificationForm from "../../../components/forms/NotificationForm"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import { navigate } from "gatsby"
import useNotification from "../../../hooks/useNotification"
import useFrontSiteUrls from "../../../hooks/useFrontSiteUrls"

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const AddNotificationPage = () => {
  const { createNotification } = useOneSignal()
  const { getAllUserIds, insertNotificationFeeds, insertNotification } =
    useNotification()

  const { getActionUrl } = useFrontSiteUrls()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async values => {
    const actionUrl = getActionUrl(values.action, values.actionValue)

    // console.log({ values, actionUrl })
    // return

    setLoading(true)
    try {
      await createNotification({
        ...values,
        isIos: values.sendTo.includes("ios"),
        isAndroid: values.sendTo.includes("android"),
        isAnyWeb: values.sendTo.includes("anyWeb"),
        web_url: actionUrl,
        data: {
          action: values.action,
          value: values.actionValue,
        },
      })

      const { title, subtitle, message } = values

      const allUserIds = await getAllUserIds()
      const notificationId = await insertNotification({
        title,
        subtitle,
        content: message,
        data: {
          action: values.action,
          value: values.actionValue,
        },
        channels: [...values.sendTo],
      })

      await insertNotificationFeeds(notificationId, allUserIds)

      uiHandleSuccess({
        message: "Notifica inviata",
        action: () => {
          navigate("/messaging/push-notification")
        },
      })
    } catch (e) {
      console.log({ error: e })
      setError(e)
      uiHandleError({ error: e })
    }
    setLoading(false)
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={loading}>
        <NotificationForm
          initialValues={{}}
          onFinish={handleSubmit}
          formType={FORM_TYPE_ADD}
        />
      </Spin>
    </PageRoot>
  )
}

export default AddNotificationPage
