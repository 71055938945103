import React from "react"
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
} from "antd"
import { InfoCircleOutlined } from "@ant-design/icons"
import PushNotificationPreview from "../PushNotificationPreview"
import AllItemSelector from "../customFormItems/AllItemSelector"

import * as MainSections from "../../enums/MainSections"

const sectionTranslations = {
  HOME_PAGE: "Home",
  ON_DEMAND: "On demand",
  CATEGORIES: "Tutte le categorie",
  CERTIFICATIONS: "Tutte le certificazioni",
  CHANNELS: "Tutti i canali",
  AUTHORS: "Tutti gli autori",
  LIBRARY: "Libreria",
  WEBINAR: "Tutti i webinar",
  HUMAN_3D: "Atlante Anatomico 3D",
  MY_CERTIFICATIONS: "Le mie certificazioni (solo per browser)",
  SPECIAL_PRICES: "Tutte le offerte speciali (solo per browser)",
  GIFT_CARDS: "Tutte le Gift Card (solo per browser)",
  BUNDLES: "Tutti i Bundle (solo per browser)",
}

const SectionSelect = props => {
  return (
    <Select {...props}>
      {Object.keys(MainSections).map((key, index) => (
        <Select.Option value={key} key={index}>
          {sectionTranslations[key]}
        </Select.Option>
      ))}
    </Select>
  )
}

const actionValueComponents = {
  item: AllItemSelector,
  section: SectionSelect,
  webPage: Input,
}

const actionValueRules = {
  item: [],
  section: [],
  webPage: [{ type: "url" }],
}

const NotificationForm = ({ onFinish, initialValues, formType }) => {
  const [form] = Form.useForm()

  return (
    <Form
      scrollToFirstError
      initialValues={{ ...initialValues, action: "none" }}
      onFinish={onFinish}
      layout={"vertical"}
      form={form}
    >
      <Row gutter={32}>
        <Col flex="auto">
          <Card title="Push Notification">
            <Divider orientation="left" plain>
              Corpo messaggio
            </Divider>
            <Form.Item
              label="Nome interno"
              tooltip={{
                title:
                  "Questo campo serve per organizzazione interna, non viene incorporato nel messaggio inviato.",
                icon: <InfoCircleOutlined />,
              }}
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Titolo"
              name="title"
              tooltip={{
                title: "Non visibile su ios",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Sottotitolo"
              name="subtitle"
              tooltip={{
                title: "Non visibile su android",
                icon: <InfoCircleOutlined />,
              }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Messaggio, NON inserire i dati sensibili."
              name="message"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input.TextArea rows={5} />
            </Form.Item>

            <Divider orientation="left" plain>
              Azione al click
            </Divider>
            <Form.Item
              label="Azione"
              name="action"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select>
                <Select.Option value="none">Nessuna</Select.Option>
                <Select.Option value="item">Aprire un articolo</Select.Option>
                <Select.Option value="section">
                  Aprire una sezione specifica
                </Select.Option>
                <Select.Option value="webPage">
                  Aprire una pagina web
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                if (prevValues.action === currentValues.action) {
                  return false
                }

                const formValues = form.getFieldsValue()
                form.setFieldsValue({ ...formValues, actionValue: null })

                return true
              }}
            >
              {({ getFieldValue }) => {
                const actionType = getFieldValue("action")
                const ActionValueComponent =
                  actionValueComponents[actionType] || actionValueComponents
                const actionValueRule = actionValueRules[actionType] || []
                return actionType === "none" ? null : (
                  <Form.Item
                    label="Soggetto"
                    name="actionValue"
                    rules={[
                      {
                        required: true,
                      },
                      ...actionValueRule,
                    ]}
                  >
                    <ActionValueComponent />
                  </Form.Item>
                )
              }}
            </Form.Item>

            <Divider orientation="left" plain>
              Canali di invio
            </Divider>
            <Form.Item
              name="sendTo"
              label="Invia a"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Checkbox.Group>
                <Row>
                  <Col span={24}>
                    <Checkbox value="android" style={{ lineHeight: "32px" }}>
                      Android
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="ios" style={{ lineHeight: "32px" }}>
                      iOS
                    </Checkbox>
                  </Col>
                  <Col span={24}>
                    <Checkbox value="anyWeb" style={{ lineHeight: "32px" }}>
                      Tutti i browser
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>
          </Card>
        </Col>
        <Col flex="450px">
          <Form.Item shouldUpdate>
            {({ getFieldsValue }) => {
              return <PushNotificationPreview notification={getFieldsValue()} />
            }}
          </Form.Item>
        </Col>
      </Row>
      <br />
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Invia
        </Button>
      </Form.Item>
    </Form>
  )
}

export default NotificationForm
