import React from "react"
import { ARCHIVED, COMING_SOON, DRAFT, PUBLISHED } from "../enums/RecordStates"
import { Tag } from "antd"

export const PublishStatus = ({ state }) => {
  const labels = {
    [DRAFT]: "Bozza",
    [ARCHIVED]: "Archiviato",
    [PUBLISHED]: "Pubblicato",
    [COMING_SOON]: "Coming soon",
  }

  const colors = {
    [DRAFT]: "orange",
    [ARCHIVED]: "red",
    [PUBLISHED]: "blue",
    [COMING_SOON]: "orange",
  }

  return <Tag color={colors[state]}>{labels[state]}</Tag>
}
