import React, { useEffect, useState } from "react"
import { gql, useMutation } from "@apollo/client"
import DataTable from "../../../components/DataTable"
import { Link } from "gatsby"
import { Affix, Button, Popconfirm, Space } from "antd"
import PageRoot from "../../../components/PageRoot"
import { PlusOutlined } from "@ant-design/icons"
import useColumnSearch from "../../../hooks/useColumnSearch"
import LoadingScreen from "../../../components/LoadingScreen"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"

const ALL_CHANNEL_LIST_QUERY = gql`
  {
    channels(order_by: { name: asc }) {
      id
      name
      description
    }
  }
`

const DELETE_CHANNEL_MUATION = gql`
  mutation deleteChannel($id: uuid!) {
    delete_channels_by_pk(id: $id) {
      id
    }
    delete_seo(where: { item_id: { _eq: $id } }) {
      affected_rows
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const ChannelListPage = () => {
  const nameSearchColumnOptions = useColumnSearch({ dataIndex: "name" })
  const [refreshing, setRefreshing] = useState(false)
  const [deleteChannel] = useMutation(DELETE_CHANNEL_MUATION)
  useEffect(() => {
    if (refreshing) {
      setRefreshing(false)
    }
  }, [refreshing])

  if (refreshing) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <LoadingScreen />
      </PageRoot>
    )
  }

  const deleteRecord = async id => {
    try {
      await deleteChannel({ variables: { id } })
      setRefreshing(true)
    } catch (error) {}
  }

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ...nameSearchColumnOptions,
    },
    {
      title: "Azioni",
      render: record => {
        return (
          <Space>
            {/*<Button><Link to={`/contents/channels/view?id=${record.id}`}>mostra</Link></Button>*/}
            <Button type="primary">
              <Link to={`/contents/channels/edit?id=${record.id}`}>
                modifica
              </Link>
            </Button>
            <Popconfirm
              placement="topRight"
              title={"Sei sicuro di voler eliminare questo record?"}
              onConfirm={() => {
                deleteRecord(record.id)
              }}
              okText="elimina"
              cancelText="annulla"
            >
              <Button type="danger">elimina</Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Affix style={{ position: "fixed", bottom: 20, right: 20 }}>
        <Link to={`/contents/channels/add`}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            shape="circle"
            size="large"
          />
        </Link>
      </Affix>
      <DataTable
        dataKey={"channels"}
        query={ALL_CHANNEL_LIST_QUERY}
        columns={columns}
      />
    </PageRoot>
  )
}

export default ChannelListPage
