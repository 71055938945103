import React from "react"
import PageRoot from "../../../components/PageRoot"
import { gql, useQuery } from "@apollo/client"
import * as QueryString from "query-string"
import ErrorScreen from "../../../components/ErrorScreen"
import LoadingScreen from "../../../components/LoadingScreen"
import { Card, Col, Empty, List, Row, Space } from "antd"
import { formatDate } from "../../../utils"
import AddItemToUserButton from "../../../components/AddItemToUserButton"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_WAREHOUSE,
} from "../../../enums/UserRoles"

const GET_USER_DATA_QUERY = gql`
  query getUserData($user_id: String!) {
    auth0_users_by_pk(user_id: $user_id) {
      user_id
      email
      role
      last_login
      basic_profile {
        firstname
        lastname
        email
        newsletter
        phone_number
      }
      billing_profile {
        email
        firstname
        lastname
        phone_number
      }
      subscriptions(order_by: { end_at: desc }) {
        state
        start_at
        end_at
      }
    }
    user_purchases(where: { user_id: { _eq: $user_id } }) {
      created_at
      description
      state
      item_type
      item_id
    }
    user_inventory_items(where: { user_id: { _eq: $user_id } }) {
      created_at
      item_type
      item_id
      video {
        title
        subtitle
        landscape {
          s3_key
        }
      }
      course {
        title
        subtitle
        landscape {
          s3_key
        }
      }
    }
  }
`

const UserViewPage = ({ location }) => {
  const params = QueryString.parse(location.search)

  const { loading, error, data, refetch } = useQuery(GET_USER_DATA_QUERY, {
    variables: {
      user_id: params.id,
    },
    fetchPolicy: "network-only",
  })

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error} />
  }

  const { basic_profile, billing_profile, subscriptions } =
    data.auth0_users_by_pk

  const { firstname, lastname, email, phone_number } = {
    ...basic_profile,
    ...billing_profile,
  }

  const subscription = subscriptions[0]

  const purchases = data.user_purchases

  const inventoryItems = data.user_inventory_items

  return (
    <PageRoot
      authorizedRoles={[
        ROLE_ADMIN,
        ROLE_ACCOUNTING,
        ROLE_SALE,
        ROLE_WAREHOUSE,
        ROLE_PRODUCT_MANAGER,
      ]}
    >
      <Row gutter={[16, 16]}>
        <Col span={7}>
          <Card title="Profilo utente" hoverable>
            <List itemLayout="horizontal">
              <List.Item>
                <List.Item.Meta title={"Nome"} />
                <div>{firstname}</div>
              </List.Item>
              <List.Item>
                <List.Item.Meta title={"Cognome"} />
                <div>{lastname}</div>
              </List.Item>
              <List.Item>
                <List.Item.Meta title={"Email"} />
                <div>{email}</div>
              </List.Item>
              <List.Item>
                <List.Item.Meta title={"Telefono"} />
                <div>{phone_number}</div>
              </List.Item>
            </List>
          </Card>
        </Col>
        <Col span={7}>
          <Card title="Abbonamento" hoverable>
            {subscription ? (
              <List itemLayout="horizontal">
                <List.Item>
                  <List.Item.Meta title={"Stato"} />
                  <div>{subscription.state}</div>
                </List.Item>
                <List.Item>
                  <List.Item.Meta title={"Data inizio"} />
                  <div>{formatDate(subscription.start_at)}</div>
                </List.Item>
                <List.Item>
                  <List.Item.Meta title={"Valido fino al"} />
                  <div>{formatDate(subscription.end_at)}</div>
                </List.Item>
              </List>
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
        <Col span={10}>
          <Card title="Storico acquisto" hoverable>
            <List
              dataSource={purchases}
              renderItem={({
                description,
                state,
                created_at,
                item_type,
                item_id,
              }) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={state}
                    title={item_type}
                    description={formatDate(created_at)}
                  />
                  <div>{description}</div>
                </List.Item>
              )}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Card
            title={
              <Space>
                <span>Inventario utente</span>
                <AddItemToUserButton
                  userId={params.id}
                  onAdd={() => {
                    refetch()
                  }}
                />
              </Space>
            }
          >
            {inventoryItems.length > 0 ? (
              <List
                dataSource={inventoryItems}
                renderItem={({
                  created_at,
                  item_type,
                  item_id,
                  video,
                  course,
                }) => {
                  const item = course || video
                  if (!item) {
                    return null
                  }

                  const { title, subtitle, landscape } = course || video
                  return (
                    <List.Item>
                      <List.Item.Meta
                        avatar={item_type}
                        title={title}
                        description={formatDate(created_at)}
                      />
                    </List.Item>
                  )
                }}
              />
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
      </Row>
    </PageRoot>
  )
}

export default UserViewPage
