import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import ProductForm from "../../../components/forms/ProductForm"
import { FORM_TYPE_EDIT } from "../../../enums/FormTypes"
import QueryString from "query-string"
import { gql, useMutation, useQuery } from "@apollo/client"
import LoadingScreen from "../../../components/LoadingScreen"
import ErrorScreen from "../../../components/ErrorScreen"
import { Button, Result, Spin } from "antd"
import { Link, navigate } from "gatsby"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"

const GET_PRODUCT_QUERY = gql`
  query getProduct($id: uuid!) {
    external_products_by_pk(id: $id) {
      id
      title
      subtitle
      description
      short_description
      picture_id
      price
      url
    }
  }
`

const UPDATE_PRODUCT_MUTATION = gql`
  mutation updateProduct($id: uuid!, $data: external_products_set_input!) {
    update_external_products_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const EditProductPage = ({ location }) => {
  const [updating, setUpdating] = useState(false)

  const params = QueryString.parse(location.search)

  const { data, error, loading } = useQuery(GET_PRODUCT_QUERY, {
    variables: { id: params.id },
    fetchPolicy: "network-only",
  })

  const [updateProduct] = useMutation(UPDATE_PRODUCT_MUTATION)

  const handleFormSubmit = async values => {
    setUpdating(true)
    let newValues = { ...values }
    try {
      await updateProduct({ variables: { data: newValues, id: params.id } })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/contents/products")
        },
      })
    } catch (error) {
      uiHandleError({ error })
    }
    setUpdating(false)
  }

  if (loading) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <LoadingScreen />
      </PageRoot>
    )
  }

  if (error) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <ErrorScreen error={error} />
      </PageRoot>
    )
  }

  const product = data.external_products_by_pk

  if (!product) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        <Result
          status="404"
          title="404"
          subTitle="Elemento non trovato!"
          extra={
            <Button type="primary">
              <Link to={"/contents/products"}>Torna indietro</Link>
            </Button>
          }
        />
      </PageRoot>
    )
  }

  const initialValues = {
    ...product,
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={updating}>
        <ProductForm
          initialValues={initialValues}
          onFinish={handleFormSubmit}
          formType={FORM_TYPE_EDIT}
        />
      </Spin>
    </PageRoot>
  )
}

export default EditProductPage
