import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Col, Layout as AntLayout, Menu, Row, Select } from "antd"
import {
  AlertOutlined,
  AppstoreAddOutlined,
  BarChartOutlined,
  EuroCircleOutlined,
  GiftOutlined,
  IdcardOutlined,
  MessageOutlined,
  UserOutlined,
} from "@ant-design/icons"
import logo from "../../static/assets/images/logo-sc-admin.svg"
import moment from "moment"
import DeploySiteButton from "./DeploySiteButton"
import { useLocation } from "@reach/router"
import LoadingScreen from "./LoadingScreen"

const { Header, Sider, Content, Footer } = AntLayout
const { SubMenu } = Menu

const createAllPaths = pathname => {
  const parts = pathname.replace("/", "").split("/")
  const result = []
  parts.map(part => {
    return result.push(`${result[result.length - 1] ?? ""}/${part}`)
  })

  return result
}

const Layout = ({ user, children }) => {
  const [selectedKeys, setSelectedKeys] = useState([])
  const [openKeys, setOpenKeys] = useState([])
  const location = useLocation()

  const onOpenChange = keys => {
    // const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    // if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
    //   setOpenKeys(keys)
    // } else {
    //   setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    // }
    setOpenKeys(keys)
  }

  useEffect(() => {
    const keys = createAllPaths(location.pathname)
    setOpenKeys(keys)
    setSelectedKeys(keys)
  }, [location])

  if (!user) {
    return <LoadingScreen />
  }

  return (
    <AntLayout style={{ minHeight: "100vh" }}>
      <AntLayout className="site-layout">
        <Sider>
          <div className="logo">
            <Link to={"/"}>
              <img src={logo} alt="logo" style={{ width: "100%" }} />
            </Link>
          </div>
          <Menu
            theme="dark"
            selectedKeys={selectedKeys}
            mode="inline"
            openKeys={openKeys}
            onOpenChange={onOpenChange}
          >
            <Menu.SubMenu
              key="/contents"
              title="Contenuti"
              icon={<AppstoreAddOutlined />}
            >
              <Menu.Item key="/contents/categories">
                <Link to={"/contents/categories"}>Categorie</Link>
              </Menu.Item>
              <Menu.Item key="/contents/channels">
                <Link to={"/contents/channels"}>Canali</Link>
              </Menu.Item>
              <Menu.Item key="/contents/authors">
                <Link to={"/contents/authors"}>Autori</Link>
              </Menu.Item>
              <Menu.Item key="/contents/videos">
                <Link to={"/contents/videos"}>Video</Link>
              </Menu.Item>
              <Menu.Item key="/contents/courses">
                <Link to={"/contents/courses"}>Corsi</Link>
              </Menu.Item>
              <Menu.Item key="/contents/webinars">
                <Link to={"/contents/webinars"}>Webinar</Link>
              </Menu.Item>
              <Menu.Item key="/contents/certifications">
                <Link to={"/contents/certifications"}>Certificazioni</Link>
              </Menu.Item>
              <Menu.Item key="/contents/products">
                <Link to={"/contents/products"}>Prodotti</Link>
              </Menu.Item>
              <Menu.Item key="/contents/settings/homepage">
                <Link to={"/contents/settings/homepage"}>
                  Impostazione pagina principale
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu key="/erp" title="ERP" icon={<AppstoreAddOutlined />}>
              <Menu.Item key="/erp/product-code/course/list">
                <Link to={"/erp/product-code/course/list"}>Corsi</Link>
              </Menu.Item>
              <Menu.Item key="/erp/product-code/certification/list">
                <Link to={"/erp/product-code/certification/list"}>
                  Certificazioni
                </Link>
              </Menu.Item>
              <Menu.Item key="/erp/product-code/bundle/list">
                <Link to={"/erp/product-code/bundle/list"}>Bundle</Link>
              </Menu.Item>
              <Menu.Item key="/erp/product-code/external-product/list">
                <Link to={"/erp/product-code/external-product/list"}>
                  Prodotti
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              key="/promo"
              title="Promozioni"
              icon={<GiftOutlined />}
            >
              <Menu.Item key="/promo/coupons">
                <Link to={"/promo/coupons"}>Bonus</Link>
              </Menu.Item>
              <Menu.Item key="/promo/gift-cards">
                <Link to={"/promo/gift-cards"}>Gift Card</Link>
              </Menu.Item>
              <Menu.Item key="/promo/special-prices">
                <Link to={"/promo/special-prices"}>Promozioni</Link>
              </Menu.Item>
              <Menu.Item key="/promo/bundles">
                <Link to={"/promo/bundles"}>Bundle</Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              key="/monetization"
              title="Monetizzazione"
              icon={<EuroCircleOutlined />}
            >
              <Menu.Item key="/monetization/ondemand">
                <Link to={"/monetization/ondemand"}>Acquisti On Demand</Link>
              </Menu.Item>
              <Menu.Item key="/monetization/subscriptions">
                <Link to={"/monetization/subscriptions"}>Abbonamenti</Link>
              </Menu.Item>
              <Menu.Item key="/monetization/transactions">
                <Link to={"/monetization/transactions"}>Transazioni</Link>
              </Menu.Item>
              <Menu.Item key="/monetization/failed-transactions">
                <Link to={"/monetization/failed-transactions"}>
                  Transazioni fallite
                </Link>
              </Menu.Item>
              <Menu.Item key="/monetization/author-commissions">
                <Link to={"/monetization/author-commissions"}>
                  Provvigione autore
                </Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              key="/users"
              title="Dati utenti"
              icon={<IdcardOutlined />}
            >
              <Menu.Item key="/users/certifications">
                <Link to={"/users/certifications"}>Certificazioni utenti</Link>
              </Menu.Item>

              <Menu.Item key="/users/all">
                <Link to={"/users/all"}>Users</Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              key="/messaging"
              title="Messaggistica"
              icon={<MessageOutlined />}
            >
              <Menu.Item key="/messaging/push-notification">
                <Link to={"/messaging/push-notification"}>Notifiche push</Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              key="/reports"
              title="Report"
              icon={<BarChartOutlined />}
            >
              <Menu.Item key="/reports/video">
                <Link to={"/reports/video"}>Video</Link>
              </Menu.Item>
            </Menu.SubMenu>

            <Menu.SubMenu
              key="/feedback"
              title="Feedback"
              icon={<AlertOutlined />}
            >
              <Menu.Item key="/feedback/topic-suggestion">
                <Link to={"/feedback/topic-suggestion"}>
                  Suggerimenti topic
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </Sider>
        <AntLayout className="site-layout">
          <Header className="site-layout-background">
            <Row justify="space-between">
              <Col>
                <Select
                  value={location.host}
                  onSelect={key => {
                    window.location = `https://${key}`
                  }}
                >
                  <Select.Option value={"admin.sportscience.com"}>
                    Admin Italiano
                  </Select.Option>
                  <Select.Option value={"admin-us.sportscience.com"}>
                    Admin USA
                  </Select.Option>
                </Select>
              </Col>
              <Col>
                <Row justify="end">
                  <Col>
                    <DeploySiteButton />
                  </Col>
                  <Col>
                    <Menu mode="horizontal">
                      <SubMenu key="user-submenu" icon={<UserOutlined />}>
                        <Menu.Item key="profile">
                          <Link to={"/me"}>{user.email}</Link>
                        </Menu.Item>
                        <Menu.Item key="sign-out">
                          <Link to={"/auth/sign-out"}>Esci</Link>
                        </Menu.Item>
                      </SubMenu>
                    </Menu>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Header>
          <Content style={{ margin: 16 }}>
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 360 }}
            >
              {children}
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            <a href="https://pixelfoundry.studio/" target="_blank">
              Pixelfoundry Srls ©{moment().get("Y")}
            </a>
          </Footer>
        </AntLayout>
      </AntLayout>
    </AntLayout>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
