import React from "react"
import { Affix, Button, Card, Col, Form, Input, Row } from "antd"
import CategorySelector from "../customFormItems/CategorySelector"
import { FORM_TYPE_ADD } from "../../enums/FormTypes"
import FormFileUploader from "../customFormItems/FormFileUploader"
import useSeoFormItems from "../../hooks/useSeoFormItems"

const CategoryForm = ({ onFinish, initialValues, formType }) => {
  const [form] = Form.useForm()
  const seoItems = useSeoFormItems({ form, itemId: initialValues?.id })

  return (
    <Form
      scrollToFirstError
      initialValues={initialValues}
      onFinish={onFinish}
      layout={"vertical"}
      form={form}
    >
      <Row gutter={16}>
        <Col span={16}>
          <Card title="Categoria">
            <Form.Item
              label="Nome"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Descrizione" name="description">
              <Input />
            </Form.Item>

            <Form.Item label="Categoria padre" name="parent_id">
              <CategorySelector />
            </Form.Item>

            <Form.Item label="Landscape" name="landscape_id">
              <FormFileUploader accept={"image/*"} />
            </Form.Item>
          </Card>
        </Col>
        <Col span={8}>
          <Affix offsetTop={16}>
            <Card title="SEO">{seoItems}</Card>
          </Affix>
        </Col>
      </Row>
      <br />
      <Form.Item>
        <Button type="primary" htmlType="submit">
          {formType === FORM_TYPE_ADD ? "inserisci" : "aggiorna"}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CategoryForm
