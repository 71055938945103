import React, { useState } from "react"
import { Button, Col, Row, Skeleton } from "antd"
import UserSelector from "../customFormItems/UserSelector"
import useCommonQueries from "../../hooks/useCommonQueries"
import { uiHandleSuccess } from "../../utils"

const CouponAssigner = ({ data, onFinish, onError }) => {
  const [loading, setLoading] = useState(false)
  const [userIds, setUserIds] = useState(0)
  const { generateCoupon } = useCommonQueries()

  if (loading) {
    return <Skeleton active round />
  }

  const { id } = data

  const handleGenerateButtonClick = async () => {
    setLoading(true)
    try {
      const result = await generateCoupon({ couponId: id, userIds })
      uiHandleSuccess({
        action: () => {},
        message: "Codici inviati con successo.",
      })
      onFinish && onFinish(result)
      setUserIds([])
    } catch (error) {
      console.log({ error })
      onError && onError(error)
    }

    setLoading(false)
  }

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col flex="auto">
          <UserSelector
            multiple
            value={userIds}
            onChange={value => {
              setUserIds(value)
            }}
          />
        </Col>

        <Col>
          <Button type="primary" onClick={handleGenerateButtonClick}>
            Genera
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default CouponAssigner
