import React, { useEffect, useState } from "react"
import { gql } from "@apollo/client"
import { Link } from "gatsby"
import { Button, Col, Drawer, Row, Space, Table, Typography } from "antd"
import PageRoot from "../../../components/PageRoot"
import { EditOutlined, EyeOutlined } from "@ant-design/icons"
import useColumnSearch from "../../../hooks/useColumnSearch"
import LoadingScreen from "../../../components/LoadingScreen"
import {
  downloadCsvFile,
  formatDate,
  getConnectionTypeByUserId,
  getRoleTranslation,
  isSubscriptionValid,
} from "../../../utils"
import moment from "moment"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
  ROLE_SALE,
  ROLE_WAREHOUSE,
} from "../../../enums/UserRoles"
import useColumnDateFilter from "../../../hooks/useColumnDateFilter"
import UserViewPage from "./view"
import DataTable from "../../../components/DataTable"
import useCommonQueries from "../../../hooks/useCommonQueries"

const { Text } = Typography

const authorizedRoles = [
  ROLE_ADMIN,
  ROLE_ACCOUNTING,
  ROLE_WAREHOUSE,
  ROLE_SALE,
  ROLE_PRODUCT_MANAGER,
]

const ALL_USER_LIST_QUERY = gql`
  {
    auth0_users(order_by: { created_at: desc }) {
      user_id
      email
      role
      last_login
      created_at
      basic_profile {
        firstname
        lastname
        updated_at
      }
      subscriptions(order_by: { end_at: desc }) {
        state
        start_at
        end_at
      }
    }
  }
`

const downloadMailChimp = data => {
  const result = [["Email Address", "First Name", "Last Name", "Website"]]
  data.map(user => {
    const { email, basic_profile } = user

    const { firstname, lastname } = basic_profile || {}

    const row = [email, firstname, lastname, process.env.GATSBY_FRONT_SITE]

    result.push(row)
  })

  downloadCsvFile(result, `lista_utente_${new Date().getTime()}.csv`)
}

const UserListPage = () => {
  const { getCurrentUser } = useCommonQueries()

  const [showSummary, setShowSummary] = useState(false)
  const emailSearchColumnOptions = useColumnSearch({ dataIndex: "email" })
  const firstnameSearchColumnOptions = useColumnSearch({
    dataIndex: "basic_profile.firstname",
  })
  const lastnameSearchColumnOptions = useColumnSearch({
    dataIndex: "basic_profile.lastname",
  })

  const createdAtRangeFilter = useColumnDateFilter({
    dataIndex: "created_at",
  })

  const lastLoginRangeFilter = useColumnDateFilter({
    dataIndex: "last_login",
  })

  const profileUpdatedAtRangeFilter = useColumnDateFilter({
    dataIndex: "basic_profile.updated_at",
  })

  const [refreshing, setRefreshing] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [filteredData, setFilteredData] = useState([])
  const [userToView, setUserToView] = useState(null)

  useEffect(() => {
    const fetchUser = async () => {
      const currentUser = await getCurrentUser()
      if ([ROLE_ADMIN].indexOf(currentUser.role) >= 0) {
        setShowSummary(true)
      }
    }
    fetchUser()
  }, [])

  const statusLabels = {
    ["Active"]: "Attivo",
    ["Canceled"]: "Cancellato",
    ["Expired"]: "Scaduto",
    ["PastDue"]: "Rinnovo sospeso",
    ["Pending"]: "In sospeso",
  }

  useEffect(() => {
    if (refreshing) {
      setRefreshing(false)
    }
  }, [refreshing])

  if (refreshing) {
    return (
      <PageRoot authorizedRoles={authorizedRoles}>
        {" "}
        <LoadingScreen />{" "}
      </PageRoot>
    )
  }

  const columns = [
    // { title: "Indice", render: (record, row, index) => index },
    {
      title: "Accesso",
      dataIndex: "user_id",
      render: record => {
        return getConnectionTypeByUserId(record)
      },
      filters: [
        {
          text: "Email",
          value: "auth0",
        },
        {
          text: "Facebook",
          value: "facebook",
        },
        {
          text: "Google",
          value: "google",
        },
      ],
      onFilter: (value, record) => {
        return record.user_id.indexOf(value) === 0
      },
    },
    {
      title: "Nome",
      ...firstnameSearchColumnOptions,
    },
    {
      title: "Cognome",
      ...lastnameSearchColumnOptions,
    },
    {
      title: "Email",
      dataIndex: "email",
      ...emailSearchColumnOptions,
    },
    {
      title: "Ruolo",
      dataIndex: "role",
      render: record => getRoleTranslation(record),
      filters: [
        {
          text: "Amministrazione",
          value: "ADMIN",
        },
        {
          text: "Commerciale",
          value: "ACCOUNTING",
        },
        {
          text: "Magazzino",
          value: "WAREHOUSE",
        },
        {
          text: "Trainer",
          value: "TRAINER",
        },
        {
          text: "VIP",
          value: "VIP",
        },
      ],
      onFilter: (value, record) => {
        return record.role === value
      },
    },
    {
      title: "Registrato il",
      ...createdAtRangeFilter,
      sorter: (a, b) => {
        return moment(a.created_at) - moment(b.created_at)
      },
    },
    {
      title: "Ultimo accesso",
      ...lastLoginRangeFilter,
      sorter: (a, b) => {
        return moment(a.last_login) - moment(b.last_login)
      },
    },
    {
      title: "Aggiornato il",
      ...profileUpdatedAtRangeFilter,
      sorter: (a, b, ordering) => {
        if (!a.basic_profile?.updated_at && !b.basic_profile?.updated_at) {
          return 0
        }

        if (!a.basic_profile?.updated_at) {
          return ordering === "ascend" ? 1 : -1
        }

        if (!b.basic_profile?.updated_at) {
          return ordering === "descend" ? 1 : -1
        }

        return (
          moment(a.basic_profile?.updated_at) -
          moment(b.basic_profile?.updated_at)
        )
      },
    },
    {
      title: "Abbonamento",
      children: [
        {
          title: "Tipologia",
          render: record => {
            return record.subscriptions.length > 0 ? "Abbonato" : "Freemium"
          },
          filters: [
            {
              text: "Abbonato",
              value: "premium",
            },
            {
              text: "Freemium",
              value: "freemium",
            },
          ],
          filterMultiple: false,
          onFilter: (value, record) => {
            return (
              (value === "premium" && record.subscriptions.length > 0) ||
              (value === "freemium" && record.subscriptions.length === 0)
            )
          },
        },
        {
          title: "Stato",
          render: record => {
            if (["Active"].includes(record.subscriptions[0]?.state)) {
              const endAt = record.subscriptions[0]?.end_at
                ? new Date(record.subscriptions[0]?.end_at)
                : null

              if (endAt && endAt < new Date()) {
                return "Rinnovo sospeso"
              } else {
                return "Attivo"
              }
            } else {
              return (
                statusLabels[record.subscriptions[0]?.state] ||
                record.subscriptions[0]?.state
              )
            }
          },
          filters: [
            {
              text: "Attivo",
              value: "Active",
            },
            {
              text: "Cancellato",
              value: "Canceled",
            },
            {
              text: "Rinnovo sospeso",
              value: "PastDue",
            },
            {
              text: "In sospeso",
              value: "Pending",
            },
          ],
          onFilter: (value, record) => {
            const now = new Date()

            if (
              value === "PastDue" &&
              ["Active", "PastDue"].includes(record.subscriptions[0]?.state)
            ) {
              if (record.subscriptions[0]?.state === "Active") {
                const endAt = record.subscriptions[0]?.end_at
                  ? new Date(record.subscriptions[0]?.end_at)
                  : null

                return endAt < now
              } else {
                return true
              }
            }

            if (
              value === "Active" &&
              record.subscriptions[0]?.state === "Active"
            ) {
              const endAt = record.subscriptions[0]?.end_at
                ? new Date(record.subscriptions[0]?.end_at)
                : null

              return endAt > now
            }

            return record.subscriptions[0]?.state === value
          },
        },
        {
          title: "Data inizio",
          render: record => {
            const subscription = record.subscriptions[0]
            return subscription
              ? formatDate(subscription.start_at, { format: "L" })
              : null
          },
          sorter: (a, b) => {
            return (
              moment(a.subscriptions[0]?.start_at) -
              moment(b.subscriptions[0]?.start_at)
            )
          },
        },
        {
          title: "Data fine",
          render: record => {
            const subscription = record.subscriptions[0]
            return subscription
              ? formatDate(subscription.end_at, { format: "L" })
              : null
          },
          sorter: (a, b) => {
            return (
              moment(a.subscriptions[0]?.end_at) -
              moment(b.subscriptions[0]?.end_at)
            )
          },
        },
      ],
    },
    {
      title: "Azioni",
      fixed: "right",
      render: record => {
        return (
          <Space>
            <Button type="primary">
              <Link to={`/users/all/edit?id=${btoa(record.user_id)}`}>
                <EditOutlined />
              </Link>
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setUserToView(record)
              }}
            >
              <EyeOutlined />
            </Button>
          </Space>
        )
      },
    },
  ]

  const handleChange = (pagination, filters, sorter, extra) => {
    setFilteredData(extra.currentDataSource)
  }

  const handleOnLoad = ({ loading, data, error, refetch }) => {
    if (!error) {
      setFilteredData(data.auth0_users)
    }
  }

  const summary = pageData => {
    let freemiumUsers = 0
    let premiumUsers = 0

    filteredData.map(record => {
      if (!record?.subscriptions) {
        return null
      }

      const lastSubscription = record.subscriptions[0]
      if (isSubscriptionValid(lastSubscription)) {
        premiumUsers++
      } else {
        freemiumUsers++
      }
    })

    return (
      <Table.Summary fixed="top">
        <Table.Summary.Row>
          <Table.Summary.Cell colSpan={2}>
            <b>Utenti Totali</b>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <b>{freemiumUsers + premiumUsers}</b>
          </Table.Summary.Cell>
          <Table.Summary.Cell colSpan={2}>
            <b>Utenti Freemium</b>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <b>{freemiumUsers}</b>
          </Table.Summary.Cell>
          <Table.Summary.Cell colSpan={2}>
            <b>Utenti Premium</b>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <b>{premiumUsers}</b>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    )
  }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Row gutter={[16, 16]}>
        <Col>
          <Button
            disabled={!filteredData?.length}
            loading={downloading}
            size="large"
            type="primary"
            onClick={() => {
              downloadMailChimp(filteredData)
            }}
          >
            Scarica lista utente per mailchimp
          </Button>
        </Col>
      </Row>
      <DataTable
        rowKey="user_id"
        dataKey={"auth0_users"}
        query={ALL_USER_LIST_QUERY}
        columns={columns}
        onLoad={handleOnLoad}
        onChange={handleChange}
        summary={showSummary ? summary : null}
        sticky
      />
      {userToView && (
        <Drawer
          width={"80%"}
          title={userToView.email}
          placement="right"
          onClose={() => {
            setUserToView(null)
          }}
          visible={true}
        >
          <UserViewPage
            noLayout
            location={{ search: `?id=${btoa(userToView.user_id)}` }}
          />
        </Drawer>
      )}
    </PageRoot>
  )
}

export default UserListPage
