import React, { useState } from "react"
import PageRoot from "../../../components/PageRoot"
import CourseForm from "../../../components/forms/CourseForm"
import { gql, useMutation } from "@apollo/client"
import { FORM_TYPE_ADD } from "../../../enums/FormTypes"
import { uiHandleError, uiHandleSuccess } from "../../../utils"
import { navigate } from "gatsby"
import { Spin } from "antd"
import { DRAFT } from "../../../enums/RecordStates"
import { FREE } from "../../../enums/PricePolicies"
import { COURSE } from "../../../enums/ItemTypes"
import {
  ROLE_ACCOUNTING,
  ROLE_ADMIN,
  ROLE_PRODUCT_MANAGER,
} from "../../../enums/UserRoles"

const INSERT_COURSE_MUTATION = gql`
  mutation insertSeo($data: seo_insert_input!) {
    insert_seo(objects: [$data]) {
      affected_rows
    }
  }
`

const authorizedRoles = [ROLE_ADMIN, ROLE_ACCOUNTING, ROLE_PRODUCT_MANAGER]

const AddCoursePage = () => {
  const [insertCourse] = useMutation(INSERT_COURSE_MUTATION)
  const [loading, setLoading] = useState(false)

  const onFinish = async values => {
    setLoading(true)
    try {
      let newValues = { ...values }
      delete newValues.videos
      delete newValues.category_ids
      delete newValues.channel_ids
      delete newValues.seo

      const videoData = values.videos
        ? values.videos.map((video, index) => ({
            video_id: video.id,
            index,
          }))
        : []

      const categoryData = values.category_ids
        ? values.category_ids.map(category_id => ({
            item_type: COURSE,
            category_id,
          }))
        : []

      const channelData = values.channel_ids
        ? values.channel_ids.map(channel_id => ({
            item_type: COURSE,
            channel_id,
          }))
        : []

      newValues.videos = { data: videoData }
      newValues.category_rels = { data: categoryData }
      newValues.channel_rels = { data: channelData }

      const data = {
        ...values.seo,
        course: {
          data: {
            ...newValues,
          },
        },
      }

      await insertCourse({ variables: { data } })

      uiHandleSuccess({
        message: "Operazione completata",
        action: () => {
          navigate("/contents/courses")
        },
      })
    } catch (error) {
      uiHandleError({
        error,
        action: () => {},
      })
    }
    setLoading(false)
  }

  const initialValues =
    process.env.NODE_ENV === "development"
      ? {
          state: DRAFT,
          price_policy: FREE,
          title: "titolo",
          subtitle: "sottotitolo",
          short_description: "descrizione breve",
          description: "descrizione completa",
          file_id: "1d67c838-ef12-4e6c-bd2c-c1941f26f815",
          duration: 5,
          videos: [],
          seo: {
            item_type: COURSE,
          },
        }
      : {
          state: DRAFT,
          price_policy: FREE,
          videos: [],
          seo: {
            item_type: COURSE,
          },
        }

  return (
    <PageRoot authorizedRoles={authorizedRoles}>
      <Spin spinning={loading}>
        <CourseForm
          initialValues={initialValues}
          onFinish={onFinish}
          formType={FORM_TYPE_ADD}
        />
      </Spin>
    </PageRoot>
  )
}

export default AddCoursePage
